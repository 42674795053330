<template>
  <div class="flex column">
    <template
      v-for="(option, index) in options"
      :key="index"
    >
      <div class="column">
        <BaseButton
          :active="isEqual(modelValue, option)"
          active-color="secondary"
          :data-intercom-target="option.dataIntercomTarget"
          :data-testid="option.dataTestId"
          :disabled="option.disabled"
          :icon="option.icon"
          :label="option.label"
          menu-item
          new-design
          :new-icon="option.newIcon"
          @click="selectOption(option)"
        >
          <slot :option="option" />
        </BaseButton>
        <StreemTooltip v-if="option.enableTooltip">
          {{ option.tooltipText }}
        </StreemTooltip>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
export interface Option<V = string> {
  disabled?: boolean;
  enableTooltip?: boolean;
  icon?: string;
  label: string;
  newIcon?: boolean;
  tooltipText?: string;
  value?: V;
  dataIntercomTarget?: string;
  dataTestId?: string;
}
</script>

<script setup lang="ts" generic="V, T extends Option<V>">
import { isEqual } from "lodash-es";

import { BaseButton, StreemTooltip } from "shared/components/base";

interface Props {
  modelValue?: T;
  options?: T[];
}

withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  options: () => [],
});

const emit = defineEmits(["update:modelValue"]);

function selectOption(option: T) {
  emit("update:modelValue", option);
}
</script>
