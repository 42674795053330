<template>
  <div class="tw-flex tw-flex-wrap">
    <Checkbox
      v-for="source in availableMedia"
      :key="source.field"
      v-model="localStream[source.property]"
      :color="source.field"
      :dense="dense"
      :label="source.label"
      new-design
      :style="inlineStyle"
      @update:model-value="checkboxToggled(source.label, $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import Checkbox from "shared/components/core/forms/Checkbox.vue";
import { type MediumField, getSources } from "shared/helpers/media";

export type Model = {
  [key in `${MediumField}_content`]: boolean;
};

const modelValue = defineModel<Model>();

interface Props {
  excludedMedia?: MediumField[];
  columnsPerRow?: number;
  dense?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  excludedMedia: () => [],
  columnsPerRow: 3,
});

const emit = defineEmits(["checkbox-toggled", "update:modelValue"]);

const sources = getSources()
  .filter(({ filterEnabled }) => filterEnabled)
  .map(({ field, label, color }) => ({
    field,
    label,
    color,
    property: `${field}_content`,
  }));

const localStream = ref(JSON.parse(JSON.stringify(modelValue.value)));

const availableMedia = computed(() =>
  sources.filter(({ field }) => !props.excludedMedia.includes(field))
);

const inlineStyle = computed(() => ({
  width: `${100 / props.columnsPerRow}%`,
}));

function checkboxToggled(medium: string, value: boolean) {
  emit("checkbox-toggled", { medium, value });
  emit("update:modelValue", localStream.value);
}

watch(modelValue, () => {
  localStream.value = JSON.parse(JSON.stringify(modelValue.value));
});
</script>
