<template>
  <MentionCard
    v-intersection="handleIntersection"
    :action-sheet-component="actionSheetComponent"
    :always-show-keywords="alwaysShowKeywords"
    :class="[
      { pointer: mention.url },
      { youtube: mention.type === 'youtube_video' },
    ]"
    :icon="sourceIcon(mention.type)"
    :list-title="listTitle"
    :mention="mention"
    :mentions="mentions"
    :no-highlighting="noHighlighting"
    :options="options"
    :product="product"
    :selected="selected"
    :show-checkbox="showCheckbox"
    :stream="stream"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #beforeBody>
      <div
        v-if="showThumbnail && mention.thumbnail_url"
        class="tw--mx-4 tw-mb-4"
      >
        <img
          :alt="$t('global.source_logo')"
          class="tw-h-[100px] tw-w-full tw-object-cover tw-object-[50%_30%]"
          :src="mention.thumbnail_url"
        />
      </div>

      <div
        v-if="title"
        class="tw-mb-4 tw-text-base tw-font-bold tw-leading-4 tw-text-denim-900"
      >
        {{ title }}
      </div>
    </template>
    <template #header>
      <template
        v-if="['reddit_post', 'reddit_post_comment'].includes(mention.type)"
      >
        <span class="tw-text-base tw-text-denim-950">
          <span class="alternate">{{ redditSourceName }}</span>
          <span>
            ·
            <span class="softer">
              <span v-if="mention.type === 'reddit_post_comment'">
                {{ $t("social_mention_card.commented") }}
              </span>
              <TimeAgo :date="mention.timestamp" />
            </span>
          </span>
        </span>
      </template>
      <template v-else-if="['blog_post', 'forum_post'].includes(mention.type)">
        <strong class="tw-text-base tw-text-denim-900">
          <AuthorWidget
            v-if="mention.author"
            :disable-click="options.disableAuthorClick"
            :mention="mention"
          />
        </strong>
      </template>
      <template v-else>
        <span>
          <strong class="tw-text-base tw-text-denim-950">
            <AuthorWidget
              v-if="mention.author"
              :disable-click="options.disableAuthorClick"
              :mention="mention"
              no-spacer
            />
          </strong>
          <QIcon
            v-if="mention.author_verified"
            class="text-social-verified social-verified"
            name="ion-checkmark-circle"
            size="14px"
          />
          <span
            v-if="mention.author_city"
            class="softer smaller"
          >
            {{ mention.author_city }}
          </span>
          <StreemTooltip
            v-if="showTooltip"
            content-class="transparent"
          >
            <div
              class="card q-pa-md border border-silver"
              style="background-color: rgb(255 255 255 / 94%)"
            >
              <div class="row no-wrap items-center">
                <img
                  v-if="mention.author_avatar_image"
                  :alt="$t('social_mention_card.author_image')"
                  class="q-mr-smd"
                  :src="mention.author_avatar_image"
                  style="max-width: 40px; max-height: 40px"
                />
                <strong
                  >{{ mention.author }} ({{ mention.twitter_handle }})</strong
                >
              </div>
              <div class="q-my-smd">
                {{ mention.profile_description }}
              </div>
              <div class="row no-wrap justify-around smaller">
                <span>
                  <QIcon
                    class="q-mr-xs"
                    name="ion-ios-people"
                    size="18px"
                    style="vertical-align: -2px"
                  />{{ suffix(mention.author_follower_count) }}
                </span>
                <span v-if="mention.author_city">
                  <QIcon
                    class="q-mr-xs"
                    name="location_on"
                    size="18px"
                    style="vertical-align: -2px"
                  />{{ mention.author_city }}
                </span>
              </div>
            </div>
          </StreemTooltip>
        </span>

        <TimeAgo
          class="softer self-start"
          :date="mention.timestamp"
        />
      </template>
    </template>

    <slot />

    <template #afterBody>
      <MentionCardInsights
        :visible-widgets="visibleWidgets"
        @click.stop.prevent
      >
        <template #components>
          <SocialViewCountWidget :mention="mention" />

          <SocialInsightWidget
            v-if="isTwitter && mention.impact"
            icon="ion-ios-radio"
            :title="$t('social_impact_score_widget.impact_score')"
            :value="`${mention.impact}%`"
          >
            <template #tooltip>
              {{ $t("social_mention_card.social_impact_score") }}
            </template>
          </SocialInsightWidget>

          <SocialInsightWidget
            v-if="commentsCount"
            icon="ion-ios-megaphone"
            :title="$t('social_impact_score_widget.comments')"
            :value="suffix(commentsCount)"
          >
            <template #tooltip>
              {{
                $t("social_mention_card.comments_count", {
                  commentsCount: suffix(commentsCount),
                })
              }}
            </template>
          </SocialInsightWidget>

          <SocialInsightWidget
            v-if="mention.score"
            icon="ion-ios-trending-up"
            :title="$t('social_impact_score_widget.impact_score')"
            :value="`${mention.score}%`"
          >
            <template #tooltip>
              {{ $t("social_mention_card.impact_score_proportional") }}
            </template>
          </SocialInsightWidget>

          <SentimentWidget
            dense
            :mention="mention"
            :stream="stream"
          />

          <ReactScoreWidget
            dense
            :is-parent-visible="isIntersecting"
            :mention="mention"
          />
        </template>
      </MentionCardInsights>
    </template>
  </MentionCard>
</template>

<script>
import AuthorWidget from "shared/components/AuthorWidget.vue";
import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import SocialInsightWidget from "shared/components/SocialInsightWidget.vue";
import SocialViewCountWidget from "shared/components/SocialViewCountWidget.vue";
import useMention from "shared/composables/useMention";
import { sourceIcon } from "shared/helpers/media";
import { suffix } from "shared/helpers/number";

export default {
  name: "SocialMentionCard",
  components: {
    TimeAgo,
    MentionCard,
    AuthorWidget,
    SentimentWidget,
    ReactScoreWidget,
    MentionCardInsights,
    SocialViewCountWidget,
    SocialInsightWidget,
  },
  inheritAttrs: false,
  props: {
    ...propsExcept(["title", "listTitle"]),
  },
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { mentionClicked } = useMention(mentionProps, context);

    return {
      mentionClicked,
    };
  },
  data() {
    return {
      isIntersecting: false,
    };
  },
  computed: {
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    hasSocialViewCount() {
      return !this.isMeta;
    },
    commentsCount() {
      if (this.isReddit) {
        return this.mention.comments_count;
      }

      return 0;
    },
    hasImpactScoreOrComments() {
      return (
        (this.isTwitter && this.mention.impact) ||
        this.commentsCount > 0 ||
        this.mention.score > 0
      );
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (this.hasSocialViewCount) widgets.push("SocialViewCountWidget");

      if (this.hasImpactScoreOrComments) widgets.push("SocialInsightWidget");

      if (this.canShowReactScore) widgets.push("ReactScoreWidget");

      return widgets;
    },
    listTitle() {
      // has a title
      if (
        ["reddit_post", "youtube_video", "forum_post"].includes(
          this.mention.type
        )
      ) {
        return this.mention.title;
      }

      // custom title
      if (this.mention.type === "reddit_post_comment") {
        return `r/${this.mention.source_name}`;
      }

      if (
        ["tweet", "facebook_post", "instagram_post"].includes(this.mention.type)
      ) {
        return this.mention.author;
      }

      return null;
    },
    title() {
      // has a title
      if (["reddit_post", "youtube_video"].includes(this.mention.type)) {
        return this.mention.title;
      }

      // custom title
      if (this.mention.type === "reddit_post_comment") {
        return `r/${this.mention.source_name}`;
      }

      return null;
    },
    redditSourceName() {
      if (this.mention.type === "reddit_post_comment") {
        return `u/${this.mention.author}`;
      }

      return `r/${this.mention.source_name}`;
    },
    showTooltip() {
      // disabled for now
      // return this.mention.type === 'tweet';
      return false;
    },
    showThumbnail() {
      return ["youtube_video", "instagram_post"].includes(this.mention.type);
    },
    isMeta() {
      return ["facebook_post", "instagram_post"].includes(this.mention.type);
    },
    isTwitter() {
      return this.mention.type === "tweet";
    },
    isReddit() {
      return ["reddit_post", "reddit_post_comment"].includes(this.mention.type);
    },
  },
  methods: {
    suffix,
    sourceIcon,
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    handleIntersection({ isIntersecting }) {
      this.isIntersecting = isIntersecting;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-icon {
  &.ion-checkmark-circle {
    color: rgb(73 208 103 / 50%);
  }

  &.ion-close-circle {
    color: rgb(249 88 88 / 50%);
  }

  &.ion-remove-circle {
    color: rgb(50 159 236 / 50%);
  }
}

.social-verified {
  vertical-align: 0;
  margin-left: 3px;
}
</style>
