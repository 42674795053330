<template>
  <form @submit.prevent="onSubmit">
    <div
      class="q-pa-md row no-wrap flex items-center justify-between search-bar"
    >
      <div class="search-input-container flex items-center flex-1 no-wrap">
        <UniversalSearchDropdown
          v-if="showSearchScope"
          v-model="searchScope"
          @update:model-value="$emit('search')"
        />
        <input
          ref="input"
          v-model="searchQuery"
          class="flex-1 full-height -q-pa-none"
          :class="{ 'q-pa-none': showSearchScope, 'q-px-lg': !showSearchScope }"
          :placeholder="searchPlaceholder"
          @focus="onFocus"
          @keydown.down.prevent="incrementSearchIndex"
          @keydown.up.prevent="decrementSearchIndex"
          @keyup.esc="close"
          @update:model-value="onInput"
        />
        <QIcon
          v-if="searchQuery"
          class="cursor-pointer q-mr-sm"
          color="primary"
          name="ion-close-circle"
          :size="$isDesktop ? '19px' : '14px'"
          @click="clearSearchQuery"
        />
      </div>
      <span
        class="q-ml-sm pointer cancel-button"
        @click="close"
      >
        {{ $t("global.cancel") }}
      </span>
    </div>

    <div
      v-if="showAutocompleteResults"
      class="autocomplete-results"
    >
      <template v-if="localLastSearch.length">
        <span class="block q-pa-sm q-pl-md bg-body">{{
          $t("universal_search_input.recent_searches")
        }}</span>
        <ul class="results-list q-px-sm q-py-md">
          <li
            v-for="(result, index) in localLastSearch"
            :key="result.id"
            class="result q-px-md cursor-pointer flex justify-between items-center"
            :class="{ active: index === searchIndex }"
            @click="onSelect(result)"
            @mouseover="searchIndex = -1"
          >
            <div class="flex items-baseline">
              <QIcon
                class="q-pr-sm search-icon"
                :name="icons[result.search_type]"
                size="16px"
              />
              <span class="result-query">{{ truncate(result.query, 85) }}</span>
              <template v-if="result.search_type !== 'journalists'">
                <span class="q-py-md q-px-sm text-ds-denim-7">{{
                  dateRangeLabel(result)
                }}</span>
                <div
                  v-for="medium in enabledMedia(result)"
                  :key="medium.label"
                >
                  <QIcon
                    class="q-mr-xs"
                    :name="`img:${medium.icon}`"
                    size="20px"
                  />
                  <StreemTooltip> {{ medium.label }} </StreemTooltip>
                </div>
              </template>
            </div>
            <QBtn
              class="q-mr-sm soft pointer remove-btn"
              dense
              :disable="removing"
              flat
              icon="ion-close"
              size="xs"
              @click.stop="removeResult(result)"
            />
          </li>
        </ul>
      </template>
    </div>
  </form>
</template>

<script>
import { debounce } from "lodash-es";
import { storeToRefs } from "pinia";

import UniversalSearchDropdown from "shared/components/search/UniversalSearchDropdown.vue";
import useContentWarning from "shared/composables/useContentWarning";
import DateRange from "shared/helpers/DateRange";
import { getSources } from "shared/helpers/media";
import { truncate } from "shared/helpers/string";
import { useUserStore } from "shared/stores/user";

export default {
  name: "UniversalSearchInput",
  components: {
    UniversalSearchDropdown,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  emits: [
    "update:scope",
    "close",
    "clear",
    "select",
    "update:modelValue",
    "search",
  ],
  setup() {
    const { openSearchCoverageContentWarningModal } = useContentWarning();

    const userStore = useUserStore();
    const { adminUserEnabled, customerViewEnabled } = storeToRefs(userStore);

    return {
      adminUserEnabled,
      customerViewEnabled,
      openSearchCoverageContentWarningModal,
    };
  },
  data() {
    return {
      searchQuery: "",
      lastSearchQuery: "",
      lastSearches: [],
      searchIndex: -1,
      focusing: false,
      editing: false,
      removing: false,
      debouncedGetLastSearches: debounce(this.getLastSearches, 500),
      icons: {
        streams: "ion-search",
        journalists: "ion-person",
        all: "ion-globe",
      },
    };
  },
  computed: {
    canSearchAvailableContent() {
      if (this.adminUserEnabled && !this.customerViewEnabled) return true;

      return (
        this.$features.has("has_search_available_content") &&
        this.$permissions.has("can_search_available_content")
      );
    },
    showSearchScope() {
      if (this.canSearchAvailableContent) {
        return true;
      }

      return !this.$features.has("has_outreach_2");
    },
    searchPlaceholder() {
      return this.scope === "streams" && this.$isDesktop
        ? this.$t("universal_search_input.search_coverage")
        : this.$t("global.search");
    },
    searchScope: {
      get() {
        return this.scope;
      },
      set(value) {
        this.$emit("update:scope", value);
      },
    },
    selectedSearchOption() {
      return this.lastSearches[this.searchIndex] || false;
    },
    showAutocompleteResults() {
      return (
        ((!this.searchQuery && this.lastSearches.length) || this.editing) &&
        this.focusing
      );
    },
    localLastSearch() {
      let { lastSearches } = this;

      if (this.$features.has("has_outreach_2")) {
        lastSearches = lastSearches.filter(
          (search) => search.search_type !== "journalists"
        );
      }

      if (!this.canSearchAvailableContent) {
        lastSearches = lastSearches.filter(
          (search) => search.search_type !== "all"
        );
      }

      return lastSearches;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.searchQuery = this.modelValue;
      },
    },
  },
  methods: {
    truncate,
    close() {
      this.$emit("close");
    },
    async focus() {
      await this.$nextTick();
      this.$refs.input.focus();
    },
    incrementSearchIndex() {
      if (this.searchIndex + 1 <= this.lastSearches.length - 1) {
        this.searchIndex += 1;
      }
    },
    decrementSearchIndex() {
      if (this.searchIndex - 1 >= -1) {
        this.searchIndex -= 1;
      }
    },
    clearSearchQuery() {
      this.searchQuery = "";
      this.$emit("clear");
      this.focus();
      this.getLastSearches();
    },
    onFocus() {
      this.focusing = true;

      if (!this.searchQuery && !this.lastSearches.length) {
        this.getLastSearches();
      }
    },
    onInput() {
      if (!this.searchQuery.length) {
        this.clearSearchQuery();
      } else {
        this.editing = true;
        this.focusing = true;
        this.searchIndex = -1;
        this.debouncedGetLastSearches();
      }
    },
    async onSelect(result) {
      if (result.search_type === "streams" && result.print_content) {
        await this.openSearchCoverageContentWarningModal();
      }

      this.searchQuery = result.query;
      this.$emit("select", result);
      this.focusing = false;
      this.editing = false;
      this.searchIndex = -1;
    },
    onSubmit() {
      if (this.selectedSearchOption) {
        this.onSelect(this.selectedSearchOption);
      } else if (
        this.searchQuery.length &&
        this.searchQuery !== this.lastSearchQuery
      ) {
        this.$emit("update:modelValue", this.searchQuery);
        this.$emit("search");
        this.editing = false;
        this.focusing = false;
        this.searchIndex = -1;
        this.lastSearchQuery = this.searchQuery;
      }
    },
    async getLastSearches() {
      this.searchIndex = -1;

      this.lastSearches = (
        await this.$streemApiV1.get("universal_searches", {
          params: {
            ...(this.searchQuery && { q: this.searchQuery }),
          },
        })
      ).data;
    },
    async removeResult(result) {
      this.removing = true;
      await this.$streemApiV1.delete(`universal_searches/${result.id}`);
      this.lastSearches.splice(this.lastSearches.indexOf(result), 1);
      this.removing = false;
    },
    enabledMedia(search) {
      return getSources().filter((source) => search[`${source.field}_content`]);
    },
    dateRangeLabel(search) {
      return new DateRange(search).toString();
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 0;
  font-size: 16px;
  background: $body-background;
}

.flex-1 {
  flex: 1;
}

.search-input-container {
  background: $body-background;
  height: 32px;
  border-radius: 2px;
  overflow: hidden;
}

.search-icon {
  color: $ds-denim-7;
}

.result-query {
  color: black;
  font-size: 16px;
}

.close-icon {
  &:hover {
    color: #444;
  }
}

.cancel-button {
  font-size: 12px;

  &:hover {
    color: #444;
  }
}

.autocomplete-results {
  background: $body-background;
  border-top: 1px solid #ddd;

  .results-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .result {
      height: 50px;
      background: white;
      border-bottom: 1px solid #ddd;

      &:first-child {
        border-top: 1px solid #ddd;
      }

      .tag {
        background: #272727;
        color: white;
        border-radius: 4px;
        font-size: 12px;
      }
    }

    li {
      &.active,
      &:hover {
        background: $hover-background;

        .remove-btn {
          display: inline;

          &:hover {
            color: white;
          }
        }
      }

      .remove-btn {
        display: none;
      }
    }
  }
}

.desktop {
  .search-input-container {
    height: 57px;
  }

  .cancel-button {
    font-size: 16px;
  }

  .autocomplete-results {
    border-radius: 4px;

    .result:last-child {
      border-bottom: none;
    }
  }
}
</style>
