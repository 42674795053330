<template>
  <AlertMessage
    icon-size="20px"
    type="info"
  >
    <i18n-t
      keypath="mention_restriction.message"
      scope="global"
      tag="span"
    >
      <template #readMore>
        <a
          class="text-ds-blue-6 tw-underline"
          :href="(appConfig as AppConfig).urls.mentionRestriction"
          rel="noopener noreferrer"
          target="_blank"
          >{{ getLocaleText("mention_restriction.read_more") }}</a
        >
      </template>
    </i18n-t>
  </AlertMessage>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { getLocaleText } from "shared/boot/i18n";
import { AlertMessage } from "shared/components/base";
import type { AppConfig } from "shared/services/appConfig/types";
import useGlobalStore from "shared/stores/global";

const { appConfig } = storeToRefs(useGlobalStore());
</script>
