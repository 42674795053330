<template>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <div>
      <Tabs
        v-model="tab"
        class="tw-w-full tw-font-bold"
        type="toggles"
      >
        <Tab
          class="tw-flex-1"
          :label="
            getLocaleText('react_score_expandable.react_score_average_button')
          "
          name="average"
          new-design
          type="toggle"
        />
        <Tab
          class="tw-flex-1"
          :label="
            getLocaleText('react_score_expandable.react_categories_button')
          "
          name="categories"
          new-design
          type="toggle"
        />
      </Tabs>
    </div>

    <template v-if="reactScoreAverageSelected">
      <RiskRangeSelector
        v-model="reactScore"
        class="q-pt-sm"
        :sub-title-prefix="
          getLocaleText('react_score_expandable.react_score_average.sub_title')
        "
        :title="
          getLocaleText('react_score_expandable.react_score_average.title')
        "
        :tooltip-text="
          getLocaleText('react_score_expandable.react_score_average.tooltip')
        "
      />
    </template>

    <div v-else>
      <div class="tw-mb-4 tw-text-xs">
        {{ getLocaleText("react_score_expandable.react_score_grouped_into_4") }}
      </div>

      <RiskRangeSelector
        v-model="aggregateFakeness"
        :sub-title-prefix="
          getLocaleText('react_score_expandable.controversy.sub_title')
        "
        :title="getLocaleText('react_score_expandable.controversy.title')"
        :tooltip-text="
          getLocaleText('react_score_expandable.controversy.tooltip')
        "
      />

      <RiskRangeSelector
        v-model="aggregateEmotionality"
        :sub-title-prefix="
          getLocaleText('react_score_expandable.emotionality.sub_title')
        "
        :title="getLocaleText('react_score_expandable.emotionality.title')"
        :tooltip-text="
          getLocaleText('react_score_expandable.emotionality.tooltip')
        "
      />

      <RiskRangeSelector
        v-model="aggregateHarmful"
        :sub-title-prefix="
          getLocaleText('react_score_expandable.harm.sub_title')
        "
        :title="getLocaleText('react_score_expandable.harm.title')"
        :tooltip-text="getLocaleText('react_score_expandable.harm.tooltip')"
      />

      <RiskRangeSelector
        v-model="aggregateSpam"
        :sub-title-prefix="
          getLocaleText('react_score_expandable.spam.sub_title')
        "
        :title="getLocaleText('react_score_expandable.spam.title')"
        :tooltip-text="getLocaleText('react_score_expandable.spam.tooltip')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { Tab, Tabs } from "shared/components/base";
import RiskRangeSelector from "shared/components/selectors/RiskRangeSelector.vue";
import { type StreamFiltersMinMax } from "shared/helpers/StreamFilters";

const tab = computed({
  get() {
    if (reactScoreAverageSelected.value) {
      return "average";
    }

    return "categories";
  },
  set(value) {
    reactScoreAverageSelected.value = value === "average";
  },
});

const reactScore = defineModel<StreamFiltersMinMax>("reactScore", {
  required: true,
});

const reactScoreAverageSelected = defineModel<boolean>(
  "reactScoreAverageSelected"
);

const aggregateEmotionality = defineModel<StreamFiltersMinMax>(
  "aggregateEmotionality",
  {
    required: true,
  }
);

const aggregateFakeness = defineModel<StreamFiltersMinMax>(
  "aggregateFakeness",
  {
    required: true,
  }
);

const aggregateSpam = defineModel<StreamFiltersMinMax>("aggregateSpam", {
  required: true,
});

const aggregateHarmful = defineModel<StreamFiltersMinMax>("aggregateHarmful", {
  required: true,
});
</script>
