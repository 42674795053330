<template>
  <div
    class="col-12"
    :class="gutterClass"
  >
    <div
      v-for="mention in localMentions"
      :key="`${mention.type}-${mention.id}`"
    >
      <MentionCardFactory
        action-sheet-component="ActionSheet"
        :mention="mention"
        :mentions="localMentions"
        :no-highlighting="noHighlighting"
        :options="options"
        :product="product"
        :stream="stream"
        @click="openMention($event)"
        @mention-removed="removeMention"
      />

      <MatchingStreams
        v-if="mention.stream_ids"
        :mention="mention"
      />
    </div>
    <div v-if="!localMentions.length && !showMoreContentOption">
      <slot name="emptyMentions">
        <MediaNotFoundCard
          class="q-pb-sm q-mt-md"
          :range="range"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import MatchingStreams from "shared/components/MatchingStreams.vue";
import MediaNotFoundCard from "shared/components/MediaNotFoundCard.vue";
import { propsExcept } from "shared/components/MentionCard";
import MentionCardFactory from "shared/components/MentionCardFactory";
import DateRange from "shared/helpers/DateRange";

export default {
  name: "MentionStreamList",
  components: {
    MentionCardFactory,
    MediaNotFoundCard,
    MatchingStreams,
  },
  props: {
    ...propsExcept(["mention", "mentions", "actionSheetComponent"]),
    mentions: {
      type: Array,
      required: true,
    },
    range: {
      type: DateRange,
      default: () => DateRange.lastThirtyDays(),
    },
    showMoreContentOption: {
      type: Boolean,
    },
    product: {
      type: String,
      default: null,
    },
    gutterClass: {
      type: String,
      default: "q-gutter-sm",
    },
  },
  emits: ["mention-clicked", "mention-removed"],
  data() {
    return {
      localMentions: [],
    };
  },
  watch: {
    mentions: {
      deep: true,
      handler(mentions) {
        this.localMentions = [...mentions];
      },
    },
  },
  mounted() {
    this.localMentions = [...this.mentions];
  },
  methods: {
    openMention(mention) {
      this.$emit("mention-clicked", mention);
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  font-size: 12px;
}

.card {
  &.warning {
    h3 {
      margin: 12px 0;
    }

    .q-icon {
      vertical-align: -3px;
    }
  }
}

.dashed-underline {
  border-bottom: 1px dashed red;
}

.scoped:deep(.mention-dropdown) {
  .q-btn-inner > .q-icon {
    font-size: 20px;
  }
}
</style>
