import { type Fn, onClickOutside, useEventListener } from "@vueuse/core";
import type { MaybeRef, Ref } from "vue";
import { onBeforeUnmount, ref, watch } from "vue";

type ClickOutsideEventListener = "mousedown" | "click";

export type ClickOutsideOptions = {
  capture?: boolean;
  events?: ClickOutsideEventListener[];
  active: Ref<boolean>;
  middleware?: (event: Event) => boolean;
};

export function useClickOutside(
  element: MaybeRef,
  handler: (event: Event) => void,
  options: ClickOutsideOptions = {
    events: ["click"],
    active: ref(true),
    capture: true,
  }
) {
  const events = options.events || ["click"];
  const eventListeners: Fn[] = [];

  let stopClickOutside: Fn = () => {};

  function shouldClickOutside(event: Event) {
    if (!options.middleware) return true;

    return options.middleware(event);
  }

  function setup() {
    const { stop, cancel, trigger } = onClickOutside(
      element,
      (event) => {
        if (shouldClickOutside(event)) {
          handler(event);
        }
      },
      {
        capture: options.capture,
        controls: true,
      }
    );

    stopClickOutside = stop;

    function handleEvent(event: Event) {
      if (shouldClickOutside(event)) {
        trigger(event);
      } else {
        cancel();
      }
    }

    events.forEach((eventName) => {
      eventListeners.push(
        useEventListener(document, eventName, handleEvent, options.capture)
      );
    });
  }

  function teardown() {
    eventListeners.forEach((cleanup) => {
      cleanup();
    });

    stopClickOutside();
  }

  watch(
    () => options.active.value,
    (active) => {
      if (active) {
        setup();
      } else {
        teardown();
      }
    },
    { immediate: true, deep: true }
  );

  onBeforeUnmount(teardown);
}
