<template>
  <div
    class="tabs flex"
    :class="{ 'tw-rounded-full tw-bg-denim-100 tw-p-1': type === 'toggles' }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: "Tabs",
};
</script>

<script setup lang="ts">
import { type Ref, nextTick, onBeforeUnmount, provide, watch } from "vue";

import { TabsSymbol } from "shared/components/base/symbols";
import { type Tab } from "shared/components/base/Tabs/Tab.vue";

export interface Props {
  duration?: number | string;
  type?: "default" | "toggles";
}

export interface TabsProvide {
  currentTab: Ref<Tab["name"]>;
  selectTab: (name: Tab["name"]) => void;
  register: (tab: Tab) => void;
  unregister: (tab: Tab) => void;
}

const props = withDefaults(defineProps<Props>(), {
  duration: 250,
  type: "default",
});

const currentTab = defineModel<Tab["name"]>({
  required: true,
});

let animateTimer: ReturnType<typeof setTimeout>;
const tabsList: Tab[] = [];

const animate = (oldName: string | number, newName: string | number) => {
  const oldTab = oldName ? tabsList.find((tab) => tab.name === oldName) : null;

  const newTab = newName ? tabsList.find((tab) => tab.name === newName) : null;

  if (oldTab?.indicator.value && newTab?.indicator.value) {
    const oldEl = oldTab.indicator.value;
    const newEl = newTab.indicator.value;

    clearTimeout(animateTimer);

    oldEl.style.transition = "none";
    oldEl.style.transform = "none";
    newEl.style.transition = "none";
    newEl.style.transform = "none";

    const oldPos = oldEl.getBoundingClientRect();
    const newPos = newEl.getBoundingClientRect();

    newEl.style.transform = `
      translate3d(${oldPos.left - newPos.left}px,0,0)
    `;

    newEl.style.width = `${oldPos.width}px`;

    nextTick(() => {
      animateTimer = setTimeout(() => {
        newEl.style.transition = `all ${props.duration}ms cubic-bezier(.3, 0, .2, 1)`;
        newEl.style.transform = "none";
        newEl.style.removeProperty("width");
      }, 150);
    });
  }
};

const selectTab: TabsProvide["selectTab"] = (name) => {
  animate(currentTab.value, name);
  currentTab.value = name;
};

const register: TabsProvide["register"] = (tab) => {
  tabsList.push(tab);
};

const unregister: TabsProvide["unregister"] = (tab) => {
  tabsList.splice(tabsList.indexOf(tab), 1);
};

watch(currentTab, (name) => {
  if (currentTab.value !== name) {
    selectTab(name);
  }
});

onBeforeUnmount(() => {
  clearTimeout(animateTimer);
});

provide(TabsSymbol, {
  currentTab,
  selectTab,
  register,
  unregister,
});
</script>
