import {
  add,
  addDays,
  addMonths,
  addYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getDay,
  isAfter,
  isBefore,
  parse,
  roundToNearestMinutes,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { storeToRefs } from "pinia";
import { type ComputedRef, type Ref, computed, ref, toRefs, watch } from "vue";

import { getLocaleValue } from "shared/boot/i18n";
import {
  formatIntlDate,
  fromCurrentToGivenTimezone,
  getTimezone,
} from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

export type CalendarModel = Date | null;

export interface CalendarProps {
  minDate?: Date | null;
  maxDate?: Date | null;
  range?: DateRange | null;
  intervalView?: "day" | "month";
  showPreviousIntervals?: boolean;
  showDateInput?: boolean;
  showTimeInput?: boolean;
  showRangeOptions?: boolean;
  hideUserTimeZone?: boolean;
  rangeSelection?: "day" | "custom" | "week" | "month";
  showUpdateButton?: boolean;
  limitToPastYear?: boolean;
  minuteStep?: number;
  rangeOptionsOnly?: boolean;
  allowAllTime?: boolean;
  allowLastYearShortcut?: boolean;
  actionButtonLabel?: string;
  showSeconds?: boolean;
  useLocalTimeZone?: boolean;
}

export interface CalendarEmit {
  (event: "update:range", dateRange: DateRange | null): void;
  (event: "cancel"): void;
  (event: "updated"): void;
}

export interface CalendarDateInterval {
  disabled: boolean;
  isCurrent: boolean;
  active: boolean;
  start: boolean;
  end: boolean;
  isWithinRange: boolean;
  date: Date;
  pending: boolean;
  label: string;
}

export interface Calendar {
  previousDateIntervals: ComputedRef<CalendarDateInterval[]>;
  dateIntervals: ComputedRef<CalendarDateInterval[]>;
  startDate: Ref<Date>;
  startDateDay: Ref<string>;
  startDateTime: Ref<Date>;
  endDate: Ref<Date>;
  endDateDay: Ref<string>;
  endDateTime: Ref<Date>;
  intervalLabel: ComputedRef<string>;
  previousIntervalLabel: ComputedRef<string>;
  canShowPreviousIntervals: ComputedRef<boolean>;
  canShowNextIntervals: ComputedRef<boolean>;
  pendingDate: Ref<Date | null>;
  dateRange: Ref<DateRange | null>;
  dateRangeLabel: ComputedRef<string>;
  isSelecting: Ref<boolean>;
  currentInterval: Ref<string>;
  calendarDate: Ref<Date>;
  previousCalendarDate: Ref<Date>;
  firstDate: ComputedRef<Date>;
  lastDate: ComputedRef<Date | null>;
  currentUserTimezone: string;

  setDate: (newDate: Date, disabled?: boolean) => void;
  setCustomDate: (newDate: Date) => void;
  setDateRange: (newRange: DateRange) => void;
  setDateRangeByLabel: (rangeLabel: string) => void;
  setPendingDate: (newPendingDate: CalendarDateInterval | null) => void;
  setInterval: () => void;
  gotoPrevious: () => void;
  gotoNext: () => void;
  updateRange: () => void;
}

export default function useCalendar(
  date: Ref<CalendarModel>,
  props: CalendarProps,
  { emit }: { emit: CalendarEmit }
): Calendar {
  const { hasAdvancedAccess, organisation, currentUser } =
    storeToRefs(useUserStore());

  const { getContentDateCap, getLowestSearcheableDate } =
    storeToRefs(useGlobalStore());

  const {
    minDate = ref(null),
    maxDate = ref(null),
    range = ref(null),
    rangeSelection = ref(),
    showUpdateButton = ref(false),
    limitToPastYear = ref(false),
    intervalView = ref(null),
    minuteStep = ref(),
    showPreviousIntervals = ref(false),
    allowAllTime = ref(false),
    useLocalTimeZone = ref(false),
  } = toRefs(props);

  const currentUserTimezone = useLocalTimeZone.value
    ? getTimezone()
    : currentUser.value.time_zone;

  const currentUserDate = computed(() =>
    fromCurrentToGivenTimezone(new Date(), currentUserTimezone)
  );

  const currentInterval = ref(intervalView.value || "day");

  const calendarDate = ref(
    currentInterval.value === "month"
      ? startOfYear(currentUserDate.value)
      : startOfMonth(currentUserDate.value)
  );

  const previousCalendarDate = computed(() => {
    if (showPreviousIntervals.value) {
      if (currentInterval.value === "month") {
        return subYears(calendarDate.value, 1);
      }

      return subMonths(calendarDate.value, 1);
    }

    return calendarDate.value;
  });

  const startDate = ref(date.value || currentUserDate.value);
  const endDate = ref(date.value || currentUserDate.value);
  const pendingDate = ref<Date | null>(null);

  const firstDate = computed(() => {
    if (allowAllTime.value) {
      return null;
    }

    if (minDate.value) {
      return fromCurrentToGivenTimezone(minDate.value, currentUserTimezone);
    }

    const maxDateRange = organisation.value?.max_date_range
      ? organisation.value.max_date_range
      : getContentDateCap.value();

    if (maxDateRange.toString().length >= 10) {
      const orgMinDate = new Date(maxDateRange * 1000);
      const userMinDate = getLowestSearcheableDate.value(currentUserDate.value);

      if (limitToPastYear.value && isBefore(orgMinDate, userMinDate)) {
        return userMinDate;
      }

      return orgMinDate;
    }

    if (!limitToPastYear.value) {
      return null;
    }

    const months = hasAdvancedAccess.value ? 12 : maxDateRange;

    return subMonths(startOfDay(currentUserDate.value), months);
  });

  const lastDate = computed(() => {
    if (maxDate.value) {
      return fromCurrentToGivenTimezone(maxDate.value, currentUserTimezone);
    }

    if (limitToPastYear.value) {
      return fromCurrentToGivenTimezone(
        endOfDay(new Date()),
        currentUserTimezone
      );
    }

    return null;
  });

  const dateRange = ref<DateRange | null>(null);

  function isBeforeLastDate(checkDate: Date, interval?: string) {
    if (!firstDate.value) {
      return false;
    }

    if (interval === "year") {
      return isBefore(checkDate, startOfYear(firstDate.value));
    }

    if (interval === "month") {
      return isBefore(checkDate, startOfMonth(firstDate.value));
    }

    return isBefore(checkDate, startOfDay(firstDate.value));
  }

  function isAfterLastDate(checkDate: Date, interval?: string) {
    if (!lastDate.value) {
      return false;
    }

    if (interval === "year") {
      return isAfter(checkDate, endOfYear(lastDate.value));
    }

    if (interval === "month") {
      return isAfter(checkDate, endOfMonth(lastDate.value));
    }

    return isAfter(checkDate, lastDate.value);
  }

  function emitDate(force?: boolean) {
    if (!force && showUpdateButton.value) {
      return;
    }

    if (rangeSelection.value === "day") {
      Object.assign(date, {
        value: zonedTimeToUtc(endDate.value, currentUserTimezone),
      });

      return;
    }

    emit(
      "update:range",
      DateRange.fromDates(
        zonedTimeToUtc(startDate.value, currentUserTimezone),
        zonedTimeToUtc(endDate.value, currentUserTimezone)
      )
    );
  }

  function updateRange() {
    emitDate(true);
    emit("updated");
  }

  function matchesFormat(value: string) {
    const regExp = new RegExp(getLocaleValue("date.regex"));

    return regExp.test(value);
  }

  const startDateDay = computed({
    get() {
      return format(startDate.value, getLocaleValue("date.format"));
    },
    set(value: string) {
      if (!matchesFormat(value)) {
        return;
      }

      const parsedDate = parse(
        value,
        getLocaleValue("date.format"),
        startDate.value
      );

      if (parsedDate.toString() === "Invalid Date") {
        return;
      }

      if (isBeforeLastDate(parsedDate) || isAfterLastDate(parsedDate)) {
        return;
      }

      if (isAfter(parsedDate, endDate.value)) {
        startDate.value = endDate.value;
        endDate.value = parsedDate;

        return;
      }

      startDate.value = parsedDate;
      emitDate();
    },
  });

  const startDateTime = computed({
    get() {
      return startDate.value;
    },
    set(value: Date) {
      startDate.value = value;
      emitDate();
    },
  });

  const endDateDay = computed({
    get() {
      return format(endDate.value, getLocaleValue("date.format"));
    },
    set(value: string) {
      if (!matchesFormat(value)) {
        return;
      }

      const parsedDate = parse(
        value,
        getLocaleValue("date.format"),
        startDate.value
      );

      if (parsedDate.toString() === "Invalid Date") {
        return;
      }

      if (isBeforeLastDate(parsedDate) || isAfterLastDate(parsedDate)) {
        return;
      }

      if (
        rangeSelection.value !== "day" &&
        isBefore(parsedDate, startDate.value)
      ) {
        endDate.value = startDate.value;
        startDate.value = parsedDate;

        return;
      }

      endDate.value = parsedDate;

      if (rangeSelection.value === "day") {
        startDate.value = endDate.value;
      }

      calendarDate.value = endDate.value;
      emitDate();
    },
  });

  const endDateTime = computed({
    get() {
      return endDate.value;
    },
    set(value: Date) {
      endDate.value = value;

      if (rangeSelection.value === "day") {
        startDate.value = endDate.value;
      }

      emitDate();
    },
  });

  const canShowPreviousIntervals = computed(() => {
    const interval = currentInterval.value === "month" ? "year" : "month";

    const previousDate =
      currentInterval.value === "month"
        ? subYears(previousCalendarDate.value, 1)
        : subMonths(previousCalendarDate.value, 1);

    return !isBeforeLastDate(previousDate, interval);
  });

  const canShowNextIntervals = computed(() => {
    const interval = currentInterval.value === "month" ? "year" : "month";

    const nextDate =
      currentInterval.value === "month"
        ? addYears(calendarDate.value, 1)
        : addMonths(calendarDate.value, 1);

    return !isAfterLastDate(nextDate, interval);
  });

  const isSelecting = ref(false);

  function getDateIntervals(activeDate: Date): CalendarDateInterval[] {
    const monthStart = startOfMonth(activeDate);
    const dayNumInWeek = getDay(monthStart);

    const calendarStart = subDays(
      monthStart,
      dayNumInWeek !== 0 ? dayNumInWeek : 7
    );

    let intervals = [];

    if (currentInterval.value === "month") {
      const yearStart = startOfYear(activeDate);
      const yearEnd = endOfYear(activeDate);

      intervals = eachMonthOfInterval({
        start: yearStart,
        end: yearEnd,
      });
    } else {
      intervals = eachDayOfInterval({
        start: calendarStart,
        end: addDays(calendarStart, 41),
      });
    }

    return intervals.map((intervalDate) => {
      let isCurrent = false;

      if (currentInterval.value === "month") {
        isCurrent = intervalDate.getFullYear() === activeDate.getFullYear();
      } else {
        isCurrent = intervalDate.getMonth() === activeDate.getMonth();
      }

      const dateFormat =
        currentInterval.value === "month" ? "yyyyMM" : "yyyyMMdd";

      const formattedIntervalDate = format(intervalDate, dateFormat);

      let isStartDate =
        format(startDate.value, dateFormat) === formattedIntervalDate;

      let isEndDate =
        format(endDate.value, dateFormat) === formattedIntervalDate;

      let isWithinRange = false;

      const disabled =
        isBeforeLastDate(intervalDate, currentInterval.value) ||
        isAfterLastDate(intervalDate, currentInterval.value);

      const active = isStartDate || isEndDate;
      let pending = false;

      if (isSelecting.value && pendingDate.value) {
        const formattedPendingDate = format(pendingDate.value, dateFormat);
        pending = formattedPendingDate === formattedIntervalDate;

        if (isBefore(pendingDate.value, startDate.value)) {
          isWithinRange =
            isAfter(intervalDate, pendingDate.value) &&
            isBefore(intervalDate, endDate.value);

          isStartDate = formattedPendingDate === formattedIntervalDate;
        } else {
          isWithinRange =
            isAfter(intervalDate, startDate.value) &&
            isBefore(intervalDate, pendingDate.value);

          isEndDate = formattedPendingDate === formattedIntervalDate;
        }

        pending = isStartDate || isEndDate;
      } else {
        isWithinRange =
          active ||
          (isAfter(intervalDate, startDate.value) &&
            isBefore(intervalDate, endDate.value));
      }

      if (isStartDate && isEndDate) {
        isStartDate = false;
        isEndDate = false;
      }

      let formatOptions: Intl.DateTimeFormatOptions = { day: "numeric" };

      if (currentInterval.value === "month") {
        formatOptions = { month: "short" };
      }

      return {
        disabled,
        isCurrent,
        active,
        start: isStartDate,
        end: isEndDate,
        isWithinRange,
        date: intervalDate,
        pending,
        label: formatIntlDate(intervalDate, formatOptions),
      };
    });
  }

  function gotoPrevious() {
    if (!canShowPreviousIntervals.value) return;

    if (currentInterval.value === "month") {
      calendarDate.value = subYears(calendarDate.value, 1);

      return;
    }

    calendarDate.value = subMonths(calendarDate.value, 1);
  }

  function gotoNext() {
    if (!canShowNextIntervals.value) return;

    if (currentInterval.value === "month") {
      calendarDate.value = addYears(calendarDate.value, 1);

      return;
    }

    calendarDate.value = addMonths(calendarDate.value, 1);
  }

  function setDateRange(newRange: DateRange): void {
    dateRange.value = newRange;

    startDate.value = fromCurrentToGivenTimezone(
      newRange.after * 1000,
      currentUserTimezone
    );

    endDate.value = fromCurrentToGivenTimezone(
      newRange.before * 1000,
      currentUserTimezone
    );
  }

  function setDateRangeByLabel(rangeLabel: string): void {
    setDateRange(DateRange.rangeForLabel(rangeLabel));
  }

  function setDate(newDate: Date, disabled?: boolean): void {
    if (disabled) {
      return;
    }

    if (currentInterval.value === "month" && intervalView.value !== "month") {
      currentInterval.value = "day";
      calendarDate.value = newDate;

      return;
    }

    dateRange.value = null;

    if (isSelecting.value) {
      isSelecting.value = false;

      if (isBefore(newDate, startDate.value)) {
        endDate.value = endOfDay(startDate.value);
        startDate.value = startOfDay(newDate);
        emitDate();

        return;
      }

      endDate.value = endOfDay(newDate);
      emitDate();

      return;
    }

    if (rangeSelection.value === "custom") {
      startDate.value = startOfDay(newDate);
      endDate.value = endOfDay(newDate);
      isSelecting.value = true;

      return;
    }

    if (rangeSelection.value === "day") {
      startDate.value = add(newDate, {
        hours: startDate.value.getHours(),
        minutes: startDate.value.getMinutes(),
        seconds: startDate.value.getSeconds(),
      });

      endDate.value = startDate.value;
    }

    if (rangeSelection.value === "week") {
      startDate.value = startOfWeek(newDate);
      endDate.value = endOfWeek(newDate);
    }

    if (rangeSelection.value === "month") {
      startDate.value = startOfMonth(newDate);
      endDate.value = endOfMonth(newDate);
    }

    emitDate();
  }

  function setCustomDate(newDate: Date): void {
    startDate.value = newDate;
    endDate.value = newDate;
    calendarDate.value = newDate;
    emitDate();
  }

  function setPendingDate(newPendingDate: CalendarDateInterval | null): void {
    if (!newPendingDate || !isSelecting.value) {
      pendingDate.value = null;

      return;
    }

    if (newPendingDate.disabled) return;
    pendingDate.value = newPendingDate.date;
  }

  function setInterval() {
    if (currentInterval.value !== "month") {
      currentInterval.value = "month";
    }
  }

  const dateIntervals = computed(() => getDateIntervals(calendarDate.value));

  const previousDateIntervals = computed<CalendarDateInterval[]>(() =>
    getDateIntervals(previousCalendarDate.value)
  );

  const intervalFormat: ComputedRef<Intl.DateTimeFormatOptions> = computed(
    () => {
      if (currentInterval.value === "month") {
        return {
          year: "numeric",
        };
      }

      return {
        month: "long",
        year: "numeric",
      };
    }
  );

  const intervalLabel = computed(() =>
    formatIntlDate(calendarDate.value, intervalFormat.value)
  );

  const previousIntervalLabel = computed(() =>
    formatIntlDate(previousCalendarDate.value, intervalFormat.value)
  );

  const dateRangeLabel = computed(() =>
    dateRange.value?.range ? dateRange.value.range.label : ""
  );

  if (range.value) {
    setDateRange(range.value);
    calendarDate.value = startOfMonth(endDate.value);
  } else if (rangeSelection.value === "custom") {
    setDateRange(DateRange.today());
  } else if (!date.value && rangeSelection.value === "day") {
    startDate.value = currentUserDate.value;
    endDate.value = currentUserDate.value;
  }

  watch(
    () => date,
    () => {
      if (!date.value) {
        return;
      }

      let newDate = date.value;

      if (minuteStep.value > 1) {
        newDate = roundToNearestMinutes(date.value, {
          nearestTo: minuteStep.value,
        });
      }

      startDate.value = fromCurrentToGivenTimezone(
        newDate,
        currentUserTimezone
      );

      endDate.value = startDate.value;
      calendarDate.value = startOfMonth(endDate.value);
    },
    { deep: true, immediate: true }
  );

  watch(
    () => range,
    () => {
      if (range.value) {
        setDateRange(range.value);
      }
    },
    { deep: true }
  );

  watch(
    () => dateRange,
    (newValue) => {
      if (!newValue.value || range.value === newValue.value) return;

      emit("update:range", newValue.value);
    },
    { deep: true }
  );

  return {
    previousDateIntervals,
    dateIntervals,
    startDate,
    startDateDay,
    startDateTime,
    endDate,
    endDateDay,
    endDateTime,
    intervalLabel,
    previousIntervalLabel,
    canShowPreviousIntervals,
    canShowNextIntervals,
    pendingDate,
    dateRange,
    dateRangeLabel,
    isSelecting,
    currentInterval,
    calendarDate,
    previousCalendarDate,
    firstDate,
    lastDate,
    currentUserTimezone,

    setDate,
    setCustomDate,
    setDateRange,
    setDateRangeByLabel,
    setPendingDate,
    setInterval,
    gotoPrevious,
    gotoNext,
    updateRange,
  };
}
