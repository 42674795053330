<template>
  <div
    class="tw-group tw-mb-2 tw-flex tw-cursor-pointer tw-gap-2 tw-rounded tw-border tw-border-solid tw-border-blue-200 tw-bg-blue-100 tw-text-sm tw-text-blue-700 hover:tw-border-blue-300 hover:tw-bg-blue-200"
    data-cy="selection"
    @click="emit('click')"
  >
    <div class="tw-flex-1 tw-py-3 tw-pl-4 tw-pr-2">
      <slot />
    </div>
    <div
      class="tw-items-center tw-pr-2"
      :class="{
        'tw-flex': isMobile,
        'tw-hidden group-hover:tw-flex': !isMobile,
      }"
    >
      {{ getLocaleText("global.view") }}
      <BaseIcon
        class="tw-text-lg"
        icon="angle-right"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import BaseIcon from "shared/components/base/BaseIcon.vue";

const isMobile = inject("isMobile");

const emit = defineEmits(["click"]);
</script>
