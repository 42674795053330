<template>
  <MentionCard
    class="pointer"
    :list-title="listTitle"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :options="options"
    :selected="selected"
    :show-checkbox="showCheckbox"
    :stream="stream"
    @click="$emit('click', $event)"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #before>
      <img
        v-if="poster"
        :alt="$t('transcript_request_mention_card.station_image')"
        class="station-image full-width thumbnail"
        :src="poster"
      />
    </template>

    <template #header>
      <span>
        <strong class="bigger2">
          {{ mention.source_name }}
        </strong>
      </span>
      <div class="q-pa-md row no-wrap items-start details">
        <img
          v-if="logo"
          :alt="$t('global.source_logo')"
          class="logo"
          :src="logo"
        />
        <div class="column full-width">
          <span class="bold bigger2 q-mb-sm">
            {{ mention.title }}
          </span>
          <span v-if="mention.source">
            {{ mention.source.name }} {{ mention.source.location }}
          </span>
          <span>{{
            formatIntlDate(mention.start_time, {
              weekday: "long",
              day: "numeric",
              month: "short",
              hour: "numeric",
              minute: "numeric",
            })
          }}</span>
          <span>{{ duration(mention.end_time, mention.start_time) }}</span>
          <span v-if="mention.program_airing">
            {{ mention.program_airing.name }}
          </span>
        </div>
      </div>
      <div class="q-pa-md row no-wrap items-start details">
        <QBtn
          v-if="pdfLink"
          :aria-label="$t('transcript_request_mention_card.pdf_download')"
          flat
          icon="ion-md-download"
          label="PDF"
        />
        <QBtn
          v-if="wordLink"
          :aria-label="$t('transcript_request_mention_card.word_download')"
          flat
          icon="ion-md-download"
          label="WORD"
        />
      </div>
    </template>
    <slot />
  </MentionCard>
</template>

<script>
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import { duration, formatIntlDate } from "shared/helpers/date";

export default {
  name: "TranscriptRequestMentionCard",
  components: {
    MentionCard,
  },
  props: {
    ...propsExcept(["listTitle", "logo"]),
  },
  emits: ["click", "mention-selected"],
  computed: {
    listTitle() {
      const mentionTitle = this.mention.program_airing
        ? this.mention.program_airing.name
        : this.mention.source_name;

      return String(mentionTitle);
    },
    poster() {
      return this.mention.source?.thumbnail;
    },
    logo() {
      return this.mention.source?.logo_url;
    },
    pdfLink() {
      const pdf = this.mention.attachments.find(({ filename }) =>
        filename.includes(".pdf")
      );

      return pdf ? pdf.url : null;
    },
    wordLink() {
      const word = this.mention.attachments.find(({ filename }) =>
        filename.includes(".doc")
      );

      return word ? word.url : null;
    },
  },
  methods: {
    duration,
    formatIntlDate,
  },
};
</script>
