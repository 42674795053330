<template>
  <label
    class="checkbox"
    :class="checkboxClasses"
    @contextmenu="onContextClick($event)"
  >
    <input
      :checked="isChecked"
      :disabled="disabled"
      type="checkbox"
      @change="onChange"
    />
    <span
      class="checkbox-checkmark"
      :class="{
        'checkbox-checkmark-border': newDesign && !isChecked && !indeterminate,
      }"
    />
    <span
      v-if="label"
      class="checkbox-label"
      :class="labelClass"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </span>
    <slot v-else />
  </label>
</template>

<script>
import { find, findIndex, isObject } from "lodash-es";

export default {
  name: "Checkbox",
  props: {
    modelValue: {
      type: [Boolean, Array, Object, String, Number],
      default: false,
    },
    value: {
      type: [Boolean, Array, Object, String, Number],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    leftLabel: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    indeterminate: {
      type: Boolean,
    },
    labelClass: {
      type: [Array, Object, String],
      default: "",
    },
    newDesign: {
      type: Boolean,
    },
    alignTop: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "primary",
    },
    dense: {
      type: Boolean,
    },
    preventContextDefault: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue", "update:contextClick"],
  computed: {
    checkboxClasses() {
      return [
        {
          indeterminate: this.indeterminate,
          "new-design": this.newDesign,
          "left-label": this.leftLabel,
          "align-top": this.alignTop,
          dense: this.dense,
        },
        `checkbox__${this.color}`,
      ];
    },
    isChecked() {
      if (Array.isArray(this.modelValue)) {
        if (isObject(this.value)) {
          return find(this.modelValue, { ...this.value });
        }

        return find(this.modelValue, (value) => value === this.value);
      }

      return this.modelValue;
    },
  },
  methods: {
    onChange(event) {
      if (Array.isArray(this.modelValue)) {
        const values = Array.from(this.modelValue);
        let valueIndex;

        if (isObject(this.value)) {
          valueIndex = findIndex(values, { ...this.value });
        } else {
          valueIndex = findIndex(values, (value) => value === this.value);
        }

        if (valueIndex > -1) {
          values.splice(valueIndex, 1);
        } else {
          values.push(this.value);
        }

        this.$emit("update:modelValue", values);

        return;
      }

      this.$emit("update:modelValue", event.target.checked);
    },
    onContextClick(event) {
      if (this.disabled) return;

      if (this.preventContextDefault) {
        event.preventDefault();
      }

      this.$emit("update:contextClick", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-label,
.checkbox-checkmark {
  pointer-events: none;
}

.checkbox-label {
  overflow-wrap: break-word;
  overflow: hidden;
  hyphens: auto;
}

.checkbox-checkmark-border {
  border: 1px solid $ds-denim-7;
}
</style>
