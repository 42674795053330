import { storeToRefs } from "pinia";
import { Notify } from "quasar";
import { computed, inject, nextTick, onBeforeUnmount, ref, toRefs } from "vue";

import { track } from "shared/boot/analytics";
import { getLocaleText } from "shared/boot/i18n";
import useMention from "shared/composables/useMention";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import { duration, toSeconds } from "shared/helpers/date";
import { PodcastEpisodeFragment } from "shared/resources";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default function usePodcastPlayer(props, context, popoutName) {
  const isDesktop = inject("isDesktop");
  const { mention = ref({}) } = toRefs(props);

  const universalPlayerStore = useUniversalPlayerStore();

  const { playerOpen, playerInitialClip, playerPopoutRef } =
    storeToRefs(universalPlayerStore);

  const {
    setPlayerPopoutTarget,
    playerInsertClip,
    playerShow,
    setPlayerDismissed,
  } = universalPlayerStore;

  const { playsOnTveyes } = useMention(props, context);

  const podcastEpisodeFragments = ref([]);

  const clip = ref({
    ...mention.value,
    media_url: "",
    signed_url: "",
    start_time: mention.value.timestamp,
  });

  const podcastStartTimestamp = computed(() => mention.value.timestamp);

  const podcastEndTimestamp = computed(() => {
    const lastFragment = podcastEpisodeFragments.value.slice(-1).pop();

    return (
      podcastStartTimestamp.value + toSeconds(lastFragment?.endTimeMs || 1000)
    );
  });

  const podcastDuration = computed(
    () => podcastEndTimestamp.value - podcastStartTimestamp.value
  );

  const hasDuration = computed(() => podcastDuration.value > 1);

  const formattedPodcastDuration = computed(() =>
    duration(podcastEndTimestamp.value, podcastStartTimestamp.value)
  );

  async function loadFragments() {
    if (playsOnTveyes.value) return;

    try {
      podcastEpisodeFragments.value = (
        await PodcastEpisodeFragment.where({
          podcastEpisodeId: mention.value.id,
        })
          .select(["text", "startTimeMs", "endTimeMs"])
          .per(999)
          .all()
      ).data;
    } catch {
      Notify.create({
        type: "negative",
        message: getLocaleText("podcast_player.load_podcast_fragments_error"),
      });
    }
  }

  async function loadClipInformation() {
    if (podcastEpisodeFragments.value.length) return;

    try {
      await loadFragments();

      clip.value = {
        ...mention.value,
        ...clip.value,
        start_time: podcastStartTimestamp.value,
        end_time: podcastEndTimestamp.value,
      };
    } catch {
      Notify.create({
        type: "negative",
        message: getLocaleText("podcast_player.load_podcast_information_error"),
      });
    }
  }

  function setPlayerTarget(playInComponent) {
    if (playInComponent) {
      setPlayerPopoutTarget(popoutName);

      track("Playing the episode fragments within the component", {
        type: "podcast",
      });
    } else {
      setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);

      track("Playing the episode fragments in the popout player", {
        type: "podcast",
      });
    }
  }

  function launchPlayer(playInComponent = true) {
    playerInsertClip(clip.value);
    if (!playerOpen.value) playerShow();

    nextTick(() => {
      playerPopoutRef.value.playNow(clip.value);
    });

    setPlayerTarget(playInComponent);
    setPlayerDismissed(false);
  }

  function isPopoutPlayerPlayingThisClip() {
    return (
      playerInitialClip.value &&
      clip.value &&
      playerInitialClip.value.id === clip.value.id
    );
  }

  onBeforeUnmount(() => {
    if (isPopoutPlayerPlayingThisClip() && playerOpen.value && isDesktop) {
      setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);

      track("Playing the clip in the popout player", {
        type: "podcast",
      });
    }
  });

  return {
    podcastStartTimestamp,
    podcastEndTimestamp,
    podcastDuration,
    formattedPodcastDuration,
    hasDuration,
    podcastEpisodeFragments,
    clip,
    loadClipInformation,
    isPopoutPlayerPlayingThisClip,
    setPlayerTarget,
    launchPlayer,
  };
}
