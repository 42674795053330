import { type Ref, computed, inject, isRef, ref, toRef } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { streamTypes } from "shared/constants";
import { toSentence } from "shared/helpers/array";
import { getMediaForStream } from "shared/helpers/media";
import StreamFilters from "shared/helpers/StreamFilters";
import { useStreamsStore } from "shared/stores/streams";
import type { Nullable, Stream } from "shared/types";

interface UseStreamFilteringProps {
  stream: Stream;
  filters?: StreamFilters | Ref<StreamFilters>;
}

interface UseStreamFilteringSelected {
  [key: string]: number;
}

export default function useStreamFiltering(options: UseStreamFilteringProps) {
  const stream = toRef<Stream>(options.stream);

  const localFilters = ref<StreamFilters>(
    options.filters && !isRef(options.filters)
      ? options.filters
      : new StreamFilters(stream.value)
  );

  const filters = computed({
    get() {
      return isRef(options.filters)
        ? options.filters.value
        : localFilters.value;
    },
    set(value) {
      if (!isRef(value)) {
        localFilters.value = value;
      }
    },
  });

  const { getStreamFilters } = useStreamsStore();

  const streamFilters = getStreamFilters(Number(stream.value.id));

  if (streamFilters) {
    filters.value = streamFilters;
  }

  const selectorActive = ref<Nullable<string>>(null);

  const filtersTotal = computed<number>(() => filters.value.totalFilters());

  const filtering = computed(
    () => Boolean(filtersTotal.value) || filters.value.filteringSortOptions()
  );

  const $isMobile = inject<boolean>("isMobile");

  const filterLabels = computed(() => {
    const labels = [];

    if ($isMobile) {
      if (filters.value.filteringAuthors()) {
        let label = getLocaleText("stream_filtering.author");

        if (stream.value.type === streamTypes.socialStream) {
          label += getLocaleText("stream_filtering.handle");
        }

        labels.push(label);
      }

      if (filters.value.filteringContent()) {
        labels.push(getLocaleText("stream_filtering.content"));
      }

      if (filters.value.filteringReactScore()) {
        labels.push(getLocaleText("stream_filtering.react_score"));
      }

      if (filters.value.filteringLocations()) {
        labels.push(getLocaleText("stream_filtering.locations"));
      }

      if (
        filters.value.filteringMedia(getMediaForStream(stream.value as Stream))
      ) {
        labels.push(getLocaleText("stream_filtering.media"));
      }

      if (filters.value.filteringPlatforms()) {
        labels.push(getLocaleText("stream_filtering.social_platforms"));
      }

      if (filters.value.filteringSources()) {
        labels.push(getLocaleText("stream_filtering.sources"));
      }

      if (filters.value.filteringTags()) {
        labels.push(getLocaleText("stream_filtering.tags"));
      }
    } else {
      if (filters.value.filteringKeywords()) {
        labels.push(getLocaleText("stream_filtering.keywords"));
      }

      if (filters.value.mediaFiltersCount()) {
        labels.push(getLocaleText("stream_filtering.media"));
      }

      if (filters.value.sourcesFiltersCount()) {
        labels.push(getLocaleText("stream_filtering.sources"));
      }

      if (filters.value.contentFiltersCount()) {
        labels.push(getLocaleText("stream_filtering.content"));
      }

      if (filters.value.dateRangeFiltersCount()) {
        labels.push(getLocaleText("stream_filtering.date_range"));
      }

      if (filters.value.metricFiltersCount()) {
        labels.push(getLocaleText("stream_filtering.metrics"));
      }

      if (filters.value.otherFiltersCount()) {
        labels.push(getLocaleText("stream_filtering.other"));
      }

      if (filters.value.filteringSortOptions()) {
        labels.push(getLocaleText("stream_filtering.sort_options"));
      }
    }

    return labels;
  });

  const filteringLabel = computed(() =>
    getLocaleText("stream_filtering.filtering_by", {
      category: toSentence(filterLabels.value),
    })
  );

  const filtersSelectorActive = computed({
    get() {
      return selectorActive.value === "filters";
    },
    set(value) {
      if (!value) selectorActive.value = null;
      selectorActive.value = "filters";
    },
  });

  const filtersSelected = computed<UseStreamFilteringSelected>(() => ({
    keywords: filters.value.keywordsFiltersCount(),
    media: filters.value.mediaFiltersCount(),
    sources: filters.value.sourcesFiltersCount(),
    content: filters.value.contentFiltersCount(),
    metrics: filters.value.metricFiltersCount(),
    other: filters.value.otherFiltersCount(),
    reports: filters.value.reportsFiltersCount(),
    dateRange: filters.value.dateRangeFiltersCount(),
  }));

  const displayFiltersSelector = () => {
    selectorActive.value = "filters";
  };

  const toggleDisplayFiltersSelector = () => {
    selectorActive.value = filtersSelectorActive.value ? null : "filters";
  };

  return {
    filters,
    filtering,
    filterLabels,
    filteringLabel,
    selectorActive,
    filtersSelectorActive,
    filtersSelected,
    filtersTotal,
    displayFiltersSelector,
    toggleDisplayFiltersSelector,
  };
}
