<template>
  <a
    v-if="mention.audience"
    class="flex no-wrap"
    @click.stop.prevent
    @mouseout="menuOver = false"
    @mouseover="menuOver = true"
  >
    <div v-if="listView">
      <div class="tw-flex tw-flex-nowrap">
        <BaseIcon
          class="tw-mr-1"
          icon="followers"
          style="font-size: 14px"
        />

        {{ audienceNumber }}
      </div>
    </div>

    <div
      v-else
      class="tw-flex tw-flex-nowrap"
    >
      <div :class="dense ? 'tw-mr-1 tw-pt-[1px]' : 'tw-ml-1 tw-mr-2 tw-pt-1'">
        <BaseIcon
          class="text-ds-denim-9"
          icon="followers"
          :style="{ 'font-size': dense ? '21px' : '18px' }"
        />
      </div>

      <div :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'">
        {{ $t("broadcast_audience_widget.short_label") }}

        <div
          class="tw-text-left tw-font-bold"
          :class="{ 'tw-text-xxs': dense }"
        >
          {{ audienceNumber }}
        </div>
      </div>
    </div>

    <QMenu
      v-model="visible"
      @show="loadAudiences"
    >
      <div
        class="audience-widget-chart q-pa-sm q-gutter-md"
        @mouseout="contentOver = false"
        @mouseover="contentOver = true"
      >
        <MetricEditorInfoAlert show-audience-multiplier-info />
        <div class="flex-center row">
          <QIcon
            class="q-pr-xs"
            color="ds-denim-9"
            name="people"
            size="18px"
          />
          <span class="bold q-pr-xs">{{ suffix(mention.audience) }}</span>
          {{ $t("broadcast_audience_widget.potential_audience") }}
        </div>
        <QSpinner
          v-if="loading"
          :size="30"
        />
        <ChartLibraryChart
          v-if="loaded && audiences.length"
          v-bind="chartProps"
        />
        <span class="block text-help-text text-center">
          {{
            $t("broadcast_audience_widget.local_time", {
              location: mention.source.location,
              shortTimezone,
            })
          }}
        </span>
        <span v-if="loaded && !audiences.length">{{
          $t("broadcast_audience_widget.no_data")
        }}</span>
      </div>
    </QMenu>
  </a>
</template>

<script>
import chroma from "chroma-js";
import { debounce, sortBy } from "lodash-es";

import { BaseIcon } from "shared/components/base";
import ChartLibraryChart from "shared/components/ChartLibraryChart";
import {
  addTimeZone,
  formatDate,
  parseDate,
  shortTimezone,
  toUTCTimezone,
} from "shared/helpers/date";
import { prettyNumber, suffix } from "shared/helpers/number";

import MetricEditorInfoAlert from "./user/MetricEditorInfoAlert.vue";

const POINT_RADIUS = 6;

export default {
  name: "BroadcastAudienceWidget",
  components: {
    BaseIcon,
    MetricEditorInfoAlert,
    ChartLibraryChart,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    medium: {
      type: String,
      required: true,
      validator(value) {
        return ["tv", "radio"].includes(value);
      },
    },
    listView: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
      menuOver: false,
      contentOver: false,
      loading: false,
      loaded: false,
      audiences: [],
    };
  },
  computed: {
    chartProps() {
      return {
        chartData: { data: this.chartData, options: this.chartOptions },
        chartConfig: {
          component: "ShareOfVoiceTimeline",
          style: { width: "250px", height: "200px" },
          componentAttributes: {},
        },
      };
    },
    color() {
      return {
        tv: "#f95858",
        radio: "#f98d5c",
      }[this.medium];
    },
    lineColor() {
      return chroma(this.color);
    },
    backgroundColor() {
      return this.lineColor.alpha(0.25);
    },
    shortTimezone() {
      return shortTimezone(
        this.mention.start_time,
        this.mention.source.time_zone
      );
    },
    chartData() {
      const datasets = [
        {
          borderColor: this.lineColor.css(),
          borderWidth: 2,
          backgroundColor: this.audiences.map((audienceBlock) =>
            audienceBlock.contains_mention
              ? this.lineColor.css()
              : this.backgroundColor.css()
          ),
          fill: true,
          data: this.audiences.map((audienceBlock) => audienceBlock.audience),
        },
      ];

      const labels = this.audiences.map((audienceBlock) => {
        const date = parseDate(audienceBlock.timestamp);
        const utcDate = toUTCTimezone(date);

        const dateInTimezone = addTimeZone(
          utcDate,
          this.mention.source.time_zone
        );

        return formatDate(dateInTimezone, "h:mmaaa");
      });

      return { labels, datasets };
    },
    chartOptions() {
      return {
        elements: {
          point: {
            radius: this.pointRadius,
            hoverRadius: this.pointRadius,
            display: true,
          },
        },
        scales: {
          x: {
            ticks: {
              callback(value) {
                return this.getLabelForValue(value);
              },
            },
          },
          y: {
            grid: { display: false },
            ticks: {
              callback: suffix,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (tooltipItem) => tooltipItem[0].label,
            },
          },
        },
      };
    },
    audienceNumber() {
      if (this.listView) {
        return prettyNumber(this.mention.audience || "-");
      }

      return suffix(this.mention.audience);
    },
  },
  watch: {
    menuOver() {
      this.debouncedCheckVisible();
    },
    contentOver() {
      this.debouncedCheckVisible();
    },
  },
  mounted() {
    this.debouncedCheckVisible = debounce(this.checkVisible, 300);
  },
  methods: {
    suffix,
    checkVisible() {
      if (this.menuOver || this.contentOver) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
    pointRadius(context) {
      return this.audiences[context.dataIndex].contains_mention
        ? POINT_RADIUS
        : 0;
    },
    async loadAudiences() {
      if (this.audiences.length) return;

      this.loading = true;

      try {
        const response = (
          await this.$streemApiV1.get(
            `${this.mention.type}s/${this.mention.id}/audiences`
          )
        ).data;

        this.audiences = sortBy(response, "timestamp");
      } catch {
        // do nothing for now...
      } finally {
        this.loading = false;
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audience-widget-chart {
  width: 276px;
}
</style>
