<template>
  <div
    class="tab tw-relative"
    :class="tabClass"
  >
    <BaseButton
      v-bind="$attrs"
      class="button tw-relative tw-z-10 tw-w-full"
      :class="{ active: name === tabs?.currentTab.value }"
      :disabled="disabled"
      flat
      :icon="icon"
      :label="label"
      :new-design="newDesign"
      :new-icon="newIcon"
      :padding="padding"
      square
      :to="to"
      @click="handleClick"
    >
      <slot />
    </BaseButton>

    <div
      v-show="!hideIndicator"
      :ref="tab.indicator"
      class="indicator"
      :class="{ active: name === tabs?.currentTab.value }"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: "Tab",
};
</script>

<script setup lang="ts">
import { type Ref, computed, inject, onMounted, onUnmounted, ref } from "vue";
import { type RouteLocationRaw, useRouter } from "vue-router";

import BaseButton from "shared/components/base/BaseButton.vue";
import { TabsSymbol } from "shared/components/base/symbols";
import { safePush } from "shared/helpers/routing";

export interface Props {
  name: string;
  label: string;
  to?: RouteLocationRaw;
  indicatorColor?: string;
  padding?: string;
  hideIndicator?: boolean;
  disabled?: boolean;
  icon?: string;
  newIcon?: boolean;
  newDesign?: boolean;
  type?: "default" | "pill" | "toggle";
}

export interface Tab {
  name: string | number;
  indicator: Ref<HTMLElement | undefined>;
}

const props = withDefaults(defineProps<Props>(), {
  indicatorColor: "primary",
  padding: "md lg",
  icon: "",
  to: "",
  type: "default",
});

const emit = defineEmits<{
  click: [];
}>();

const router = useRouter();
const push = safePush({ router });

const tabs = inject(TabsSymbol);

const tab: Tab = {
  name: props.name,
  indicator: ref(),
};

const tabClass = computed(() => {
  const tabClassList = [props.indicatorColor];

  if (props.newDesign) {
    tabClassList.push(props.type);
  }

  if (props.name === tabs?.currentTab.value) {
    tabClassList.push("active");
  }

  return tabClassList;
});

const handleClick = (event: MouseEvent) => {
  const { name, to } = props;

  if (to) {
    event.preventDefault();
    push(to);
    tabs?.selectTab(name);
  } else {
    tabs?.selectTab(name);
  }

  emit("click");
};

onMounted(() => {
  tabs?.register(tab);
});

onUnmounted(() => {
  tabs?.unregister(tab);
});
</script>

<style lang="scss" scoped>
.tab {
  --tab-indicator-color: transparent;

  .indicator {
    @apply tw-absolute tw-bottom-0 tw-left-0 tw-h-1 tw-w-full tw-origin-top-left tw-transition-colors;

    background: var(--tab-indicator-color);
  }

  &:hover {
    &:not(.active) {
      --tab-indicator-color: var(--s-color-denim-2);
    }
  }

  &.active {
    --tab-indicator-color: var(--s-tab-indicator);

    &.tv {
      --tab-indicator-color: var(--s-color-media-tv);
    }

    &.online {
      --tab-indicator-color: var(--s-color-media-online);
    }

    &.print {
      --tab-indicator-color: var(--s-color-media-print);
    }

    &.blue {
      --tab-indicator-color: var(--s-color-blue-1);
    }
  }

  &.pill {
    .button {
      @apply tw-border-none;

      --c-button-bg-default: transparent;
      --c-button-bg-hover: transparent;
      --c-button-bg-active: transparent;
    }

    .indicator {
      @apply tw-bottom-auto tw-top-0 tw-z-0 tw-h-full tw-rounded-full;
    }

    &.active {
      .button {
        --c-button-color: var(--s-button-color-primary-default);
      }

      &.blue {
        .button {
          --c-button-color: var(--s-color-blue-7);
          --c-button-color-hover: var(--s-color-blue-7);
        }
      }
    }
  }

  &.toggle {
    .button {
      @apply tw-border-none;

      --c-button-bg-default: transparent;
      --c-button-bg-hover: transparent;
      --c-button-bg-active: transparent;
      --c-button-color: var(--s-color-denim-4);
    }

    .indicator {
      @apply tw-bottom-auto tw-top-0 tw-z-0 tw-h-full tw-rounded-full;

      --tab-indicator-color: transparent;
    }

    &.active {
      .button {
        --c-button-color: var(--s-button-color-secondary-default);
      }

      .indicator {
        --tab-indicator-color: white;
      }
    }
  }
}
</style>
