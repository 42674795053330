<template>
  <Transition
    ref="container"
    name="fade"
  >
    <div
      v-if="visible"
      ref="element"
      class="popper-element tw-z-[7500] tw-inline-block tw-w-max tw-border-denim-200"
      :style="floatingStyles"
      v-bind="listeners"
    >
      <slot />
      <slot
        name="arrow"
        :placement="floatingPlacement"
        :position="staticSide"
        :set-arrow-ref="setArrowRef"
        :styles="arrowStyles"
      >
        <div
          v-if="caret"
          :ref="(el) => setArrowRef(el as HTMLElement)"
          class="tw-absolute tw-h-[10px] tw-w-[10px] tw-border tw-border-solid tw-border-[inherit] tw-bg-white tw-shadow-[inherit]"
          :class="caretClasses"
          :style="arrowStyles"
        ></div>
      </slot>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import {
  type OffsetOptions,
  type Placement,
  type ShiftOptions,
  arrow as arrowMiddleware,
  autoUpdate,
  flip as flipMiddleware,
  limitShift,
  offset as offsetMiddleware,
  shift as shiftMiddleware,
  size as sizeMiddleware,
  useFloating,
} from "@floating-ui/vue";
import { type CSSProperties, computed, onMounted, ref, useAttrs } from "vue";

import { useClickOutside } from "shared/composables/useClickOutside";
import { type Nullable } from "shared/types";

export interface PopperProps {
  boundary?: Nullable<HTMLElement>;
  caret?: boolean;
  clickOutsideCapture?: boolean;
  clickOutsideMiddleware?: (event: Event) => boolean;
  disableClickOutside?: boolean;
  fallbackPlacements?: Placement[];
  flip?: boolean;
  offset?: number[] | OffsetOptions;
  placement?: Placement;
  rootElement?: Nullable<HTMLElement>;
  sameWidth?: boolean;
  tether?: boolean;
  tetherOffset?: number;
  width?: Nullable<number>;
}

const visible = defineModel({
  type: Boolean,
});

const props = withDefaults(defineProps<PopperProps>(), {
  boundary: null,
  // eslint-disable-next-line vue/no-boolean-default
  clickOutsideCapture: true, // to avoid the need for `.stop` on sibling elements near a Popper component, and to match default behaviour of onClickOutside.
  fallbackPlacements: () => ["top"],
  clickOutsideMiddleware: () => true,
  offset: undefined,
  placement: "bottom",
  rootElement: null,
  tetherOffset: 0,
  width: null,
});

const attrs = useAttrs();
const root = ref<Nullable<HTMLElement>>(null);
const element = ref<Nullable<HTMLDivElement>>(null);
const container = ref<Nullable<HTMLDivElement>>(null);

const floatingArrow = ref<Nullable<HTMLElement>>(null);

function setArrowRef(el: HTMLElement) {
  floatingArrow.value = el;
}

const floatingArrowWidth = computed(
  () => floatingArrow.value?.offsetWidth || 0
);

const listeners = computed<Record<string, any>>(() =>
  // need to remove input listener that gets passed down when Popper is used with v-model
  Object.entries(attrs).reduce((acc: Record<string, any>, [key, listener]) => {
    if (key !== "update:modelValue") acc[key] = listener;

    return acc;
  }, {})
);

function beforeHandler(event: Event): boolean {
  const middlewareResult = props.clickOutsideMiddleware?.(event) ?? true;

  // prevents edge cases on mousemoves within the element
  const isNotDocumentElement = event.target !== document.documentElement;

  return middlewareResult && isNotDocumentElement;
}

function toggleVisibility(): void {
  visible.value = !visible.value;
}

useClickOutside(element, toggleVisibility, {
  middleware: beforeHandler,
  active: computed(() => !props.disableClickOutside && Boolean(visible.value)),
  capture: props.clickOutsideCapture,
});

function findParentEl(): Nullable<HTMLElement> {
  const excludedClasses = ["popper-element", "vue-portal-target"];

  let parent = container.value?.parentElement || null;

  while (
    parent?.parentElement &&
    Array.from(parent.classList || []).some((klass) =>
      excludedClasses.includes(klass)
    )
  ) {
    parent = parent?.parentElement;
  }

  return parent;
}

const offsetConfiguredMiddleware = computed<
  ReturnType<typeof offsetMiddleware>
>(() => {
  if (!Array.isArray(props.offset)) {
    return offsetMiddleware(props.offset);
  }

  const options = {
    mainAxis: 0,
    crossAxis: 0,
  };

  if (props.caret) {
    options.mainAxis = Math.sqrt(2 * floatingArrowWidth.value ** 2) / 2;
  }

  if (props.offset.length) {
    const [mainAxis, crossAxis] = props.offset;

    options.mainAxis = mainAxis || 0;
    options.crossAxis = crossAxis || 0;
  }

  return offsetMiddleware(options);
});

const flipConfiguredMiddleware = computed<ReturnType<typeof flipMiddleware>>(
  () => {
    const options = props.fallbackPlacements.length
      ? { fallbackPlacements: props.fallbackPlacements }
      : {};

    return flipMiddleware(options);
  }
);

const shiftConfiguredMiddleware = computed<ReturnType<typeof shiftMiddleware>>(
  () => {
    const options: ShiftOptions = {
      boundary: props.boundary || "clippingAncestors",
      rootBoundary: "document",
      limiter: limitShift({
        mainAxis: props.tether,
        offset: props.tetherOffset,
      }),
    };

    return shiftMiddleware(options);
  }
);

const sizeConfiguredMiddleware = computed<ReturnType<typeof sizeMiddleware>>(
  () =>
    sizeMiddleware({
      apply({ rects, elements }) {
        Object.assign(elements.floating.style, {
          ...(props.sameWidth ? { width: `${rects.reference.width}px` } : {}),
          ...(!props.sameWidth && props.width
            ? { width: `${props.width}px` }
            : {}),
        });
      },
    })
);

const arrowConfiguredMiddleware = computed<ReturnType<typeof arrowMiddleware>>(
  () => arrowMiddleware({ element: floatingArrow })
);

const target = computed(() => props.rootElement || root.value);

const {
  floatingStyles,
  middlewareData,
  placement: floatingPlacement,
} = useFloating(target, element, {
  placement: props.placement,
  strategy: "fixed",
  whileElementsMounted: autoUpdate,
  middleware: [
    offsetConfiguredMiddleware.value,
    ...(props.flip ? [flipConfiguredMiddleware.value] : []),
    sizeConfiguredMiddleware.value,
    shiftConfiguredMiddleware.value,
    arrowConfiguredMiddleware.value,
  ],
});

const staticSide = computed<string | undefined>(() => {
  const side = floatingPlacement.value.split("-")[0];

  return {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[side];
});

const arrowStyles = computed<CSSProperties>(() => {
  if (!middlewareData.value.arrow) {
    return {};
  }

  const side = staticSide.value;

  const { x, y } = middlewareData.value.arrow;

  return {
    left: x !== null && x !== undefined ? `${x}px` : "",
    top: y !== null && y !== undefined ? `${y}px` : "",
    right: "",
    bottom: "",
    [side!]: `${-floatingArrowWidth.value / 2}px`,
  };
});

const caretClasses = computed<string>(() => {
  if (staticSide.value === "bottom") {
    return "tw-translate-y-[1px] tw-rotate-45 tw-rounded-[0_0_3px_0] tw-border-l-0 tw-border-t-0";
  }

  if (staticSide.value === "top") {
    return "tw-translate-y-[-1px] tw-rotate-45 tw-rounded-[3px_0_0_0] tw-border-b-0 tw-border-r-0";
  }

  return "";
});

onMounted(() => {
  root.value = findParentEl();
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.popper-element {
  *.bordered {
    border: 1px solid var(--s-color-denim-2);
  }
}
</style>
