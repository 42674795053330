import { type Slot, type VNode, Comment, Fragment, Text } from "vue";

// https://github.com/vuejs/vue-next/blob/ca17162e377e0a0bf3fae9d92d0fdcb32084a9fe/packages/runtime-core/src/helpers/renderSlot.ts#L77
const isVnodeEmpty = (vnodes: Array<VNode>) =>
  vnodes.every((node: VNode) => {
    if (node.type === Comment) {
      return true;
    }

    if (
      node.type === Text &&
      typeof node.children === "string" &&
      !node.children.trim()
    ) {
      return true;
    }

    if (node.type === Fragment && isVnodeEmpty(node.children as Array<VNode>)) {
      return true;
    }

    return false;
  });

// eslint-disable-next-line import/prefer-default-export
export const hasSlotContent = (slot: Slot<any> | undefined) => {
  if (!slot) {
    return false;
  }

  return !slot ? false : !isVnodeEmpty(slot());
};
