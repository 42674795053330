<template>
  <BaseButton
    class="!tw-px-0"
    :class="{
      '!tw-text-xs': fontSize === 'xs',
      '!tw-text-md': fontSize === 'md',
    }"
    dense
    icon="full-article"
    icon-size="md"
    :label="getLocaleText('broadcast_mention_card.view_broadcast')"
    new-design
    new-icon
    @click="viewFullBroadcast"
  />
</template>

<script setup lang="ts">
import { getLocaleText } from "shared/boot/i18n";
import { BaseButton } from "shared/components/base";
import ModalService from "shared/modals/ModalService";
import type { RadioClip, TvCaption } from "shared/types/mentions";

const props = defineProps<{
  mention: TvCaption | RadioClip;
  fontSize: "xs" | "md";
}>();

function viewFullBroadcast() {
  ModalService.open("TveyesPlayerModal", {
    props: {
      mention: props.mention,
      forceFullBroadcast: true,
    },
  });
}
</script>
