<template>
  <BaseButtonDropdown
    v-model="visible"
    class="sentiment-widget"
    new-dropdown
    placement="bottom-start"
  >
    <template #btn="{ toggle }">
      <div
        class="tw-flex tw-flex-nowrap"
        :class="{ soft: dense }"
        @click.stop="toggleDropdown(toggle)"
      >
        <div :class="dense ? 'tw-mr-0.5 tw-pt-0.5' : 'tw-ml-1 tw-mr-2 tw-pt-1'">
          <BaseIcon
            :icon="sentimentOption.icon"
            :style="{
              color: sentimentOption.style['--icon-color'],
              'font-size': dense ? '20px' : '18px',
            }"
          />
        </div>

        <div
          class="tw-text-left"
          :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'"
        >
          {{ $t("sentiment_widget.heading") }}

          <div
            class="tw-text-left tw-font-bold"
            :class="{ 'tw-text-xxs': dense }"
          >
            {{ sentimentOption.abbreviatedLabel }}
          </div>
        </div>

        <StreemTooltip v-if="sentimentRatingTooltip">
          {{ sentimentRatingTooltip }}
        </StreemTooltip>
      </div>
    </template>

    <div
      v-if="allowSentimentRating"
      class="sentiment-widget__menu"
      @click.stop
    >
      <div class="sentiment-widget__heading">
        {{ $t("sentiment_widget.heading") }}
      </div>

      <div
        v-for="option in sentimentRatingOptions"
        :key="option.value"
        class="sentiment-widget__option"
      >
        <Radio
          :model-value="sentimentOption.field"
          new-design
          :value="option.field"
          @update:model-value="updateSentiment(option)"
        >
          <span class="q-ml-sm">{{ option.label }}</span>
          <span
            v-if="
              mentionSentimentOption &&
              mentionSentimentOption.field === option.field
            "
            class="q-ml-sm soft"
          >
            {{ $t("sentiment_widget.automated") }}
          </span>
        </Radio>
      </div>
      <div
        v-if="sentimentRatingTooltip"
        class="sentiment-widget__update"
      >
        {{ sentimentRatingTooltip }}
      </div>
    </div>
  </BaseButtonDropdown>
</template>

<script setup>
import { ref } from "vue";

import { BaseButtonDropdown } from "shared/components/base";
import BaseIcon from "shared/components/base/BaseIcon.vue";
import StreemTooltip from "shared/components/base/StreemTooltip.vue";
import Radio from "shared/components/core/forms/Radio.vue";
import useMentionSentiment from "shared/composables/useMentionSentiment";

const props = defineProps({
  mention: {
    type: Object,
    required: true,
  },
  stream: {
    type: Object,
    required: true,
  },
  dense: {
    type: Boolean,
  },
});

const {
  sentimentRatingOptions,
  sentimentOption,
  mentionSentimentOption,
  sentimentRatingTooltip,
  saveSentimentRating,
  allowSentimentRating,
} = useMentionSentiment(props);

const visible = ref(false);

function toggleDropdown(toggle) {
  if (allowSentimentRating.value) toggle();
}

function updateSentiment(option) {
  saveSentimentRating(option.value);
  visible.value = false;
}
</script>

<style lang="scss" scoped>
.sentiment-widget {
  color: var(--s-color-font-default);

  &__heading {
    font-weight: var(--s-font-weight-bold);
  }

  &__menu {
    padding-block: var(--s-spacing-md);
    padding-inline: var(--s-spacing-lg);
  }

  &__update {
    padding-top: var(--s-spacing-md);
    font-size: var(--s-font-size-sm);
    color: var(--s-color-font-subtext);
  }
}
</style>
