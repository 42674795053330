<template>
  <div
    class="tag tw-inline-flex tw-w-fit tw-max-w-full tw-items-center tw-gap-2 tw-whitespace-nowrap"
    :class="[
      `tag--${props.color}`,
      {
        'tw-h-[24px] tw-px-2.5 tw-text-sm': size === 'md',
        'tw-h-[32px] tw-px-4': size === 'lg',
        'tw-cursor-pointer': !hideCursor,
        'tw-rounded-full': rounded,
        'tw-rounded': !rounded,
      },
    ]"
    :style="tagStyles"
    @click="$emit('click', $event)"
  >
    <BaseIcon
      v-if="icon"
      :icon="icon"
    />

    <slot>
      <EllipseContent class="tag-label">
        {{ label }}
      </EllipseContent>
    </slot>

    <slot name="close-button">
      <span
        v-if="!hideRemoveButton"
        class="tag-icon tw-pointer-events-auto tw-flex tw-h-[20px] tw-w-[20px] tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center"
        :class="{
          '-tw-mr-1.5': size === 'md',
          '-tw-mr-2.5': size === 'lg',
          'tw-rounded-full': rounded,
          'tw-rounded': !rounded,
        }"
      >
        <BaseIcon
          icon="close"
          @click.stop.prevent="$emit('remove')"
        />
      </span>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import BaseIcon from "shared/components/base/BaseIcon.vue";
import EllipseContent from "shared/components/base/EllipseContent.vue";
import { defaultTagColor, tagPalette } from "shared/constants";
import { MediumField } from "shared/helpers/media";

enum Colors {
  NONE = "none",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SELECTED = "selected",
}

interface ColorPallete {
  fontColor: string;
}

interface ColorPalletes {
  [key: string]: ColorPallete;
}

export interface Props {
  label?: string | number;
  color?: Colors | MediumField | string;
  palette?: ColorPalletes;
  hideRemoveButton?: boolean;
  hideCursor?: boolean;
  size?: "md" | "lg" | "xl";
  rounded?: boolean;
  icon?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  color: defaultTagColor,
  palette: () => tagPalette,
  icon: "",
  size: "md",
});

defineEmits(["click", "remove"]);

const colorPalette = computed<ColorPalletes>(() => props.palette);

const tagColor = computed(() => {
  if (Object.values(Colors).includes(props.color as Colors)) {
    return props.color;
  }

  if (Object.values(MediumField).includes(props.color as MediumField)) {
    return props.color;
  }

  return null;
});

const tagStyles = computed(() => {
  if (tagColor.value) {
    return {};
  }

  return {
    "background-color": props.color || defaultTagColor,
    color: colorPalette.value[props.color]?.fontColor || "white",
  };
});
</script>

<style lang="scss">
.tag {
  --tag-bg-color: none;
  --tag-hover-bg-color: var(--s-color-denim-1);
  --tag-text-color: var(--s-button-color-secondary-default);
  --tag-button-hover-bg-color: none;

  color: var(--tag-text-color);
  background-color: var(--tag-bg-color);

  &.tag--secondary {
    --tag-bg-color: var(--s-button-bg-secondary-default);
    --tag-hover-bg-color: var(--s-button-bg-secondary-hover);
    --tag-text-color: var(--s-button-color-secondary-default);
    --tag-button-hover-bg-color: var(--s-button-bg-secondary-active);
  }

  &.tag--primary {
    --tag-bg-color: var(--s-button-bg-primary-default);
    --tag-hover-bg-color: var(--s-button-bg-primary-hover);
    --tag-button-hover-bg-color: var(--s-button-bg-primary-active);
    --tag-text-color: var(--s-button-color-primary-default);
  }

  &.tag--danger {
    --tag-bg-color: var(--s-button-bg-danger-default);
    --tag-hover-bg-color: var(--s-button-bg-danger-hover);
    --tag-button-hover-bg-color: var(--s-button-bg-danger-active);
    --tag-text-color: var(--s-button-color-primary-default);
  }

  &.tag--selected {
    --tag-bg-color: var(--s-color-blue-1);
    --tag-hover-bg-color: var(--s-color-blue-2);
    --tag-text-color: var(--s-color-blue-7);
    --tag-button-hover-bg-color: var(--s-color-blue-3);
  }

  @each $medium in $media {
    &.tag--#{$medium} {
      --tag-bg-color: var(--s-button-bg-#{$medium}-default);
      --tag-hover-bg-color: var(--s-button-bg-#{$medium}-hover);
      --tag-button-hover-bg-color: var(--s-button-bg-#{$medium}-active);
      --tag-text-color: var(--s-button-color-#{$medium}-default);
    }
  }

  .tag-icon {
    &:hover {
      background-color: var(--tag-button-hover-bg-color);
    }
  }

  &:hover {
    --tag-bg-color: var(--tag-hover-bg-color);
  }
}
</style>
