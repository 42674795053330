import { cloneDeep, snakeCase } from "lodash-es";

import { streamTypes } from "shared/constants";
import DateRange from "shared/helpers/DateRange";
import {
  type Medium,
  type MediumName,
  type SocialPlatform,
  getSources,
  MediumField,
  social,
} from "shared/helpers/media";
import {
  type Source,
  sourceGroupKeys,
  sourceKeysFor,
} from "shared/helpers/sourceFilters";
import type { SimpleBoolean } from "shared/resources/Stream";
import features from "shared/services/features";
import type { Nullable, Stream } from "shared/types";
import type { MentionType, SocialMentionType } from "shared/types/mentions";

import {
  SortOptionField,
  SortOptionMissing,
  SortOptionOrder,
} from "./mentions";

export type StreamFiltersTag = {
  color: Nullable<string>;
  focus?: boolean;
  id: number;
  label: string;
  selected?: boolean;
};

export interface StreamFiltersSortOption {
  sortBy: SortOptionField;
  orderBy: SortOptionOrder;
  missing: SortOptionMissing;
}

export type StreamFiltersAuthor = {
  focus?: boolean;
  id: number;
  name: string;
};

export type StreamFiltersCategory = "National" | "Metro" | "Regional";

export type StreamFiltersLicense = "nla" | "cla";

export type StreamFiltersMinMax = {
  max: Nullable<number>;
  min: Nullable<number>;
};

type StreamFiltersFrontPageRanks = {
  min: number;
};

export type StreamFiltersTier = {
  label: string;
  value: number;
};

type StreamTierFilters = {
  [key in `${MediumField}_tiers`]?: number[];
};

export interface StreamRequestFiltersSortOption {
  sort_by: SortOptionField;
  sort_order: SortOptionOrder;
  missing: SortOptionMissing;
}

interface StreamBaseFilters extends StreamTierFilters {
  authors?: string;
  boolean_query?: string;
  category_keys?: string[];
  domain_authority?: StreamFiltersMinMax[];
  excluded_authors?: string;
  excluded_keywords?: string;
  excluded_language_codes?: string[];
  excluded_license?: StreamFiltersLicense[];
  impact_score?: StreamFiltersMinMax[];
  include_external_items?: boolean;
  include_international_plus?: boolean;
  include_online_cald_sources?: string;
  include_print_cald_sources?: string;
  keywords?: string;
  language_codes?: string[];
  license?: StreamFiltersLicense[];
  location_keys?: string[];
  max_advertisement_rate?: number;
  max_aggregate_emotionality?: number;
  max_aggregate_fakeness?: number;
  max_aggregate_harmful?: number;
  max_aggregate_spam?: number;
  max_audience?: number;
  max_desktop_monthly_visits?: number;
  max_mobile_monthly_visits?: number;
  max_react_score?: number;
  max_social_impact_threshold?: number;
  max_word_count?: number;
  media?: MediumName[];
  min_advertisement_rate?: number;
  min_aggregate_emotionality?: number;
  min_aggregate_fakeness?: number;
  min_aggregate_harmful?: number;
  min_aggregate_spam?: number;
  min_audience?: number;
  min_desktop_monthly_visits?: number;
  min_mobile_monthly_visits?: number;
  min_react_score?: number;
  min_social_impact_threshold?: number;
  min_word_count?: number;
  only_external_items?: boolean;
  page_numbers?: StreamFiltersMinMax;
  selected_social_content?: SocialMentionType[];
  selected_tv_content?: MentionType[];
  sentiment?: StreamFiltersMinMax[];
  social_only_verified?: boolean;
  unrated_sentiment?: boolean;
}

export interface StreamRequestFilters extends StreamBaseFilters {
  bundle_broadcast?: boolean;
  categories?: StreamFiltersCategory[];
  collapse_syndicated?: boolean;
  excluded_source_group_keys?: string[];
  excluded_source_keys?: string[];
  excluded_tag_ids?: number[];
  front_page_ranks?: StreamFiltersFrontPageRanks;
  include_bookmark_syndication?: boolean;
  lastSavedEnabled?: boolean;
  location_ids?: number[];
  max_domain_authority?: number;
  min_domain_authority?: number;
  minimum_follower_count?: number;
  online_tiers?: number[];
  programs?: string[];
  range?: DateRange;
  social_impact_threshold?: number;
  sort_options?: StreamRequestFiltersSortOption[];
  source_group_keys?: string[];
  source_keys?: string[];
  stock_symbol?: string;
  tag_ids?: number[];
  types?: string[];
  use_exact_authors?: boolean;
  verified?: boolean;
}

export type InitialStreamFilters = Partial<Omit<StreamFilters, "stream">>;

export enum StreamFiltersType {
  Base = "base",
  Filters = "filters",
  Report = "report",
  Stream = "stream",
}

type StreamFiltersTypes<T> = T extends StreamFiltersType.Filters
  ? StreamRequestFilters
  : T extends StreamFiltersType.Report
    ? StreamReportFilters
    : T extends StreamFiltersType.Stream
      ? StreamOptionsFilters
      : never;

type StreamOptionTypes<T> = T extends StreamFiltersType.Report
  ? StreamReportFilters
  : T extends StreamFiltersType.Stream
    ? StreamOptionsFilters
    : never;

export interface StreamOptionsFilters extends StreamBaseFilters {
  authors_filter?: StreamFiltersAuthor[];
  bookmark_stream_ids?: number[];
  boolean_query?: StreamFilters["booleanQuery"];
  excluded_authors_filter?: StreamFiltersAuthor[];
  excluded_bookmark_stream_ids?: number[];
  simple_boolean?: StreamFilters["simpleBoolean"];
}

export interface StreamReportFilters extends StreamOptionsFilters {
  excluded_source_group_keys?: string[];
  excluded_source_keys?: string[];
  source_group_keys?: string[];
  source_keys?: string[];
}

function isStreamFilters(
  type: StreamFiltersType,
  params: unknown
): params is StreamOptionsFilters {
  return StreamFiltersType.Stream === type;
}

function isStreamReportFilters(
  type: StreamFiltersType,
  params: unknown
): params is StreamReportFilters {
  return StreamFiltersType.Report === type;
}

function isStreamRequestFilters(
  type: StreamFiltersType,
  params: unknown
): params is StreamRequestFilters {
  return StreamFiltersType.Filters === type;
}

export default class StreamFilters {
  advertisementRate: StreamFiltersMinMax;

  aggregateEmotionality: StreamFiltersMinMax;

  aggregateFakeness: StreamFiltersMinMax;

  aggregateHarmful: StreamFiltersMinMax;

  aggregateSpam: StreamFiltersMinMax;

  audience: StreamFiltersMinMax;

  authors: StreamFiltersAuthor[];

  booleanQuery: string;

  bundleBroadcast: boolean;

  categories: StreamFiltersCategory[];

  categoryKeys: string[];

  collapseSyndicated: boolean;

  dateRangeEnabled: boolean;

  desktopMonthlyVisits: StreamFiltersMinMax;

  domainAuthority: StreamFiltersMinMax;

  domainAuthorityRanges: StreamFiltersMinMax[];

  excludedAuthors: StreamFiltersAuthor[];

  excludedKeywords: string;

  excludedLanguageCodes: string[];

  excludedLicense: StreamFiltersLicense[];

  excludedSources: Source[];

  excludedTags: StreamFiltersTag[];

  externalItemsEnabled: boolean;

  frontPageRanks: StreamFiltersFrontPageRanks;

  frontPageRanksEnabled: boolean;

  impactScoreRanges: StreamFiltersMinMax[];

  includeBookmarkSyndication: boolean;

  includeInternationalPlus: Nullable<boolean>;

  includeOnlineCaldSources: string;

  includePrintCaldSources: string;

  includedMentionsEnabled: boolean;

  initialFilters: InitialStreamFilters;

  keywords: string;

  languageCodes: string[];

  lastSavedEnabled: boolean;

  license: StreamFiltersLicense[];

  locations: number[];

  locationKeys: string[];

  media: MediumName[];

  mediaTypes: MentionType[];

  minimumFollowerCount: number;

  minimumFollowerCountEnabled: boolean;

  mobileMonthlyVisits: StreamFiltersMinMax;

  onlyShowExternalItems: boolean;

  pageNumbers: StreamFiltersMinMax;

  pageNumbersEnabled: boolean;

  platforms: SocialPlatform[];

  programs: string[];

  range: DateRange;

  reactScore: StreamFiltersMinMax;

  reactScoreAverageSelected: boolean;

  reactScoreBreakdown: boolean;

  selectedTvContent: MentionType[];

  selectedSocialContent: SocialMentionType[];

  sentiment: StreamFiltersMinMax[];

  sentimentEnabled: boolean;

  simpleBoolean: Nullable<SimpleBoolean>;

  socialImpactThreshold: StreamFiltersMinMax;

  socialOnlyVerified: Nullable<boolean>;

  sources: Source[];

  sortOptions: StreamFiltersSortOption[];

  stockSymbol?: string;

  stream: Nullable<Stream>;

  tags: StreamFiltersTag[];

  tiers: StreamFiltersTier[];

  unratedSentiment: boolean;

  useExactAuthors: boolean;

  verifiedEnabled: boolean;

  wordCount: StreamFiltersMinMax;

  wordCountEnabled: boolean;

  static get DEFAULT_FILTERS(): InitialStreamFilters {
    const streamFilters = new StreamFilters(null);

    return streamFilters.initialFilters;
  }

  static fromOptions(
    streamOptions: StreamReportFilters,
    stream: Nullable<Stream>,
    initialFilters: InitialStreamFilters = {}
  ): StreamFilters {
    const {
      authors_filter: authors = [],
      boolean_query: booleanQuery = "",
      category_keys: categoryKeys = [],
      domain_authority: domainAuthorityRanges = [],
      excluded_authors_filter: excludedAuthors = [],
      excluded_keywords: excludedKeywords = "",
      excluded_language_codes: excludedLanguageCodes = [],
      excluded_license: excludedLicense = [],
      impact_score: impactScoreRanges = [],
      include_external_items: externalItemsEnabled,
      include_international_plus: includeInternationalPlus = null,
      include_online_cald_sources: includeOnlineCaldSources = "",
      include_print_cald_sources: includePrintCaldSources = "",
      keywords = "",
      language_codes: languageCodes = [],
      license = [],
      location_keys: locationKeys = [],
      max_advertisement_rate: maxAdvertisementRate = null,
      max_aggregate_emotionality: maxAggregateEmotinality,
      max_aggregate_fakeness: maxAggregateFakeness,
      max_aggregate_harmful: maxAggregateHarmful,
      max_aggregate_spam: maxAggregateSpam,
      max_audience: maxAudience = null,
      max_desktop_monthly_visits: maxDesktopMonthlyVisits = null,
      max_mobile_monthly_visits: maxMobileMonthlyVisits = null,
      max_react_score: maxReactScore = 1,
      max_social_impact_threshold: maxSocialImpactThreshold = null,
      max_word_count: maxWordCount = null,
      media = [],
      min_advertisement_rate: minAdvertisementRate = null,
      min_aggregate_emotionality: minAggregateEmotionality,
      min_aggregate_fakeness: minAggregateFakeness,
      min_aggregate_harmful: minAggregateHarmful,
      min_aggregate_spam: minAggregateSpam,
      min_audience: minAudience = null,
      min_desktop_monthly_visits: minDesktopMonthlyVisits = null,
      min_mobile_monthly_visits: minMobileMonthtlyVisits = null,
      min_react_score: minReactScore = 0,
      min_social_impact_threshold: minSocialImpactThreshold = null,
      min_word_count: minWordCount = null,
      only_external_items: onlyShowExternalItems = false,
      page_numbers: pageNumbers = { min: null, max: null },
      selected_social_content: selectedSocialContent = [],
      selected_tv_content: selectedTvContent = [],
      sentiment = [],
      simple_boolean: simpleBoolean = null,
      social_only_verified: socialOnlyVerified = null,
      unrated_sentiment: unratedSentiment = false,
    } = streamOptions;

    const pageNumbersEnabled = Boolean(
      pageNumbers !== undefined &&
        (pageNumbers.min !== null || pageNumbers.max !== null)
    );

    const wordCountEnabled = Boolean(
      minWordCount !== null || maxWordCount !== null
    );

    const reactScoreAverageSelected = Boolean(
      maxAggregateEmotinality === undefined &&
        maxAggregateFakeness === undefined &&
        maxAggregateHarmful === undefined &&
        maxAggregateSpam === undefined
    );

    return new StreamFilters(stream, {
      ...initialFilters,
      advertisementRate: {
        min: minAdvertisementRate !== null ? minAdvertisementRate / 100 : null,
        max: maxAdvertisementRate !== null ? maxAdvertisementRate / 100 : null,
      },
      aggregateEmotionality: {
        min: minAggregateEmotionality ? minAggregateEmotionality * 100 : 0,
        max: maxAggregateEmotinality ? maxAggregateEmotinality * 100 : 100,
      },
      aggregateFakeness: {
        min: minAggregateFakeness ? minAggregateFakeness * 100 : 0,
        max: maxAggregateFakeness ? maxAggregateFakeness * 100 : 100,
      },
      aggregateHarmful: {
        min: minAggregateHarmful ? minAggregateHarmful * 100 : 0,
        max: maxAggregateHarmful ? maxAggregateHarmful * 100 : 100,
      },
      aggregateSpam: {
        min: minAggregateSpam ? minAggregateSpam * 100 : 0,
        max: maxAggregateSpam ? maxAggregateSpam * 100 : 100,
      },
      audience: { min: minAudience, max: maxAudience },
      authors,
      booleanQuery,
      categoryKeys,
      desktopMonthlyVisits: {
        min: minDesktopMonthlyVisits,
        max: maxDesktopMonthlyVisits,
      },
      domainAuthorityRanges,
      excludedAuthors,
      excludedKeywords,
      excludedLanguageCodes,
      excludedLicense,
      ...(externalItemsEnabled !== undefined ? { externalItemsEnabled } : {}),
      impactScoreRanges,
      includeInternationalPlus,
      includeOnlineCaldSources,
      includePrintCaldSources,
      keywords,
      languageCodes,
      license,
      locationKeys,
      media,
      mobileMonthlyVisits: {
        min: minMobileMonthtlyVisits,
        max: maxMobileMonthlyVisits,
      },
      onlyShowExternalItems,
      pageNumbers,
      pageNumbersEnabled,
      reactScoreAverageSelected,
      reactScore: {
        min: minReactScore ? minReactScore * 100 : 0,
        max: maxReactScore ? maxReactScore * 100 : 100,
      },
      selectedSocialContent,
      selectedTvContent,
      sentiment,
      sentimentEnabled: Boolean(sentiment.length),
      simpleBoolean,
      socialImpactThreshold: {
        min: minSocialImpactThreshold,
        max: maxSocialImpactThreshold,
      },
      socialOnlyVerified,
      unratedSentiment,
      wordCount: { min: minWordCount, max: maxWordCount },
      wordCountEnabled,
    });
  }

  constructor(
    stream: Nullable<Stream>,
    initialFilters: InitialStreamFilters = {}
  ) {
    this.advertisementRate = { min: null, max: null };
    this.aggregateEmotionality = { min: 0, max: 100 };
    this.aggregateFakeness = { min: 0, max: 100 };
    this.aggregateHarmful = { min: 0, max: 100 };
    this.aggregateSpam = { min: 0, max: 100 };
    this.audience = { min: null, max: null };
    this.authors = [];
    this.booleanQuery = "";
    this.bundleBroadcast = false;
    this.categories = [];
    this.categoryKeys = [];
    this.collapseSyndicated = false;
    this.dateRangeEnabled = false;
    this.desktopMonthlyVisits = { min: null, max: null };
    this.domainAuthority = { min: null, max: null };
    this.domainAuthorityRanges = [];
    this.excludedAuthors = [];
    this.excludedLicense = [];
    this.excludedKeywords = "";
    this.excludedLanguageCodes = [];
    this.excludedSources = [];
    this.excludedTags = [];
    this.frontPageRanks = { min: 0 };
    this.frontPageRanksEnabled = false;
    this.impactScoreRanges = [];
    this.includeBookmarkSyndication = false;
    this.includedMentionsEnabled = false;
    this.includeInternationalPlus = null;
    this.includeOnlineCaldSources = "";
    this.includePrintCaldSources = "";
    this.keywords = "";
    this.languageCodes = [];
    this.lastSavedEnabled = false;
    this.license = [];
    this.locationKeys = [];
    this.locations = [];
    this.media = [];
    this.mediaTypes = [];
    this.minimumFollowerCount = 500;
    this.minimumFollowerCountEnabled = false;
    this.mobileMonthlyVisits = { min: null, max: null };
    this.onlyShowExternalItems = false;
    this.pageNumbers = { min: null, max: null };
    this.pageNumbersEnabled = false;
    this.platforms = [];
    this.programs = [];
    this.range = DateRange.today();
    this.reactScore = { min: 0, max: 100 };
    this.reactScoreAverageSelected = true;
    this.reactScoreBreakdown = false;
    this.selectedSocialContent = [];
    this.selectedTvContent = [];
    this.sentiment = [];
    this.sentimentEnabled = false;
    this.simpleBoolean = null;
    this.socialImpactThreshold = { min: null, max: null };
    this.socialOnlyVerified = null;

    this.sortOptions = [
      {
        sortBy: SortOptionField.TIMESTAMP,
        orderBy: SortOptionOrder.DESC,
        missing: SortOptionMissing.LAST,
      },
    ];

    this.sources = [];
    this.stream = stream;
    this.tags = [];
    this.tiers = [];
    this.unratedSentiment = false;
    this.useExactAuthors = false;
    this.verifiedEnabled = false;
    this.wordCount = { min: null, max: null };
    this.wordCountEnabled = false;

    if (
      this.canFilterExternalItems() &&
      !("externalItemsEnabled" in initialFilters)
    ) {
      this.externalItemsEnabled = true;
    }

    Object.assign(this, initialFilters);

    const streamFiltersClone = cloneDeep(this);

    const {
      stream: streamClone,
      initialFilters: initialFiltersClone,
      ...filters
    } = streamFiltersClone;

    this.initialFilters = cloneDeep(filters);
  }

  clone(): StreamFilters {
    return Object.assign(new StreamFilters(this.stream, cloneDeep(this)), {
      initialFilters: cloneDeep(this.initialFilters),
    });
  }

  toOptions<T extends StreamFiltersType>(type?: T): StreamOptionTypes<T> {
    const options = <StreamOptionTypes<T>>{};

    if (this.authors.length) {
      options.authors_filter = this.authors;
    }

    if (this.excludedAuthors.length) {
      options.excluded_authors_filter = this.excludedAuthors;
    }

    if (this.simpleBoolean) {
      options.simple_boolean = this.simpleBoolean;
    }

    if (type === StreamFiltersType.Report) {
      const filters = this.requestFilters<StreamFiltersType.Report>(type);

      return {
        ...options,
        ...filters,
      };
    }

    const filters = this.requestFilters<StreamFiltersType.Stream>(
      StreamFiltersType.Stream
    );

    return {
      ...options,
      ...filters,
    };
  }

  supportsExternalItems(stream: Nullable<Stream>): boolean {
    if (stream === null || stream === undefined) return false;

    return [
      streamTypes.mentionStream,
      streamTypes.bookmarkStream,
      streamTypes.organisationBrief,
    ].includes(stream.type as number);
  }

  canFilterExternalItems(): boolean {
    if (this.stream === null) {
      return false;
    }

    return (
      this.supportsExternalItems(this.stream) &&
      features.has("has_external_items")
    );
  }

  filteringContent(
    { ignoredFilters }: { ignoredFilters: string[] } = { ignoredFilters: [] }
  ): boolean {
    const contentFilters = {
      keywords: this.keywords && this.keywords !== "",
      excludedKeywords: this.excludedKeywords && this.excludedKeywords !== "",
      pageNumber:
        this.pageNumbersEnabled !== this.initialFilters.pageNumbersEnabled,
      wordCount: this.wordCountEnabled !== this.initialFilters.wordCountEnabled,
      dateRangeEnabled:
        this.dateRangeEnabled !== this.initialFilters.dateRangeEnabled,
      dateRange:
        this.initialFilters.range &&
        !this.range.equals(this.initialFilters.range),
      sentiment:
        this.sentimentEnabled !== this.initialFilters?.sentimentEnabled,
      lastSaved: this.lastSavedEnabled,
    };

    return Object.entries(contentFilters).some(
      ([filter, active]) => !ignoredFilters.includes(filter) && active
    );
  }

  rangeEquals(
    selected: StreamFiltersMinMax,
    initial: StreamFiltersMinMax
  ): boolean {
    return selected.min === initial.min && selected.max === initial.max;
  }

  scoreChanged(score: StreamFiltersMinMax): boolean {
    return score.min !== 0 || score.max !== 100;
  }

  filteringReactScore(): boolean {
    if (this.reactScoreBreakdown) {
      return true;
    }

    const reactScoreFilters = [
      this.reactScoreAverageSelected && this.scoreChanged(this.reactScore),
      !this.reactScoreAverageSelected &&
        this.scoreChanged(this.aggregateEmotionality),
      !this.reactScoreAverageSelected &&
        this.scoreChanged(this.aggregateFakeness),
      !this.reactScoreAverageSelected && this.scoreChanged(this.aggregateSpam),
      !this.reactScoreAverageSelected &&
        this.scoreChanged(this.aggregateHarmful),
    ];

    return reactScoreFilters.some((filter) => filter);
  }

  filteringSources(): boolean {
    const sourceFilters = [
      this.sources.length,
      this.excludedSources.length,
      this.categories.length,
      this.tiers?.length,
    ];

    return sourceFilters.some((filter) => filter);
  }

  filteringAuthors(): boolean {
    return Boolean(this.authors.length);
  }

  filteringPlatforms(): boolean {
    return Boolean(this.platforms.length);
  }

  filteringHandleOrSocialImpact(): boolean {
    const filters = [
      this.authors.length,
      this.verifiedEnabled !== this.initialFilters.verifiedEnabled,
      this.minimumFollowerCountEnabled !==
        this.initialFilters.minimumFollowerCountEnabled,
    ];

    return filters.some((filter) => filter);
  }

  filteringTags(): boolean {
    const tagFilters = [this.tags.length, this.excludedTags.length];

    return tagFilters.some((filter) => filter);
  }

  filteringLocations(): boolean {
    return Boolean(this.locations.length);
  }

  filteringMedia(defaultMedia: MediumName[]): boolean {
    return this.media.length
      ? defaultMedia.length - this.media.length > 0
      : false;
  }

  filteringKeywords(): boolean {
    if (this.booleanQuery || this.keywords || this.excludedKeywords)
      return true;

    return false;
  }

  filteringSortOptions(): boolean {
    return !this.sortOptions.some(
      (sortOption) =>
        sortOption.sortBy === SortOptionField.TIMESTAMP &&
        sortOption.orderBy === SortOptionOrder.DESC
    );
  }

  keywordsFiltersCount(): number {
    let count = 0;
    if (this.booleanQuery) count += 1;
    if (this.keywords || this.excludedKeywords) count += 1;

    return count;
  }

  mediaCoverageFilters(medium: Medium): string[] {
    const filters: string[] = [];

    if (this.locationKeys.some((key) => key.includes(medium.field)))
      filters.push(`${medium.medium} Location`);

    if (this.categoryKeys.some((key) => key.includes(medium.field)))
      filters.push(`${medium.medium} Category`);

    if (medium.field === MediumField.ONLINE) {
      if (this.domainAuthorityRanges.length) filters.push("Domain Authority");

      if (this.includeInternationalPlus !== null)
        filters.push("International Plus");

      if (this.includeOnlineCaldSources && features.has("has_cald_sources"))
        filters.push("Online CALD Sources");
    }

    if (medium.field === MediumField.PRINT) {
      if (this.includePrintCaldSources && features.has("has_cald_sources"))
        filters.push("Print CALD Sources");
    }

    if (medium.field === MediumField.SOCIAL) {
      if (this.selectedSocialContent.length) filters.push("Social Content");

      if (this.validateMinOrMax(this.socialImpactThreshold))
        filters.push("Social Impact Threshold");

      if (
        this.socialOnlyVerified !== null &&
        this.stream?.social_only_verified !== this.socialOnlyVerified
      ) {
        filters.push("Social Verified Only");
      }
    }

    if (medium.field === MediumField.TV) {
      if (this.selectedTvContent.length) filters.push("TV Content");
    }

    return filters;
  }

  mediaFilters(): string[] {
    if (this.stream && this.stream.enabled_media) {
      const filters: string[] = [];

      const enabledMedia = getSources().filter(({ medium }) =>
        this.stream!.enabled_media.includes(medium)
      );

      enabledMedia.forEach((medium) => {
        if (this.media.includes(medium.medium)) {
          filters.push(medium.medium);
        }

        filters.push(...this.mediaCoverageFilters(medium));
      });

      return filters;
    }

    return this.media;
  }

  mediaFiltersCount(): number {
    if (this.stream && this.stream.enabled_media) {
      const enabledMedia = getSources().filter(({ medium }) =>
        this.stream!.enabled_media.includes(medium)
      );

      if (this.media.length && enabledMedia.length !== this.media.length) {
        return this.media.length;
      }
    }

    return 0;
  }

  sourceFilters(): string[] {
    const filters: string[] = [];
    if (this.sources.length) filters.push("Included Sources");
    if (this.excludedSources.length) filters.push("Excluded Sources");

    return filters;
  }

  sourcesFiltersCount(): number {
    return this.sources.length + this.excludedSources.length;
  }

  contentFilters(): string[] {
    const filters: string[] = [];

    if (this.authors.length) filters.push("Authors");
    if (this.excludedAuthors.length) filters.push("Excluded Authors");
    if (this.excludedTags.length) filters.push("Excluded Tags");
    if (this.languageCodes.length) filters.push("Language Codes");
    if (this.excludedLanguageCodes.length)
      filters.push("Exclude Language Codes");
    if (this.license.length) filters.push("Content Types");
    if (this.pageNumbersEnabled) filters.push("Page Number");
    if (this.tags.length) filters.push("Tags");
    if (this.wordCountEnabled) filters.push("Word Count");

    return filters;
  }

  contentFiltersCount(): number {
    let count = 0;

    if (this.authors.length) count += 1;
    if (this.excludedAuthors.length) count += 1;
    if (this.excludedTags.length) count += 1;
    if (this.languageCodes.length) count += 1;
    if (this.excludedLanguageCodes.length) count += 1;
    if (this.license.length) count += 1;
    if (this.pageNumbersEnabled) count += 1;
    if (this.tags.length) count += 1;
    if (this.wordCountEnabled) count += 1;

    return count;
  }

  dateRangeFiltersCount(): number {
    if (this.dateRangeEnabled) {
      return 1;
    }

    return 0;
  }

  metricFilters(): string[] {
    const filters: string[] = [];

    if (this.filteringReactScore()) filters.push("React Score");
    if (this.validateMinOrMax(this.advertisementRate))
      filters.push("Advertisement Rate");
    if (this.sentimentEnabled || this.unratedSentiment)
      filters.push("Sentiment");
    if (this.impactScoreRanges.length) filters.push("Impact Score");
    if (this.validateMinOrMax(this.audience)) filters.push("Audience");
    if (this.validateMinOrMax(this.mobileMonthlyVisits))
      filters.push("Mobile Monthly Visits");
    if (this.validateMinOrMax(this.desktopMonthlyVisits))
      filters.push("Desktop Monthly Visits");

    return filters;
  }

  metricFiltersCount(): number {
    let count = 0;

    if (this.filteringReactScore()) count += 1;
    if (this.validateMinOrMax(this.advertisementRate)) count += 1;
    if (this.sentimentEnabled || this.unratedSentiment) count += 1;
    if (this.impactScoreRanges.length) count += 1;

    if (
      this.validateMinOrMax(this.audience) ||
      this.validateMinOrMax(this.mobileMonthlyVisits) ||
      this.validateMinOrMax(this.desktopMonthlyVisits)
    ) {
      count += 1;
    }

    return count;
  }

  otherFilters(): string[] {
    const filters: string[] = [];

    if (this.canFilterExternalItems()) {
      if (!this.externalItemsEnabled) filters.push("Exclude External Items");
      if (this.onlyShowExternalItems) filters.push("Only show External Items");
    }

    return filters;
  }

  otherFiltersCount(): number {
    if (
      this.canFilterExternalItems() &&
      (!this.externalItemsEnabled || this.onlyShowExternalItems)
    ) {
      return 1;
    }

    return 0;
  }

  reportsFilters(): string[] {
    const filters: string[] = [];

    if (this.includedMentionsEnabled) filters.push("Included Mentions");
    if (this.sortOptions.length > 1) filters.push("Sort Options");
    if (this.tiers.length) filters.push("Tiers");
    if (this.lastSavedEnabled) filters.push("Last Saved");

    return filters;
  }

  reportsFiltersCount(): number {
    let count = 0;
    if (this.includedMentionsEnabled) count += 1;
    if (this.sortOptions.length > 1) count += 1;
    if (this.tiers.length) count += 1;
    if (this.lastSavedEnabled) count += 1;

    return count;
  }

  totalFilters(): number {
    return (
      this.contentFiltersCount() +
      this.dateRangeFiltersCount() +
      this.keywordsFiltersCount() +
      this.mediaFiltersCount() +
      this.metricFiltersCount() +
      this.otherFiltersCount() +
      this.reportsFiltersCount() +
      this.sourcesFiltersCount()
    );
  }

  appliedFilters(): string[] {
    const filtersUsed: string[] = [];

    if (this.keywordsFiltersCount()) filtersUsed.push("Keywords");
    filtersUsed.push(...this.mediaFilters());
    filtersUsed.push(...this.sourceFilters());
    filtersUsed.push(...this.contentFilters());
    if (this.dateRangeFiltersCount()) filtersUsed.push("Date Range");
    filtersUsed.push(...this.metricFilters());
    filtersUsed.push(...this.otherFilters());
    filtersUsed.push(...this.reportsFilters());

    return filtersUsed;
  }

  platformsToMediaTypes(platforms: SocialPlatform[]): SocialMentionType[] {
    const mediaTypes: SocialMentionType[] = [];

    social.platforms.forEach((platform) => {
      if (platforms.includes(platform.platform)) {
        mediaTypes.push(...platform.fields);
      }
    });

    return mediaTypes;
  }

  requestFilters<T extends StreamFiltersType = StreamFiltersType.Filters>(
    type: T = StreamFiltersType.Filters as T
  ): StreamFiltersTypes<T> {
    const params = <StreamFiltersTypes<T>>{};

    if (this.keywords !== "") params.keywords = this.keywords;
    if (this.excludedKeywords !== "")
      params.excluded_keywords = this.excludedKeywords;
    if (this.booleanQuery !== "") params.boolean_query = this.booleanQuery;

    if (
      isStreamReportFilters(type, params) ||
      isStreamRequestFilters(type, params)
    ) {
      if (this.sources?.length) {
        params.source_keys = sourceKeysFor(this.sources);
        const includedSourceGroupKeys = sourceGroupKeys(this.sources);

        if (includedSourceGroupKeys.length)
          params.source_group_keys = sourceGroupKeys(this.sources);
      }

      if (this.excludedSources?.length > 0) {
        params.excluded_source_keys = sourceKeysFor(this.excludedSources);
        const excludedSourceGroupKeys = sourceGroupKeys(this.excludedSources);
        if (excludedSourceGroupKeys.length)
          params.excluded_source_group_keys = excludedSourceGroupKeys;
      }
    }

    if (this.tags.length > 0) {
      const tags = this.tags.map((tag) => tag.id);

      if (
        isStreamReportFilters(type, params) ||
        isStreamFilters(type, params)
      ) {
        params.bookmark_stream_ids = tags;
      } else {
        params.tag_ids = tags;
      }
    }

    if (this.excludedTags?.length) {
      const excludedTags = this.excludedTags.map(
        (excludedTag) => excludedTag.id
      );

      if (
        isStreamReportFilters(type, params) ||
        isStreamFilters(type, params)
      ) {
        params.excluded_bookmark_stream_ids = excludedTags;
      } else {
        params.excluded_tag_ids = excludedTags;
      }
    }

    if (isStreamRequestFilters(type, params)) {
      if (this.useExactAuthors) params.use_exact_authors = this.useExactAuthors;

      if (
        this.domainAuthority &&
        Object.keys(this.domainAuthority).length &&
        this.domainAuthority.min !== null &&
        this.domainAuthority.max !== null
      ) {
        params.min_domain_authority = this.domainAuthority.min;
        params.max_domain_authority = this.domainAuthority.max;
      }

      if (this.stockSymbol) params.stock_symbol = this.stockSymbol;

      if (this.sortOptions.length) {
        params.sort_options = this.sortOptions.map((option) => ({
          sort_by: option.sortBy,
          sort_order: option.orderBy,
          missing: option.missing,
        }));
      }

      if (this.platforms.length > 0) {
        params.types = this.platformsToMediaTypes(this.platforms);
      } else if (this.mediaTypes.length > 0) {
        params.types = this.mediaTypes;
      }

      if (this.verifiedEnabled) params.verified = this.verifiedEnabled;

      if (this.minimumFollowerCountEnabled)
        params.minimum_follower_count = this.minimumFollowerCount;

      if (this.categories.length) params.categories = this.categories;

      if (this.locations.length) {
        params.location_ids = this.locations;
      }

      if (this.dateRangeEnabled) {
        params.range = this.range;
      }

      if (this.lastSavedEnabled)
        params.lastSavedEnabled = this.lastSavedEnabled;

      if (this.collapseSyndicated)
        params.collapse_syndicated = this.collapseSyndicated;

      if (this.bundleBroadcast) params.bundle_broadcast = this.bundleBroadcast;

      if (this.tiers.length) {
        const selectedTiers = this.tiers.map((tier) => tier.value);

        if (this.media.length > 0) {
          this.media.forEach((medium) => {
            params[`${snakeCase(medium) as MediumField}_tiers`] = selectedTiers;
          });
        } else if (this.stream !== null) {
          this.stream.enabled_media?.forEach((medium) => {
            params[`${snakeCase(medium) as MediumField}_tiers`] = selectedTiers;
          });
        }
      }

      if (
        this.stream?.type === streamTypes.bookmarkStream &&
        this.includeBookmarkSyndication
      ) {
        params.include_bookmark_syndication = this.includeBookmarkSyndication;
      }

      if (this.programs.length) params.programs = this.programs;

      if (this.frontPageRanksEnabled)
        params.front_page_ranks = this.frontPageRanks;
    }

    if (this.domainAuthorityRanges.length) {
      params.domain_authority = this.domainAuthorityRanges;
    }

    if (this.authors.length) {
      params.authors = this.authors
        .map((author) => author.name || author)
        .join(", ");
    }

    if (this.excludedAuthors.length) {
      params.excluded_authors = this.excludedAuthors
        .map((author) => author.name || author)
        .join(", ");
    }

    if (this.wordCountEnabled && this.validateMinOrMax(this.wordCount)) {
      if (this.wordCount.min !== null)
        params.min_word_count = this.wordCount.min;
      if (this.wordCount.max !== null)
        params.max_word_count = this.wordCount.max;
    }

    if (this.externalItemsEnabled || isStreamFilters(type, params)) {
      params.include_external_items = this.externalItemsEnabled;

      if (this.externalItemsEnabled) {
        params.only_external_items = this.onlyShowExternalItems;
      }
    }

    if (this.locationKeys.length) {
      params.location_keys = this.locationKeys;
    }

    if (this.sentimentEnabled) {
      params.sentiment = this.sentiment;
    }

    if (this.unratedSentiment) {
      params.unrated_sentiment = this.unratedSentiment;
    }

    if (this.media.length > 0) params.media = this.media;

    if (this.categoryKeys.length) {
      params.category_keys = this.categoryKeys;
    }

    if (this.pageNumbersEnabled && this.validateMinOrMax(this.pageNumbers)) {
      params.page_numbers = this.pageNumbers;
    }

    if (
      this.stream &&
      this.stream.type !== streamTypes.socialStream &&
      this.filteringReactScore()
    ) {
      if (
        this.reactScoreAverageSelected &&
        this.validateMinMax(this.reactScore)
      ) {
        params.min_react_score = this.reactScore.min / 100;
        params.max_react_score = this.reactScore.max / 100;
      } else {
        if (this.validateMinMax(this.aggregateEmotionality)) {
          params.min_aggregate_emotionality =
            this.aggregateEmotionality.min / 100;

          params.max_aggregate_emotionality =
            this.aggregateEmotionality.max / 100;
        }

        if (this.validateMinMax(this.aggregateFakeness)) {
          params.min_aggregate_fakeness = this.aggregateFakeness.min / 100;
          params.max_aggregate_fakeness = this.aggregateFakeness.max / 100;
        }

        if (this.validateMinMax(this.aggregateSpam)) {
          params.min_aggregate_spam = this.aggregateSpam.min / 100;
          params.max_aggregate_spam = this.aggregateSpam.max / 100;
        }

        if (this.validateMinMax(this.aggregateHarmful)) {
          params.min_aggregate_harmful = this.aggregateHarmful.min / 100;
          params.max_aggregate_harmful = this.aggregateHarmful.max / 100;
        }
      }
    }

    if (this.languageCodes.length) {
      params.language_codes = this.languageCodes;
    }

    if (this.excludedLanguageCodes.length) {
      params.excluded_language_codes = this.excludedLanguageCodes;
    }

    if (this.impactScoreRanges.length) {
      params.impact_score = this.impactScoreRanges;
    }

    if (this.validateMinOrMax(this.advertisementRate)) {
      if (this.advertisementRate.min !== null)
        params.min_advertisement_rate = this.advertisementRate.min * 100;
      if (this.advertisementRate.max !== null)
        params.max_advertisement_rate = this.advertisementRate.max * 100;
    }

    if (this.validateMinOrMax(this.audience)) {
      if (this.audience.min !== null) params.min_audience = this.audience.min;
      if (this.audience.max !== null) params.max_audience = this.audience.max;
    }

    if (this.validateMinOrMax(this.desktopMonthlyVisits)) {
      if (this.desktopMonthlyVisits.min !== null)
        params.min_desktop_monthly_visits = this.desktopMonthlyVisits.min;
      if (this.desktopMonthlyVisits.max !== null)
        params.max_desktop_monthly_visits = this.desktopMonthlyVisits.max;
    }

    if (this.validateMinOrMax(this.mobileMonthlyVisits)) {
      if (this.mobileMonthlyVisits.min !== null)
        params.min_mobile_monthly_visits = this.mobileMonthlyVisits.min;
      if (this.mobileMonthlyVisits.max !== null)
        params.max_mobile_monthly_visits = this.mobileMonthlyVisits.max;
    }

    if (this.license.length) {
      params.license = this.license;
    }

    if (this.excludedLicense.length) {
      params.excluded_license = this.excludedLicense;
    }

    if (this.includeInternationalPlus !== null) {
      params.include_international_plus = this.includeInternationalPlus;
    }

    if (this.includeOnlineCaldSources) {
      params.include_online_cald_sources = this.includeOnlineCaldSources;
    }

    if (this.includePrintCaldSources) {
      params.include_print_cald_sources = this.includePrintCaldSources;
    }

    if (this.selectedTvContent.length) {
      params.selected_tv_content = this.selectedTvContent;
    }

    if (this.selectedSocialContent.length) {
      params.selected_social_content = this.selectedSocialContent;
    }

    if (this.validateMinOrMax(this.socialImpactThreshold)) {
      if (this.socialImpactThreshold.min !== null)
        params.min_social_impact_threshold = this.socialImpactThreshold.min;
      if (this.socialImpactThreshold.max !== null)
        params.max_social_impact_threshold = this.socialImpactThreshold.max;
    }

    if (this.socialOnlyVerified !== null) {
      params.social_only_verified = this.socialOnlyVerified;
    }

    return params;
  }

  validateMinMax(
    minMax: StreamFiltersMinMax
  ): minMax is { [K in keyof StreamFiltersMinMax]: number } {
    return minMax.max !== null && minMax.min !== null;
  }

  validateMinOrMax(
    minMax: StreamFiltersMinMax
  ): minMax is { [K in keyof StreamFiltersMinMax]: number } {
    return minMax.max !== null || minMax.min !== null;
  }
}
