export default function bulkSelection<T>(
  key: string,
  {
    keySelector,
    valueSelector,
    collection,
  }: {
    keySelector: (item: T) => string;
    valueSelector: (item: T) => boolean;
    collection: T[];
  }
): string[] {
  // if other items in the collection have a truthy value, return only the key
  if (
    collection.some(
      (item: T) => keySelector(item) !== key && valueSelector(item)
    )
  ) {
    return [key];
  }

  // if no other items in the collection have a truthy value, return all keys
  return collection.map(keySelector);
}
