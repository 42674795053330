<template>
  <BaseButtonDropdown
    v-model="openHelpPopup"
    class="inline-block"
    :close-on-click="false"
    :placement="placement"
  >
    <template #btn="{ listeners }">
      <span v-on="listeners">
        <slot />
      </span>
    </template>
    <div class="contact-popup q-pa-md">
      <div class="column q-gutter-sm text-left">
        <template v-if="isAccountManager">
          <div class="text-bold">{{ $t("contact_popup.need_help") }}</div>
          <i18n-t
            keypath="contact_popup.popup_subtext"
            :plural="getAccountManagerPlural()"
            scope="global"
            tag="div"
          >
            <template #contactEmail>
              <a
                class="link"
                :href="`mailto:${contactEmail}`"
              >
                {{ contactEmail }}
              </a>
            </template>
            <template #helpCentre>
              <a
                class="link"
                :href="
                  appConfig.platformName === 'Streem'
                    ? appConfig.urls.helpArticles
                    : $t('contact_menu.cision_knowledge_base_url')
                "
                rel="noopener noreferrer"
                target="_blank"
              >
                {{ $t("contact_popup.help_centre") }}
              </a>
            </template>
          </i18n-t>
          <template v-if="organisation.id">
            <div
              v-if="organisation.account_manager"
              class="account-manager-card flex row q-mt-md"
            >
              <img
                :alt="$t('contact_popup.account_manager_picture')"
                class="q-mr-sm"
                :src="organisation.account_manager.picture_url"
              />
              <div class="account-manager-details flex column">
                <span class="text-bold">
                  {{ organisation.account_manager.name }}
                </span>
                <a
                  class="link"
                  :href="`mailto:${organisation.account_manager.email}`"
                >
                  {{ organisation.account_manager.email }}
                </a>
                <a
                  v-if="appConfig.phone.raw"
                  class="link"
                  :href="`tel:${appConfig.phone.raw}`"
                >
                  {{ appConfig.phone.formatted }}
                </a>
              </div>
            </div>
            <div
              v-for="secondaryAccountManager in organisation.secondary_account_managers"
              :key="secondaryAccountManager.id"
              class="account-manager-card flex row q-mt-md"
            >
              <img
                :alt="$t('contact_popup.account_manager_picture')"
                class="q-mr-sm"
                :src="secondaryAccountManager.picture_url"
              />
              <div class="account-manager-details flex column">
                <span class="text-bold">
                  {{ secondaryAccountManager.name }}
                </span>
                <a
                  class="link"
                  :href="`mailto:${secondaryAccountManager.email}`"
                >
                  {{ secondaryAccountManager.email }}
                </a>
                <a
                  v-if="appConfig.phone.raw"
                  class="link"
                  :href="`tel:${appConfig.phone.raw}`"
                >
                  {{ appConfig.phone.formatted }}
                </a>
              </div>
            </div>
          </template>
          <QSpinner
            v-else
            class="full-width q-mt-lg"
            :size="30"
          />
        </template>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="displayCustomHelpCenterContact"
          class="custom-message"
          v-html="organisation.custom_help_center_contact"
        />
        <i18n-t
          v-else
          keypath="contact_popup.contact_team"
          scope="global"
          tag="div"
        >
          <template #contactLabel>
            <a
              class="link"
              :href="contactReference"
            >
              {{ contactLabel }}
            </a>
          </template>
        </i18n-t>
      </div>
    </div>
  </BaseButtonDropdown>
</template>

<script>
import { storeToRefs } from "pinia";

import { BaseButtonDropdown } from "shared/components/base";
import { useGlobalStore } from "shared/stores/global";

export default {
  name: "ContactPopup",
  components: {
    BaseButtonDropdown,
  },
  props: {
    placement: {
      type: String,
      default: "top",
    },
    organisation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    return { appConfig };
  },
  data() {
    return {
      openHelpPopup: false,
    };
  },
  computed: {
    contactEmail() {
      return this.isCustomerSuccess
        ? this.appConfig.emails.customerSuccess
        : this.appConfig.emails.accountManagers;
    },
    contactReference() {
      return this.isCustomerSuccess
        ? `mailto:${this.appConfig.emails.customerSuccess}`
        : `mailto:${this.appConfig.emails.accountManagers}`;
    },
    contactLabel() {
      return this.isCustomerSuccess
        ? this.appConfig.emails.customerSuccess
        : this.appConfig.emails.accountManagers;
    },
    isAccountManager() {
      return this.organisation.help_center_contact_option === "account_manager";
    },
    isCustomerSuccess() {
      return (
        this.organisation.help_center_contact_option === "customer_success"
      );
    },
    displayCustomHelpCenterContact() {
      return (
        this.organisation?.help_center_contact_option === "custom" &&
        this.organisation?.custom_help_center_contact
      );
    },
  },
  methods: {
    getAccountManagerPlural() {
      return this.organisation.secondary_account_managers.length + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(a),
:deep(.link) {
  color: $blue-link !important;
  text-decoration: none;

  &:hover {
    color: $blue-link-darker;
    text-decoration: underline !important;
  }
}

.contact-popup {
  max-width: 280px;

  .account-manager-card {
    img {
      width: 36px;
      height: 36px;
      border-radius: 999px;
    }
  }
}
</style>
