import { computed, ref } from "vue";

import features from "shared/services/features";
import { useTranslationStore } from "shared/stores/translation";

const SUPPORTED_TYPES = [
  "article",
  "magazine_article",
  "paper_article",
  "radio_clip",
  "tv_caption",
];

const SUPPORTED_LANGUAGES = [
  "de",
  "fr",
  "pt",
  "es",
  "sv",
  "it",
  "zh",
  "ja",
  "jv",
  "jw",
  "id",
  "ko",
  "ms",
  "th",
  "vi",
];

export function useTranslation(mention) {
  const translation = ref({
    loading: false,
    error: false,
    success: false,
  });

  const store = useTranslationStore();

  const canTranslateMention = computed(() => {
    const hasTranslateFeature = features.has(
      "translate_online_and_print_content"
    );

    if (!hasTranslateFeature) return false;

    const languageCode = mention.language_code;

    if (languageCode === "en") return false;

    if (!SUPPORTED_LANGUAGES.includes(languageCode)) return false;

    return SUPPORTED_TYPES.includes(mention.type);
  });

  const translatedMention = computed(() => {
    const processedTranslation = store.getTranslation(mention.id);

    return {
      ...translation.value,
      success: Boolean(processedTranslation) || translation.value.success,
      title: processedTranslation?.title || "",
      body: processedTranslation?.body || "",
      excerpts: processedTranslation?.excerpts || [],
      source_language: processedTranslation?.source_language || "",
      target_language: processedTranslation?.target_language || "",
    };
  });

  async function translateMention() {
    try {
      if (translation.value.success) {
        return;
      }

      translation.value.error = false;
      translation.value.loading = true;

      await store.fetchMentionTranslation(mention);

      translation.value.success = true;
    } catch {
      translation.value.error = true;
    } finally {
      translation.value.loading = false;
    }
  }

  return {
    translateMention,
    translatedMention,
    canTranslateMention,
  };
}

export default useTranslation;
