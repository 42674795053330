<template>
  <div class="column fit no-wrap journalist">
    <div
      class="row full-width q-mb-md q-px-smd no-shrink no-wrap journalist-container"
    >
      <!-- z-index: 2000 required to put image on top of quasar nav bar -->
      <div
        class="col-auto profile-img-container relative full-height q-mr-smd"
        style="z-index: 2000"
      >
        <QIcon
          class="profile-img"
          :name="sourceIcon(mention.type)"
        />
      </div>
      <div class="column q-mt-xs q-mx-sm">
        <div class="row journalist-name">
          {{ author }}
        </div>
      </div>
    </div>

    <div class="row bigger2 bold q-ml-md q-mb-sm q-mt-lg q-px-smd no-shrink">
      <div class="col-auto q-mr-xl">
        {{ $t("journalist_detail.latest_media") }}
      </div>
    </div>
    <div class="relative">
      <div class="absolute-top inset-shadow-top" />
    </div>
    <div class="row grow q-pl-md q-pr-sm scroll-y tab">
      <!-- Mentions -->
      <MentionStream
        ref="mentionStream"
        class="no-header full-width"
        :class="{ 'full-height': $q.platform.is.ios }"
        :default-range="defaultRange"
        :fetching-service="fetchingService"
        hide-edit-button
        hide-generate-report-button
        hide-range-selector
        scroll-class="q-pr-sm"
        :stream="stream"
      >
        <template #noMentions>
          <div class="no-media-container column justify-center items-center">
            <strong class="q-mb-md">
              {{ $t("journalist_detail.no_mentions_found") }}
            </strong>
          </div>
        </template>
      </MentionStream>
    </div>
  </div>
</template>

<script>
import MentionStream from "shared/components/MentionStream.vue";
import DateRange from "shared/helpers/DateRange";
import { sourceIcon } from "shared/helpers/media";
import { dig } from "shared/helpers/object";
import fetchingService from "shared/services/fetching/streamSearch";

export default {
  name: "JournalistDetail",
  components: {
    MentionStream,
  },
  props: {
    author: {
      type: String,
      required: true,
    },
    mention: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchingService,
      defaultRange: DateRange.lastThirtyDays(),
    };
  },
  computed: {
    stream() {
      let query = `author: "${this.author}" AND "${this.author}"`;

      if (this.sourceGroup) {
        query += ` AND (source.group: "${this.sourceGroup}" OR medium: Social)`;
      }

      return {
        media: [],
        online_content: true,
        print_content: true,
        social_content: true,
        magazine_content: true,
        enabled_media: [],
        filters: [],
        disableLocations: true,
        hideFilterCheckboxes: true,
        skipReload: true,
        keywords: query,
      };
    },
    sourceGroup() {
      return dig(this.mention, "source", "source_group", "name");
    },
  },
  watch: {
    author() {
      this.$nextTick(() => {
        this.$refs.mentionStream?.refreshMentions();
      });
    },
  },
  methods: {
    sourceIcon,
  },
};
</script>

<style lang="scss" scoped>
.journalist-name {
  font-size: 24px;
  font-weight: bold;
}

.journalist {
  background: white;
}

.journalist-container {
  min-height: 60px;
  max-width: 380px;
}

.profile-img-container {
  width: 95px;
}

.profile-img {
  object-fit: cover;
  width: 95px;
  height: 95px;
  background-color: $silver-whiter;
  position: absolute;
  top: -35px;
  border: 3px solid white;
  border-radius: 50%;
  font-size: 75px;
  color: $soft;
}

:deep(.no-header > div > .card-column-header),
:deep(.no-header .card-header) {
  display: none;
}

.tab {
  padding-top: 9px;
  background-color: $body-background;
}

.inset-shadow-top {
  box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 10%);
  z-index: 1;
  height: 4px;
}

.no-shrink {
  flex-shrink: 0;
}
</style>
