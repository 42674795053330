<template>
  <span class="softer tw-inline-flex tw-text-sm">
    <span class="flex items-center justify-center tw-mr-1">
      <BaseIcon icon="inventory" />
    </span>
    <span
      v-if="isTvEyesMediaRequestStatus === 'In Progress'"
      class="inline-block align-middle"
    >
      {{ getLocaleText("mention_detail_tv_radio.processing") }}
    </span>
    <span
      v-if="isTvEyesMediaRequestStatus === 'Completed'"
      class="inline-block align-middle"
    >
      {{ getLocaleText("mention_detail_tv_radio.archived") }}
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { BaseIcon } from "shared/components/base";
import { type Nullable } from "shared/types";
import type { RadioClip, TvCaption } from "shared/types/mentions";

const props = defineProps<{
  mention: TvCaption | RadioClip;
}>();

const isTvEyesMediaRequestStatus = computed<Nullable<string>>(
  () => props.mention.tveyes_media_request?.status || null
);
</script>
