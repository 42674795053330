<template>
  <div class="tw-flex tw-flex-nowrap tw-text-xxs">
    <div class="tw-mr-0.5">
      <QIcon
        class="text-ds-denim-9 tw-mt-[1px]"
        name="ion-ios-people"
        size="23px"
      />
    </div>

    <div>
      {{ getLocaleText("social_view_count_widget.label") }}

      <div class="tw-text-left tw-text-xxs tw-font-bold">
        {{ suffix(viewsCount) }}
      </div>
    </div>

    <StreemTooltip> {{ suffix(viewsCount) }} {{ tooltip }} </StreemTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { StreemTooltip } from "shared/components/base";
import { suffix } from "shared/helpers/number";
import type { Mention } from "shared/types/mentions";
import { MentionType } from "shared/types/mentions";

export interface SocialViewCountWidgetProps {
  mention: Mention;
}

const props = defineProps<SocialViewCountWidgetProps>();

const isBlogPost = computed(() => props.mention.type === MentionType.blog_post);
const isTwitter = computed(() => props.mention.type === MentionType.tweet);

const isYouTube = computed(
  () => props.mention.type === MentionType.youtube_video
);

const isReddit = computed(() =>
  ["reddit_post", "reddit_post_comment"].includes(props.mention.type)
);

const viewsCount = computed(() => {
  if (isTwitter.value) {
    return props.mention.author_follower_count;
  }

  if (isYouTube.value) {
    return props.mention.view_count;
  }

  if (isReddit.value) {
    return props.mention.source_follower_count;
  }

  return 0;
});

const tooltip = computed(() => {
  if (isTwitter.value) {
    return getLocaleText("social_mention_card.followers");
  }

  if (isReddit.value) {
    return getLocaleText("social_mention_card.subreddit_followers");
  }

  if (isYouTube.value || isBlogPost.value) {
    return getLocaleText("social_mention_card.views");
  }

  return "";
});
</script>
