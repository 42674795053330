<template>
  <div
    class="card q-pa-xs matching-streams align-center"
    :class="`border-color-${medium}`"
  >
    <div class="relative q-pa-xs">
      <div>
        <QIcon name="chat_bubble" />
        <span class="info">
          <i18n-t
            keypath="matching_streams.found_in_mention_stream"
            :plural="streams.length"
            scope="global"
            tag="span"
          >
            <template #streamLabel>
              <a
                class="hover-active cursor-pointer inline-block"
                @click="goToStream(streams[0].id)"
              >
                {{ streams[0].label }}
              </a>
            </template>
          </i18n-t>

          <span
            v-if="otherStreams.length"
            class="alternate q-mt-xs cursor-pointer"
            @click.stop="displayAll = !displayAll"
          >
            + {{ otherStreams.length }}
          </span>
        </span>
      </div>

      <QBtn
        v-if="otherStreams.length"
        :aria-label="$t('matching_streams.display_all')"
        class="soft q-mr-xs absolute-right"
        dense
        flat
        :icon="displayAll ? 'ion-md-arrow-dropdown' : 'ion-md-arrow-dropright'"
        size="13px"
        @click.stop="displayAll = !displayAll"
      />
    </div>

    <ul
      v-if="displayAll"
      class="matching-streams-list"
    >
      <li
        v-for="stream in otherStreams"
        :key="stream.id"
        class="q-pa-xs bold info"
      >
        <a
          class="hover-active cursor-pointer"
          :class="(stream.primary || stream.shared) && 'flex'"
          @click="goToStream(stream.id)"
        >
          <QIcon
            v-if="stream.primary"
            name="trip_origin"
            size="14px"
          />

          <QIcon
            v-else-if="stream.shared"
            name="ion-shuffle"
            size="14px"
          />

          <ColorIndicator
            v-else
            :color="stream.group && stream.group.color"
            size="14px"
          />
          <span class="q-pl-xs">{{ stream.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { useDesktopStore } from "desktop/src/stores/desktop";

import ColorIndicator from "shared/components/core/various/ColorIndicator.vue";
import { formattedMedium } from "shared/helpers/mentions";
import { useStreamsStore } from "shared/stores/streams";

export default {
  name: "MatchingStreams",
  components: {
    ColorIndicator,
  },
  props: {
    mention: {
      type: Object,
      required: true,
      validator(value) {
        return "stream_ids" in value;
      },
    },
  },
  setup() {
    const streamsStore = useStreamsStore();
    const { getStreamById } = streamsStore;

    const desktopStore = useDesktopStore();
    const { setFocusedStream } = desktopStore;

    return {
      getStreamById,
      setFocusedStream,
    };
  },
  data() {
    return {
      displayAll: false,
    };
  },
  computed: {
    streams() {
      return this.mention.stream_ids.map((id) => this.getStreamById(id));
    },
    otherStreams() {
      return this.streams.slice(1);
    },
    medium() {
      return formattedMedium(this.mention);
    },
  },
  methods: {
    goToStream(id) {
      const stream = this.getStreamById(id);

      if (stream.primary) {
        this.$safeRouterPush({ name: "launchpad" }).catch(() => {});
      } else {
        this.setFocusedStream(stream);

        this.$safeRouterPush({
          name: "stream",
          params: {
            groupSlug: stream.group.slug,
            streamSlug: stream.slug,
          },
        }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.matching-streams {
  min-height: 10px;
  padding: 0.25em;
  margin-top: 1px;
  border-left: 5px solid;
}

.info {
  font-size: 12px;
}

.card {
  &.warning {
    h3 {
      margin: 12px 0;
    }

    .q-icon {
      vertical-align: -3px;
    }
  }
}

.matching-streams-list {
  background-color: #eee;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  list-style: none;
}
</style>
