<template>
  <div class="c-risk-range-selector">
    <div class="c-risk-range-selector-top">
      <div class="c-risk-range-selector-top-heading">
        <div class="c-risk-range-selector-top-heading-title">
          {{ title }}
        </div>
        <QIcon name="info">
          <StreemTooltip
            anchor="top middle"
            self="bottom middle"
          >
            {{ tooltipText }}
          </StreemTooltip>
        </QIcon>
      </div>
      <div class="c-risk-range-selector-top-sub-heading">
        {{ subTitle }}
      </div>
    </div>
    <div class="c-risk-range-selector-body">
      <span class="c-risk-range-selector-body-range-label">
        {{ $t("risk_range_selector.low") }}
      </span>
      <QRange
        :key="rangeKey"
        v-model="handleRiskRange"
        marker-labels
        :max="100"
        :min="0"
        :step="25"
      >
        <template #marker-label-group="scope">
          <div
            v-for="marker in scope.markerList"
            :key="marker.index"
            :class="[
              marker.classes,
              'c-risk-range-selector-body-range-marker-label',
            ]"
            :style="marker.style"
          >
            {{ marker.value }}
          </div>
        </template>
      </QRange>
      <span class="c-risk-range-selector-body-range-label">{{
        $t("risk_range_selector.high")
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RiskRangeSelector",
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    subTitlePrefix: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      rangeKey: 1,
    };
  },
  computed: {
    handleRiskRange: {
      get() {
        return this.modelValue;
      },
      set(val) {
        let range = val;

        if (val.min === val.max) {
          range = this.modelValue;

          this.rangeKey += 1;
        }

        this.$emit("update:modelValue", range);
      },
    },
    subTitle() {
      return this.$t("risk_range_selector.sub_title", {
        subTitlePrefix: this.subTitlePrefix,
        min: this.modelValue.min,
        max: this.modelValue.max,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.c-risk-range-selector {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  padding-bottom: 25px;

  &-top {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &-heading {
      display: flex;
      align-items: center;
      column-gap: 8px;

      &-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
    }

    &-sub-heading {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      color: var(--s-color-denim-8);
    }
  }

  &-body {
    display: flex;
    column-gap: 15px;

    &-range-label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: var(--s-color-denim-8);
      text-transform: uppercase;
      padding-top: 7px;
    }

    &-range-marker-label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: var(--s-color-denim-7);
    }
  }
}
</style>
