<template>
  <div
    class="sidebar column absolute-right card full-height"
    :class="{ 'new-design': shouldUseNewDesign }"
  >
    <div
      class="header q-pa-md row no-wrap items-center justify-between"
      :class="{ 'new-design': shouldUseNewDesign }"
    >
      <div
        class="title"
        :class="{ 'new-design': shouldUseNewDesign }"
      >
        <h3>
          {{ sideBarTitle }}
        </h3>
        <span>{{ sideBarSubtitle }}</span>
      </div>
      <QBtn
        v-if="!shouldHideCloseButton"
        :aria-label="$t('global.close')"
        class="q-ml-sm"
        dense
        flat
        @click="$emit('close')"
      >
        <QIcon
          name="ion-close"
          size="28px"
        />
      </QBtn>
    </div>

    <BaseSeparator />

    <div class="sidebar-body">
      <Component
        :is="activeSideBar"
        v-bind="sideBarData"
      />
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { BaseSeparator } from "shared/components/base";

import sideBars from "src/components/sideBars";
import { useSideBarStore } from "src/stores/sideBar";

export default {
  name: "SideBar",
  components: {
    ...sideBars,
    BaseSeparator,
  },
  emits: ["close"],
  setup() {
    const sideBarStore = useSideBarStore();

    const { sideBarTitle, sideBarSubtitle, sideBarComponent, sideBarData } =
      storeToRefs(sideBarStore);

    return { sideBarTitle, sideBarSubtitle, sideBarComponent, sideBarData };
  },
  computed: {
    activeSideBar() {
      return sideBars[this.sideBarComponent];
    },
    shouldUseNewDesign() {
      return this.sideBarData?.newDesign;
    },
    shouldHideCloseButton() {
      return this.sideBarData?.hideCloseButton;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "shared/css/utils/variables";

.card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sidebar {
  overflow: hidden;
  width: 380px;
  z-index: 500;
  background: $white;
  border-left: 1px solid $sidebar-grey;

  &.new-design {
    box-shadow: 0 8px 16px 0 #00000026;
  }

  .header {
    &.new-design {
      background: $white;
    }

    .title {
      &.new-design {
        color: var(--s-color-denim-9);
      }

      h3 {
        margin: 0;
      }
    }
  }

  .sidebar-body {
    flex: 1;
    min-height: 0;
    border-top: 1px $sidebar-grey;
  }
}
</style>
