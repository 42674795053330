<template>
  <div class="q-gutter-y-sm">
    <label class="block">
      <span class="bold block q-mb-sm">
        {{ $t("content_expandable.keywords") }}
        <small class="label-extra">
          {{ $t("content_expandable.comma_separated") }}
        </small>
      </span>
      <InputText
        v-model="handleKeywords"
        new-design
      />
    </label>

    <label class="block">
      <span class="bold block q-mb-sm">
        {{ $t("content_expandable.excluded_keywords") }}
        <small class="label-extra">
          {{ $t("content_expandable.comma_separated") }}
        </small>
      </span>
      <InputText
        v-model="handleExcludedKeywords"
        new-design
      />
    </label>

    <label
      v-if="!hidePageNumbers && hasPrintEnabled"
      class="block"
    >
      <span class="bold block q-mb-sm">
        {{ $t("content_expandable.page_number") }}
        <QToggle
          v-model="handlePageNumbersEnabled"
          class="float-right"
          color="ds-denim-9"
          dense
        />
      </span>

      <div
        v-if="handlePageNumbersEnabled"
        class="row no-wrap q-gutter-x-sm"
      >
        <InputText
          v-model="handlePageNumbersMin"
          min="1"
          new-design
          :placeholder="$t('content_expandable.min')"
          type="number"
        />
        <InputText
          v-model="handlePageNumbersMax"
          min="1"
          new-design
          :placeholder="$t('content_expandable.max')"
          type="number"
        />
      </div>
    </label>

    <label
      v-if="!hideWordCount"
      class="block"
    >
      <span class="bold block q-mb-sm">
        {{ $t("content_expandable.word_count") }}
        <QToggle
          v-model="handleWordCountEnabled"
          class="float-right"
          color="ds-denim-9"
          dense
        />
      </span>
    </label>

    <QRange
      v-if="wordCountEnabled"
      v-model="handleWordCount"
      class="range-bar range-label"
      :label-always="wordCountEnabled"
      :max="maxWordCount"
      :min="0"
      :step="1"
    />

    <label
      v-if="showIncludedMentions"
      class="block"
    >
      <span class="bold block q-mb-sm">
        {{ $t("content_expandable.hide_mentions_already_included") }}
        <QToggle
          v-model="handleIncludedMentionsEnabled"
          class="float-right"
          color="ds-denim-9"
          dense
        />
      </span>
    </label>

    <label
      v-if="showLastSavedToggle"
      class="block"
    >
      <span class="bold block q-mb-sm">
        {{ $t("content_expandable.filter_since_last_saved") }}
        <QToggle
          v-model="handleLastSavedEnabled"
          class="float-right"
          color="ds-denim-9"
          dense
        />
      </span>
    </label>

    <SentimentExpandable
      v-model:sentiment="handleSentiment"
      v-model:sentiment-enabled="handleSentimentEnabled"
      class="q-mb-sm"
      span-class="bold block"
    />

    <label
      v-if="!hideDateRange"
      class="block"
    >
      <span class="bold block q-mb-sm">
        {{ $t("content_expandable.date_range") }}
        <QToggle
          v-model="handleDateRangeEnabled"
          class="float-right"
          color="ds-denim-9"
          dense
        />
      </span>
    </label>

    <template v-if="dateRangeEnabled">
      <InputDatePicker
        v-if="$isDesktop"
        v-model:range="handleDateRange"
        class="e2e-date-picker"
        close-on-update
        date-style="medium"
        limit-to-past-year
        range-selection="custom"
        show-date-input
        show-range-options
        show-time-input
        show-update-button
        time-style="short"
      >
        <template #btn="{ toggle, afterLabel, beforeLabel }">
          <div
            class="pointer e2e-date-range"
            @click="toggle"
          >
            <i18n-t
              keypath="content_expandable.from_date_to"
              scope="global"
              tag="span"
            >
              <template #afterLabel>
                <strong>{{ afterLabel }}</strong>
              </template>
              <template #beforeLabel>
                <strong>{{ beforeLabel }}</strong>
              </template>
            </i18n-t>
          </div>
        </template>
      </InputDatePicker>
      <InputDate
        v-else
        v-model:range="handleDateRange"
        class="range-options"
        range-options-only
      />
    </template>

    <template v-if="!isSocialStream">
      <label
        v-if="showExternalItemsToggle"
        class="block"
      >
        <span class="bold block q-mb-sm">
          {{ $t("content_expandable.external_items") }}
          <QToggle
            v-model="handleExternalItemsEnabled"
            class="float-right"
            color="ds-denim-9"
            dense
          />
        </span>
      </label>

      <QCheckbox
        v-if="handleExternalItemsEnabled"
        v-model="handleOnlyShowExternalItems"
        @update:model-value="trackOnlyShowExternalItemsAction"
      >
        <span> {{ $t("content_expandable.only_external_items") }} </span>
      </QCheckbox>
    </template>
  </div>
</template>

<script>
import { intersection } from "lodash-es";

import { InputDate, InputDatePicker, InputText } from "shared/components/base";
import SentimentExpandable from "shared/components/selectors/expandables/SentimentExpandable.vue";
import { streamTypes } from "shared/constants";
import DateRange from "shared/helpers/DateRange";
import { getMediaForStream } from "shared/helpers/media";
import StreamFilters from "shared/helpers/StreamFilters";

const MAX_WORD_COUNT = 3000;

export default {
  name: "ContentExpandable",
  components: {
    InputDate,
    InputDatePicker,
    InputText,
    SentimentExpandable,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    filters: {
      type: StreamFilters,
      required: true,
    },
    keywords: {
      type: String,
      default: "",
    },
    excludedKeywords: {
      type: String,
      default: "",
    },
    pageNumbers: {
      type: Object,
      default: () => ({ min: null, max: null }),
    },
    wordCount: {
      type: Object,
      default: () => ({}),
    },
    sentiment: {
      type: Array,
      default: () => [],
    },
    selectedDateRange: {
      type: DateRange,
      default: () => DateRange.today(),
    },
    pageNumbersEnabled: {
      type: Boolean,
    },
    dateRangeEnabled: {
      type: Boolean,
    },
    externalItemsEnabled: {
      type: Boolean,
    },
    onlyShowExternalItems: {
      type: Boolean,
    },
    wordCountEnabled: {
      type: Boolean,
    },
    lastSavedEnabled: {
      type: Boolean,
    },
    sentimentEnabled: {
      type: Boolean,
    },
    includedMentionsEnabled: {
      type: Boolean,
    },
    showIncludedMentions: {
      type: Boolean,
    },
    hideDateRange: {
      type: Boolean,
    },
    hidePageNumbers: {
      type: Boolean,
    },
    hideWordCount: {
      type: Boolean,
    },
    showLastSavedToggle: {
      type: Boolean,
    },
  },
  emits: [
    "update:keywords",
    "search",
    "update:excludedKeywords",
    "update:pageNumbers",
    "update:pageNumbersEnabled",
    "update:wordCountEnabled",
    "update:lastSavedEnabled",
    "update:includedMentionsEnabled",
    "update:sentimentEnabled",
    "update:sentiment",
    "update:dateRangeEnabled",
    "update:externalItemsEnabled",
    "update:onlyShowExternalItems",
    "update:wordCount",
    "update:selectedDateRange",
  ],
  computed: {
    isSocialStream() {
      return this.stream.type === streamTypes.socialStream;
    },
    hasPrintEnabled() {
      const streamMedia = getMediaForStream(this.stream);
      let mediaFilters = this.filters.media;
      if (!mediaFilters.length) mediaFilters = streamMedia;

      const filteredMedia = intersection(streamMedia, mediaFilters);

      return (
        filteredMedia.includes("Print") || filteredMedia.includes("Magazine")
      );
    },
    showExternalItemsToggle() {
      return (
        this.$features.has("has_external_items") &&
        this.$permissions.has("can_insert_external_items")
      );
    },
    handleKeywords: {
      get() {
        return this.keywords;
      },
      set(val) {
        this.$emit("update:keywords", val);
        this.$emit("search");
      },
    },
    handleExcludedKeywords: {
      get() {
        return this.excludedKeywords;
      },
      set(val) {
        this.$emit("update:excludedKeywords", val);
        this.$emit("search");
      },
    },
    handlePageNumbersMin: {
      get() {
        return this.pageNumbers.min;
      },
      set(val) {
        this.$emit("update:pageNumbers", { ...this.pageNumbers, min: val });
        this.$emit("search");
      },
    },
    handlePageNumbersMax: {
      get() {
        return this.pageNumbers.max;
      },
      set(val) {
        this.$emit("update:pageNumbers", { ...this.pageNumbers, max: val });
        this.$emit("search");
      },
    },
    handlePageNumbersEnabled: {
      get() {
        return this.pageNumbersEnabled;
      },
      set(val) {
        this.$emit("update:pageNumbersEnabled", val);
        this.$emit("search");
      },
    },
    handleWordCountEnabled: {
      get() {
        return this.wordCountEnabled;
      },
      set(val) {
        this.$emit("update:wordCountEnabled", val);
        this.$emit("search");
      },
    },
    handleLastSavedEnabled: {
      get() {
        return this.lastSavedEnabled;
      },
      set(val) {
        this.$emit("update:lastSavedEnabled", val);
        this.$emit("search");
      },
    },
    handleIncludedMentionsEnabled: {
      get() {
        return this.includedMentionsEnabled;
      },
      set(val) {
        this.$emit("update:includedMentionsEnabled", val);
        this.$emit("search");
      },
    },
    handleSentimentEnabled: {
      get() {
        return this.sentimentEnabled;
      },
      set(val) {
        this.$emit("update:sentimentEnabled", val);
        this.$emit("search");
      },
    },
    handleSentiment: {
      get() {
        return this.sentiment;
      },
      set(val) {
        this.$emit("update:sentiment", val);
        this.$emit("search");
      },
    },
    handleDateRangeEnabled: {
      get() {
        return this.dateRangeEnabled;
      },
      set(val) {
        this.$emit("update:dateRangeEnabled", val);
        this.$emit("search");
      },
    },
    handleExternalItemsEnabled: {
      get() {
        return this.externalItemsEnabled;
      },
      set(val) {
        this.$emit("update:externalItemsEnabled", val);
        this.$emit("search");
      },
    },
    handleOnlyShowExternalItems: {
      get() {
        return this.onlyShowExternalItems;
      },
      set(val) {
        if (this.handleExternalItemsEnabled) {
          this.$emit("update:onlyShowExternalItems", val);
          this.$emit("search");
        }
      },
    },
    handleWordCount: {
      get() {
        return this.wordCount;
      },
      set(val) {
        if (this.handleWordCountEnabled) {
          this.$emit("update:wordCount", val);
          this.$emit("search");
        }
      },
    },
    handleDateRange: {
      get() {
        return this.selectedDateRange;
      },
      set(val) {
        if (this.handleDateRangeEnabled) {
          this.$emit("update:selectedDateRange", val);
          this.$emit("search");
        }
      },
    },
    maxWordCount() {
      return MAX_WORD_COUNT;
    },
  },
  methods: {
    trackOnlyShowExternalItemsAction() {
      this.$track("Clicked Only show External Items Filter Checkbox");
    },
  },
};
</script>

<style lang="scss" scoped>
.label-extra {
  font-weight: normal;
  color: var(--s-color-denim-7);
}

.range-label {
  :deep(.q-slider__pin) {
    transform: rotate(180deg) !important;
    top: 20px !important;
  }

  :deep(.q-slider__text-container) {
    transform: rotate(180deg) !important;
  }

  :deep(.q-slider__arrow) {
    transform: rotate(180deg) !important;
    top: 15px !important;
  }

  :deep(.q-slider__pin-text-container) {
    transform: rotate(180deg) translateX(20px) !important;
    right: -100% !important;
  }
}

.range-bar {
  margin-bottom: 20px;
}

.range-options {
  border: 1px solid var(--s-color-denim-3);
  border-radius: var(--s-border-radius-md);
  padding: var(--s-spacing-md);
}
</style>
