<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="e2e-mention-content"
    :class="componentClassList"
  >
    <template
      v-if="displayExcerpts && !hideExcerpts && showCaptionTvSuperKeywords"
    >
      <p :class="`border-color-${medium}`">
        {{ $t("mention_excerpts.text_on_screen") }}
        <span v-html="tvSuperKeywords" />
      </p>
    </template>

    <template v-if="excerptsVisible">
      <!-- Show full body when mention is tweet -->
      <template
        v-if="(showHighlightedBody || showPlainBody) && displayExcerpts"
      >
        <div>
          <p v-html="mention.body_highlighted || mention.body" />
        </div>
      </template>

      <!-- Show normal excerpts, one per paragraph (if shown) -->
      <template v-else-if="displayedExcerpts.length">
        <div>
          <LineClamp :lines="5">
            <span
              class="excerpt tw-text-denim-900"
              v-html="displayedExcerpts[0].text"
            />
          </LineClamp>
          <div v-show="hasExpandExcerptsButton && displayAllExcerpts">
            <div
              v-for="(excerpt, index) in expandedExcerpts"
              :key="index"
              class="q-mt-md"
            >
              <span
                class="excerpt tw-text-denim-900"
                v-html="excerpt.text"
              />

              <a
                v-if="index === expandedExcerpts.length - 1"
                class="text-ds-denim-9 hover-ds-denim-8 bold cursor-pointer"
                @click.stop="displayAllExcerpts = !displayAllExcerpts"
              >
                {{ $t("mention_excerpts.see_less") }}
              </a>
            </div>
          </div>

          <a
            v-if="!displayAllExcerpts && hasExpandExcerptsButton"
            class="text-ds-denim-9 hover-ds-denim-8 bold cursor-pointer"
            @click.stop="displayAllExcerpts = !displayAllExcerpts"
          >
            {{ $t("mention_excerpts.see_more") }}
          </a>
        </div>
      </template>
    </template>

    <div
      v-if="excerptsVisible && keywordsVisible"
      class="q-my-sm"
    />

    <!-- Show keywords mentioned when excerpts are unavailable or hidden -->
    <template v-if="keywordsVisible">
      <p
        class="tw-text-denim-900"
        :class="[`border-color-${medium}`]"
      >
        {{ keywordsVerb }}
        <span v-html="keywords" />

        <template v-if="isRadioOrPodcastType">
          {{ $t("mention_excerpts.around_time_zone", { timeInZone }) }}
        </template>
      </p>
    </template>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { LineClamp } from "shared/components/base";
import { toSentence } from "shared/helpers/array";
import {
  formatDate,
  fromCurrentToGivenTimezone,
  shortTimezone,
} from "shared/helpers/date";
import { getSource, social } from "shared/helpers/media";
import {
  excerptsKeywords,
  formattedMedium,
  mentionKeywords,
} from "shared/helpers/mentions";
import { highlight } from "shared/helpers/string";
import { useUserStore } from "shared/stores/user";

export default {
  name: "MentionExcerpts",
  components: {
    LineClamp,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    expandable: {
      type: Boolean,
    },
    listView: {
      type: Boolean,
    },
    noHighlighting: {
      type: Boolean,
    },
    alwaysShowKeywords: {
      type: Boolean,
    },
    socialStream: {
      type: Boolean,
    },
  },
  setup() {
    const userStore = useUserStore();
    const { showFullExcerpts, adminUserEnabled } = storeToRefs(userStore);

    return { showFullExcerpts, adminUserEnabled };
  },
  data() {
    return {
      displayAllExcerpts: false,
    };
  },
  computed: {
    medium() {
      return formattedMedium(this.mention);
    },
    isRadioOrPodcastType() {
      return ["radio_clip", "podcast_episode"].includes(this.mention.type);
    },
    timeInZone() {
      const timestampMillisconds =
        this.mention.timestamp_milliseconds || this.mention.timestamp * 1000;

      const isoString = new Date(timestampMillisconds).toISOString();

      const timeInTimezone = fromCurrentToGivenTimezone(
        isoString,
        this.mention.source.time_zone
      );

      const timezone = shortTimezone(
        timeInTimezone,
        this.mention.source.time_zone
      );

      return `${formatDate(timeInTimezone, "h:mmaaa")} ${timezone}`;
    },
    componentClassList() {
      return [
        this.hasExpandExcerptsButton ? "q-mb-md" : "",
        this.listView ? "list-view" : "",
        this.noHighlighting ? "no-highlight" : "",
      ];
    },
    keywordsVerb() {
      switch (this.mention.type) {
        case "tv_super":
          return this.$t("mention_excerpts.text_on_screen");
        case "tv_logo_appearance":
          return this.$t("mention_excerpts.logo_on_screen");
        default:
          return this.$t("mention_excerpts.mentions");
      }
    },
    excerptsLength() {
      return (this.captionExcerpts || this.mention.excerpts).length;
    },
    hasExpandExcerptsButton() {
      return (
        this.expandable &&
        this.allowExpandExcerpts &&
        this.hasExcerpts &&
        this.excerptsLength > 1 &&
        (this.adminUserEnabled || this.displayExcerpts) &&
        !this.showKeywords
      );
    },
    captionExcerpts() {
      if (this.mention.type === "tv_caption") {
        return this.mention.excerpts.filter(
          (excerpt) => excerpt.type !== "tv_super"
        );
      }

      return null;
    },
    displayedExcerpts() {
      const displayed = [];
      const excerpts = this.captionExcerpts || this.mention.excerpts;

      excerpts.forEach((excerpt, index) => {
        if (this.shouldDisplayExcerpt(index)) displayed.push(excerpt);
      });

      return displayed;
    },
    expandedExcerpts() {
      const [, ...rest] = this.displayedExcerpts;

      return rest;
    },
    showKeywords() {
      return (
        ["tv_super", "tv_logo_appearance"].includes(this.mention.type) ||
        (this.mention.type === "reddit_post" && !this.mention.has_body) ||
        (!(this.mention.excerpts || []).some(
          (excerpt) => excerpt.text !== ""
        ) &&
          this.mention.type !== "tweet")
      );
    },
    showExcerptsAndKeywords() {
      if (
        this.mention.license === "cla" ||
        this.mention.source?.name?.includes("Financial Times") ||
        (this.mention.license === "nla" && this.mention.excerpts[0]?.text)
      ) {
        return true;
      }

      return ["article"].includes(this.mention.type);
    },
    source() {
      return this.socialStream ? social : getSource(this.mention.type);
    },
    displayExcerptsFeature() {
      return this.source.displayExcerptsFeature;
    },
    displayExcerpts() {
      return (
        this.$isAdminMode ||
        this.showFullExcerpts ||
        this.$features.has(this.displayExcerptsFeature)
      );
    },
    keywordsVisible() {
      return (
        this.alwaysShowKeywords ||
        !this.displayExcerpts ||
        this.hideExcerpts ||
        this.showKeywords ||
        this.showExcerptsAndKeywords
      );
    },
    excerptsVisible() {
      return this.displayExcerpts && !this.hideExcerpts && !this.showKeywords;
    },
    hideExcerpts() {
      return !this.displayExcerpts && !this.options.isDerBuilder;
    },
    hasExcerpts() {
      return this.mention.excerpts && Boolean(this.mention.excerpts.length);
    },
    allowExpandExcerpts() {
      return (
        this.hasExcerpts &&
        this.mention.type !== "tweet" &&
        ((this.mention.type !== "article" &&
          this.mention.type !== "paper_article" &&
          this.mention.type !== "magazine_article") ||
          this.$isAdminMode ||
          this.adminUserEnabled) &&
        (this.$isAdminMode || this.showFullExcerpts)
      );
    },
    keywords() {
      switch (this.mention.type) {
        case "tv_super":
        case "tv_logo_appearance":
        case "radio_clip":
          return this.tvSuperKeywords;
        default:
          return toSentence(
            Object.entries(this.mention.keywords).map(
              ([keyword, hits]) => `<em>${keyword} (${hits})</em>`
            )
          );
      }
    },
    filteredExcerpts() {
      return (type) =>
        this.mention.excerpts.filter((excerpt) => excerpt.type === type);
    },
    mentionedkeywords() {
      return mentionKeywords(this.mention);
    },
    tvSuperKeywords() {
      let keywords = this.mentionedkeywords;

      if (this.mention.type === "tv_caption") {
        keywords = this.tvSuperKeywordsForCaption;
      }

      return highlight(toSentence(keywords), keywords);
    },
    tvSuperKeywordsForCaption() {
      return excerptsKeywords(this.filteredExcerpts("tv_super"));
    },
    showCaptionTvSuperKeywords() {
      return (
        this.mention.type === "tv_caption" &&
        this.tvSuperKeywordsForCaption.length
      );
    },
    showHighlightedBody() {
      return this.mention.type === "tweet" && this.mention.body_highlighted;
    },
    showPlainBody() {
      return this.mention.type === "tweet" && !this.mention.body_highlighted;
    },
  },
  methods: {
    shouldDisplayExcerpt(key) {
      return (
        !this.showKeywords &&
        this.mention.excerpts &&
        ((!this.hideExcerpts && key === 0) || this.displayAllExcerpts)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.excerpt {
  margin-bottom: 5px;
  line-height: 20px;
  word-break: break-word;
}

:deep(em) {
  background-color: rgb(252 220 89 / 30%);
  padding: 1px 2px;
  font-style: normal;
}

.no-highlight :deep(em) {
  background-color: initial !important;
}

.list-view {
  & p {
    font-size: 14px !important;
    line-height: 135% !important;
  }
}
</style>
