import { difference } from "lodash-es";
import { storeToRefs } from "pinia";
import { type Ref, computed, toRef } from "vue";

import type { Model as MediumSelectorModel } from "shared/components/selectors/MediumSelector.vue";
import type { MediumName } from "shared/helpers/media";
import {
  getMediaForStream,
  getSourceByField,
  MediumField,
  sourceByMedium,
} from "shared/helpers/media";
import features from "shared/services/features";
import type {
  Filter,
  FilterType,
  FilterValue,
  FilterValueItem,
} from "shared/stores/sources";
import useSourcesStore, { isFilterValueItem } from "shared/stores/sources";
import type { Nullable, Stream } from "shared/types";

export interface ContentTypeFilter {
  feature: string[];
  filter: string;
  default_filter?: string;
  label: string;
}

export const CONTENT_TYPE_FILTERS: ContentTypeFilter[] = [
  {
    feature: ["has_lexisnexis_print", "has_lexisnexis_online"],
    filter: "only_international_premium",
    label: "International Premium",
  },
  {
    feature: ["has_international_plus"],
    filter: "only_international_plus",
    default_filter: "include_international_plus",
    label: "International Plus",
  },
  {
    feature: ["has_cald_sources"],
    filter: "only_cald_sources",
    label: "CALD Sources",
  },
];

interface Props {
  stream?: Nullable<Stream> | Ref<Stream>;
  enabledMedia?: MediumName[] | Ref<MediumName[]>;
}

interface ContentTypeFilters {
  [key: string]: boolean;
}

export default function useSourceFilters(props: Props = {}) {
  const stream = toRef(props.stream);
  const enabledMedia = toRef(props.enabledMedia);
  const sourcesStore = useSourcesStore();

  const { filters } = storeToRefs(sourcesStore);

  const keywordFilters = computed<Filter[]>(() =>
    filters.value.filter((filter) => filter.type === "keyword")
  );

  const enabledMediaByMedium = computed<MediumField[]>(() => {
    if (!enabledMedia.value) return [];

    return enabledMedia.value.map((medium) => sourceByMedium[medium].field);
  });

  const validMedia = computed<MediumField[]>(() => {
    const media = Object.values(MediumField).filter(
      (field) =>
        field !== MediumField.SOCIAL &&
        (!enabledMediaByMedium.value.length ||
          enabledMediaByMedium.value.includes(field))
    );

    if (stream.value) {
      const streamMedia = difference(
        Object.keys(sourceByMedium),
        getMediaForStream(stream.value as Stream)
      ).map((name) => name.toLocaleLowerCase() as MediumField);

      return media.filter((medium) => !streamMedia.includes(medium));
    }

    return media;
  });

  const excludedMedia = computed<MediumField[]>(() =>
    Object.values(MediumField).filter(
      (field) => !validMedia.value.includes(field)
    )
  );

  const mediaFilters = computed<MediumField[]>(() =>
    filters.value
      .filter((filter) => filter.type === "medium")
      .map((filter) => filter.value as MediumField)
  );

  const selectedCategories = computed<number[]>(() =>
    filters.value
      .filter((filter) => filter.type === "category")
      .map((filter) => filter.value as number)
  );

  const selectedLocations = computed<number[]>(() =>
    filters.value
      .filter((filter) => filter.type === "location")
      .map((filter) => filter.value as number)
  );

  const media = computed<MediumSelectorModel>({
    get() {
      return {
        online_content: filters.value.some(
          (filter) => filter.value === MediumField.ONLINE
        ),
        print_content: filters.value.some(
          (filter) => filter.value === MediumField.PRINT
        ),
        magazine_content: filters.value.some(
          (filter) => filter.value === MediumField.MAGAZINE
        ),
        tv_content: filters.value.some(
          (filter) => filter.value === MediumField.TV
        ),
        radio_content: filters.value.some(
          (filter) => filter.value === MediumField.RADIO
        ),
        social_content: filters.value.some(
          (filter) => filter.value === MediumField.SOCIAL
        ),
        podcast_content: filters.value.some(
          (filter) => filter.value === MediumField.PODCAST
        ),
      };
    },
    set(value: MediumSelectorModel) {
      const fields: Filter[] = Object.entries(value).reduce(
        (accumulator, [key, checked]) => {
          const { field } = getSourceByField(key.replace("_content", ""));

          if (checked) {
            accumulator.push({
              type: "medium",
              value: field,
            });
          }

          return accumulator;
        },
        [] as Filter[]
      );

      filters.value = filters.value
        .filter((filter) => filter.type !== "medium")
        .concat(...fields);
    },
  });

  const selectedTopics = computed<Filter[]>(() =>
    filters.value.filter((filter) => filter.type === "topic")
  );

  const topicFilters = computed<number[]>(() =>
    selectedTopics.value.map(
      (filter) => (filter.value as FilterValueItem).id as number
    )
  );

  const selectedSourceGroups = computed<Filter[]>(() =>
    filters.value.filter((filter) => filter.type === "group")
  );

  const seletedSourceGroupIds = computed<number[]>(() =>
    selectedSourceGroups.value
      .map((filter) => filter.group_ids as number[])
      .flat()
  );

  const availableContentTypeFilters = computed<ContentTypeFilter[]>(() =>
    CONTENT_TYPE_FILTERS.filter((contentType) =>
      features.hasAny(contentType.feature)
    )
  );

  const selectedContentTypes = computed<Filter[]>(() =>
    filters.value.filter((filter) => filter.type === "content")
  );

  const contentTypeFilters = computed<ContentTypeFilters>(() => {
    const enabledFilters: ContentTypeFilters = {};

    availableContentTypeFilters.value.forEach((contentTypeFilter) => {
      const selected = selectedContentTypes.value.find(
        (selectedContentType) =>
          selectedContentType.value === contentTypeFilter.filter
      );

      if (selected) {
        enabledFilters[contentTypeFilter.filter as string] = true;
      } else if (contentTypeFilter.default_filter) {
        enabledFilters[contentTypeFilter.default_filter as string] = true;
      }
    });

    return enabledFilters;
  });

  const totalFilters = computed<number>(() => filters.value.length);

  function clearFilters(type?: FilterType): void {
    if (type) {
      filters.value = filters.value.filter((filter) => filter.type !== type);
    } else {
      filters.value = [];
    }
  }

  function findFilter(filter: Filter): Filter | undefined {
    return filters.value.find((existingFilter) => {
      if (existingFilter.type !== filter.type) return false;
      if (existingFilter.value === filter.value) return true;

      if (
        isFilterValueItem(existingFilter.value) &&
        isFilterValueItem(filter.value)
      ) {
        return existingFilter.value.id === filter.value.id;
      }

      return false;
    });
  }

  function removeFilter(oldFilter: Filter): void {
    const foundFilter = findFilter(oldFilter);

    if (foundFilter) {
      filters.value = filters.value.filter((filter) => filter !== foundFilter);
    }
  }

  function addFilter(filter: Filter): void {
    const foundFilter = findFilter(filter);

    if (foundFilter) {
      return;
    }

    filters.value.push(filter);
  }

  function toggleFilter(filter: Filter): void {
    const foundFilter = findFilter(filter);

    if (foundFilter) {
      filters.value = filters.value.filter(
        (existingFilter) => existingFilter !== foundFilter
      );
    } else {
      filters.value.push(filter);
    }
  }

  function addFilters(type: FilterType, values: FilterValue[]): void {
    const newFilters: Filter[] = values.map((value) => ({
      type,
      value,
    }));

    filters.value = filters.value
      .filter((filter) => filter.type !== type)
      .concat(newFilters);
  }

  return {
    filters,
    keywordFilters,
    selectedCategories,
    selectedLocations,
    validMedia,
    excludedMedia,
    media,
    mediaFilters,
    selectedTopics,
    topicFilters,
    selectedSourceGroups,
    seletedSourceGroupIds,
    selectedContentTypes,
    availableContentTypeFilters,
    contentTypeFilters,
    totalFilters,

    addFilter,
    addFilters,
    clearFilters,
    removeFilter,
    toggleFilter,
  };
}
