<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-intersection="handleIntersection"
    class="full-width"
  >
    <MentionCard
      ref="mentionCard"
      :action-sheet-component="actionSheetComponent"
      :always-show-keywords="alwaysShowKeywords"
      class="scoped"
      :class="{ 'tw-cursor-pointer': showCursorPointer }"
      :list-title="listTitle"
      :logo="mention.source ? mention.source.logo_url : null"
      :mention="mention"
      :mentions="mentions"
      :no-highlighting="noHighlighting"
      :options="options"
      :selected="selected"
      :show-checkbox="showCheckbox"
      :stream="stream"
      :title="mention.title"
      @click="mentionClicked($event)"
      @mention-removed="removeMention"
      @mention-selected="$emit('mention-selected', $event)"
    >
      <template #header>
        <SyndicationsPopper
          v-if="mention?.syndications?.length"
          v-slot="{ toggleOpen }"
          :mention="mention"
          :parent-el-ref="$refs.mentionCard?.$el"
          :stream="stream"
          @syndication-clicked="mentionClicked($event)"
        >
          <BaseButton
            new-design
            plain
            @click.stop.prevent="toggleOpen"
          >
            <LineClamp
              class="tw-text-base tw-font-bold tw-leading-4"
              :lines="1"
              tag="strong"
            >
              {{ mention.source.name }}
              <template #after="{ clamped }">
                <StreemTooltip v-if="clamped">{{
                  mention.source.name
                }}</StreemTooltip>
              </template>
            </LineClamp>

            <span class="tw-text-base tw-text-denim-900">
              +{{ mention.syndications.length }}
            </span>
          </BaseButton>
        </SyndicationsPopper>
        <span
          v-else
          class="alternate"
        >
          <LineClamp
            class="tw-text-base tw-font-bold tw-text-denim-900"
            :lines="1"
            tag="span"
          >
            {{ mention.source.name }}
            <template #after="{ clamped }">
              <StreemTooltip v-if="clamped">
                {{ mention.source.name }}
              </StreemTooltip>
            </template>
          </LineClamp>
        </span>

        <span class="tw-text-denim-900">
          {{ mention.source.location }}
        </span>

        <div class="row items-center tw-text-sm">
          <span class="softer">
            <TimeAgo
              :date="mention.timestamp"
              :timezone="mention.source.time_zone"
            />
            <span v-if="mention.program_airing">
              · {{ mention.program_airing.name }}
            </span>
          </span>
        </div>
        <ArchivedContentLabel
          v-if="isTveyesContent && isArchived"
          :mention="mention"
        />
      </template>

      <slot />

      <template #list-title-after>
        <div
          v-if="isTveyesContent && isArchived"
          class="tw-mb-1"
        >
          <ArchivedContentLabel :mention="mention" />
        </div>
      </template>

      <template
        v-if="!isTveyesContent"
        #beforeBody="{ mentionFormatted }"
      >
        <div
          class="tw-mb-4"
          @click.stop.prevent
        >
          <UniversalPlayerControlWidget
            v-if="!hasPortalContent"
            :clip="mentionFormatted"
            new-design
            @play="playInPopout"
          />

          <PortalTarget
            multiple
            :name="portalName"
            @change="handlePortalChange"
          />
        </div>
      </template>

      <template #body="{ mentionFormatted }">
        <div class="row justify-start no-wrap">
          <MentionExcerpts
            expandable
            :mention="mentionFormatted"
            :no-highlighting="noHighlighting"
          />
        </div>
      </template>

      <template #list-body-after>
        <div
          v-if="isTveyesContent && isArchived"
          @click.stop
        >
          <div class="tw-mt-2">
            <FullBroadcastAnchor
              font-size="xs"
              :mention="mention"
            />
          </div>
        </div>

        <div
          v-if="isTveyesContent && isExpired"
          class="tw-mt-2"
        >
          <ExpiredContentNotice />
        </div>
      </template>

      <template #afterBody>
        <div @click.stop>
          <div
            v-if="isTveyesContent && isArchived"
            class="tw-mt-4"
          >
            <FullBroadcastAnchor
              font-size="md"
              :mention="mention"
            />
          </div>

          <div
            v-if="isTveyesContent && isExpired"
            class="tw-mt-4"
          >
            <ExpiredContentNotice />
          </div>
        </div>

        <MentionCardInsights
          :visible-widgets="visibleWidgets"
          :widget-keys="[sentimentWidgetKey]"
          @click.stop.prevent
        >
          <template #components>
            <BroadcastAudienceWidget
              dense
              medium="radio"
              :mention="mention"
            />

            <AdvertisingValueWidget
              dense
              :mention="mention"
            />

            <SentimentWidget
              :key="sentimentWidgetKey"
              dense
              :mention="mention"
              :stream="stream"
            />

            <ReactScoreWidget
              dense
              :is-parent-visible="isIntersecting"
              :mention="mention"
            />
          </template>
        </MentionCardInsights>
      </template>

      <template #list-actions>
        <UniversalPlayerControlWidget
          v-if="showPopUpPlayerWidget"
          class="play-button"
          :clip="mention"
          @play="playInPopout"
        />
      </template>
    </MentionCard>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import { BaseButton, LineClamp, StreemTooltip } from "shared/components/base";
import BroadcastAudienceWidget from "shared/components/BroadcastAudienceWidget.vue";
import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import MentionExcerpts from "shared/components/MentionExcerpts.vue";
import { UniversalPlayerControlWidget } from "shared/components/players/UniversalPlayerControlWidget";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import { SyndicationsPopper } from "shared/components/SyndicationsPopper";
import useMention from "shared/composables/useMention";
import { usePortalContent } from "shared/composables/usePortalContent";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import { isTveyesExpired } from "shared/helpers/date";
import { useGlobalStore } from "shared/stores/global";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

import {
  ArchivedContentLabel,
  ExpiredContentNotice,
  FullBroadcastAnchor,
} from "./BroadcastContentMentionCard";

export default {
  name: "RadioMentionCard",
  components: {
    AdvertisingValueWidget,
    BaseButton,
    BroadcastAudienceWidget,
    LineClamp,
    MentionCard,
    MentionCardInsights,
    MentionExcerpts,
    PortalTarget,
    ReactScoreWidget,
    SentimentWidget,
    StreemTooltip,
    SyndicationsPopper,
    TimeAgo,
    UniversalPlayerControlWidget,
    ExpiredContentNotice,
    ArchivedContentLabel,
    FullBroadcastAnchor,
  },
  props: {
    ...propsExcept(["listTitle"]),
  },
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    const universalPlayerStore = useUniversalPlayerStore();

    const {
      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsDismissed,
    } = storeToRefs(universalPlayerStore);

    const {
      playerHide,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,
      setPlayerPopoutTarget,
    } = universalPlayerStore;

    const { mentionClicked } = useMention(mentionProps, context);

    const { portalName, hasPortalContent, handlePortalChange } =
      usePortalContent("radio-card");

    return {
      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsDismissed,

      playerHide,
      setPlayerPopoutTarget,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,

      mentionClicked,

      portalName,
      hasPortalContent,
      handlePortalChange,

      appConfig,
    };
  },
  data() {
    return {
      isIntersecting: false,
      sentimentValue: null,
    };
  },
  computed: {
    isExpired() {
      return isTveyesExpired(this.mention);
    },
    isArchived() {
      return (
        Boolean(this.mention.tveyes_media_request) &&
        this.mention.tveyes_media_request.status !== "Cancelled"
      );
    },
    isTveyesContent() {
      return this.mention.tveyes_player_url?.length;
    },
    sentimentWidgetKey() {
      return `sentiment-widget-${this.mention.id}-${this.sentimentValue}`;
    },
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (this.mention.audience) {
        widgets.push("BroadcastAudienceWidget");
      }

      if (
        this.mention.advertising_value &&
        this.$features.has("advertising_value_mentions")
      ) {
        widgets.push("AdvertisingValueWidget");
      }

      if (this.canShowReactScore) widgets.push("ReactScoreWidget");

      return widgets;
    },
    listTitle() {
      const mentionTitle = this.mention.program_airing
        ? this.mention.program_airing.name
        : this.mention.source.name;

      return `${mentionTitle} ${this.mention.source.location}`;
    },
    isListView() {
      return Boolean(this.options?.list);
    },
    showCursorPointer() {
      return !this.showPopUpPlayerWidget;
    },
    showPopUpPlayerWidget() {
      return !this.$isAdminMode && !this.isTveyesContent;
    },
  },
  watch: {
    "mention.sentiment_ratings": {
      deep: true,
      handler(options) {
        if (options?.length) {
          this.sentimentValue = options[0].sentiment;
        }
      },
    },
  },
  mounted() {
    this.mention.sentiment_ratings ??= [];
  },
  beforeUnmount() {
    if (
      this.isPopoutPlayerPlayingThisClip() &&
      this.playerOpen &&
      this.$isDesktop
    ) {
      this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);

      this.$track("Playing the clip in the popout player", {
        type: "radio",
      });
    }
  },
  methods: {
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    handleIntersection(el) {
      this.isIntersecting = el.isIntersecting;
      if (this.playerClosed()) return;
      if (!this.playerOpen) this.playerShow();
      this.setPlayerTarget();
    },
    playInPopout() {
      this.playerInsertClip(this.mention);

      if (!this.playerOpen) this.playerShow();

      this.$nextTick(() => {
        this.playerPopoutRef.playNow(this.mention);
      });

      this.setPlayerTarget();
      this.setPlayerDismissed(false);
    },
    setPlayerTarget() {
      if (this.playInCard()) {
        this.setPlayerPopoutTarget(this.portalName);

        this.$track("Playing the clip in the card", {
          type: "radio",
        });
      } else {
        this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);

        this.$track("Playing the clip in the popout player", {
          type: "radio",
        });
      }
    },
    playInCard() {
      return (
        (this.isIntersecting && !this.isListView && !this.alwaysPlayInPopout) ||
        this.$isMobile
      );
    },
    isPopoutPlayerPlayingThisClip() {
      return (
        this.playerInitialClip &&
        this.mention &&
        this.playerInitialClip.id === this.mention.id
      );
    },
    playerClosed() {
      return !this.isPopoutPlayerPlayingThisClip() || this.playerIsDismissed;
    },
  },
};
</script>
