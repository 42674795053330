<template>
  <div
    class="play-button"
    :style="showStyle"
  >
    <div
      v-if="clip"
      class="player"
    >
      <div
        class="player-controls"
        :class="{ active: showPlayOptions }"
        @mouseenter="togglePlayerOptions(true)"
        @mouseleave="togglePlayerOptions(false)"
      >
        <QBtn
          v-if="playerIsPlaying && !isPlaying && $isDesktop"
          :aria-label="$t('universal_player_control_widget.add_to_queue')"
          class="queue-button"
          dense
          flat
          :icon="inPlaylist ? 'ion-md-remove' : 'ion-ios-add'"
          round
          :size="size"
          @click="controlAction(addToPlaylist)"
        >
          <StreemTooltip
            anchor="center left"
            self="center right"
            transition-hide="jump-right"
            transition-show="jump-left"
          >
            {{
              inPlaylist
                ? $t("universal_player_control_widget.remove_from_queue")
                : $t("universal_player_control_widget.add_to_queue")
            }}
          </StreemTooltip>
        </QBtn>
        <QBtn
          v-else
          :aria-label="$t('universal_player_control_widget.play')"
          :class="isPlaying ? 'pause-button' : 'play-button'"
          dense
          flat
          :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
          round
          :size="size"
          @click="controlAction(togglePlay)"
        >
          <StreemTooltip>
            {{ $t("universal_player_control_widget.play") }}
          </StreemTooltip>
        </QBtn>
        <Transition name="slide-down-fade">
          <div
            v-if="showPlayOptions && $isDesktop"
            class="play-options column"
          >
            <QBtn
              :aria-label="$t('universal_player_control_widget.play')"
              :class="isPlaying ? 'pause-button' : 'play-button'"
              dense
              flat
              :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
              round
              :size="size"
              @click="controlAction(togglePlay)"
            >
              <StreemTooltip>{{
                isPlaying
                  ? $t("universal_player_control_widget.pause")
                  : $t("universal_player_control_widget.play")
              }}</StreemTooltip>
            </QBtn>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { StreemTooltip } from "shared/components/base";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  /* eslint-disable vue/match-component-file-name */
  name: "UniversalPlayerControlWidget",
  components: {
    StreemTooltip,
  },
  inheritAttrs: false,
  props: {
    clip: {
      type: Object,
      required: true,
      validator(value) {
        return (
          ("media_url" in value || "signed_url" in value) &&
          "start_time" in value
        );
      },
    },
    startTime: {
      type: Number,
      default: null,
    },
    autoplay: {
      type: Boolean,
    },
    uiStyle: {
      type: [Object, String],
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    origin: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["play"],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();

    const {
      universalPlayerRef,
      playerInitialClip,
      playerClip,
      playerOpen,
      playerIsPlaying,
      playerList,
      playerPopoutRef,
    } = storeToRefs(universalPlayerStore);

    const {
      playerShow,
      playerInsertClip,
      playerAppendClip,
      playerRemoveListItem,
    } = universalPlayerStore;

    return {
      universalPlayerRef,
      playerInitialClip,
      playerClip,
      playerOpen,
      playerIsPlaying,
      playerList,
      playerPopoutRef,
      playerShow,
      playerInsertClip,
      playerAppendClip,
      playerRemoveListItem,
    };
  },
  data() {
    return {
      isPlaying: false,
      immutableClip: null,
      active: false,
      showPlayOptions: false,
    };
  },
  computed: {
    playerRef() {
      return this.universalPlayerRef;
    },
    inPlaylist() {
      if (this.playerList) {
        return this.playerList.some((listItem) => listItem.id === this.clip.id);
      }

      return false;
    },
    isUniversalPlayerPlayingThisClip() {
      return (
        this.playerInitialClip &&
        this.clip &&
        this.playerInitialClip.id === this.clip.id
      );
    },
    showStyle() {
      const opacity =
        this.$isMobile || this.isUniversalPlayerPlayingThisClip ? 1 : false;

      const baseStyle = {
        ...(opacity ? { opacity } : {}),
      };

      return [baseStyle, this.uiStyle];
    },
  },
  watch: {
    playerIsPlaying: {
      handler: "matchUniversalPlayerState",
    },
    playerClip: {
      handler: "matchUniversalPlayerState",
    },
    playerOpen() {
      if (!this.playerOpen) this.paused();
    },
    startTime() {
      if (this.startTime && this.active) {
        this.play();
        if (this.startTime) this.updatePlayerStart();
        this.$emit("play");
      }
    },
  },
  activated() {
    this.active = true;
    this.matchUniversalPlayerState();
  },
  deactivated() {
    this.active = false;
    this.isPlaying = false;
  },
  mounted() {
    this.active = true;
    this.immutableClip = { ...this.clip, ...{ origin: this.origin } };
    this.matchUniversalPlayerState();

    if (this.autoplay && !this.playerIsPlaying) {
      this.togglePlay();
    }
  },
  methods: {
    updatePlayerStart() {
      this.$nextTick().then(() => {
        let startTime = this.startTime - this.clip.start_time;

        if (startTime > this.clip.end_time - this.clip.start_time) {
          const { duration } = this.playerRef;

          startTime =
            Math.min(this.clip.end_time - this.clip.start_time, duration) - 2;
        }

        this.playerRef.currentTime = startTime;
      });
    },
    togglePlay() {
      if (!this.active) return;

      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
        if (this.startTime) this.updatePlayerStart();
        this.$emit("play");
      }
    },
    play() {
      if (!this.inPlaylist) this.playerInsertClip(this.immutableClip);
      if (!this.playerOpen) this.playerShow();
      this.$nextTick().then(this.played);
    },
    pause() {
      this.playerRef.pause();
      this.paused();
    },
    played() {
      this.isPlaying = true;
    },
    paused() {
      this.isPlaying = false;
    },
    addToPlaylist() {
      if (this.inPlaylist) {
        this.removeFromPlaylist();
      } else {
        this.playerAppendClip(this.immutableClip);
      }
    },
    removeFromPlaylist() {
      if (this.isUniversalPlayerPlayingThisClip) {
        this.pause();
      }

      this.playerRemoveListItem(this.clip);
    },
    matchUniversalPlayerState() {
      this.$nextTick().then(() => {
        if (this.playerIsPlaying && this.isUniversalPlayerPlayingThisClip) {
          this.played();
        } else {
          this.paused();
        }
      });
    },
    togglePlayerOptions(value) {
      if (!this.isPlaying && this.playerIsPlaying) {
        this.showPlayOptions = value;
      } else {
        this.showPlayOptions = false;
      }
    },
    controlAction(action) {
      if (
        this.$isMobile &&
        !this.isPlaying &&
        this.playerIsPlaying &&
        !this.showPlayOptions
      ) {
        this.togglePlayerOptions(true);
      } else if (typeof action === "function") {
        this.togglePlayerOptions(false);
        action();
        this.trackAction(action);
      }
    },
    trackAction(action) {
      let type = "";

      switch (this.clip.type) {
        case "podcast_episode":
          type = "podcast";
          break;
        case "radio_clip":
          type = "radio";
          break;
        default:
          type = "tv";
      }

      if (action.name === "addToPlaylist") {
        this.$track("Added a clip to the playlist", {
          clipId: this.clip.id,
          type,
        });
      } else {
        this.$track("Toggled play on a clip", {
          clipId: this.clip.id,
          type,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "shared/css/utils/transitions/slideDownFade";

.play-button,
.pause-button,
.queue-button {
  color: $primary;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.play-button :deep(i) {
  padding-left: 3px;
  padding-top: 1px;
}

.pause-button,
.queue-button {
  :deep(i) {
    padding: 1px 0 0;
    font-weight: bold;
  }
}

.player-controls {
  border-radius: 16px;
  transition: height 0.5s;
}

.tv-card-universal-control .play-button,
.tv-card-universal-control .pause-button,
.tv-card-universal-control .queue-button {
  color: $white;
  width: 100%;
  height: 100%;
}
</style>
