<template>
  <div
    class="no-shrink"
    :class="{ active: active }"
  >
    <InputText
      v-model="handleAuthors"
      new-design
    >
      <template #label>
        <div class="q-mb-sm">
          <span :class="contentStyle">
            {{ $t("authors_expandable.handle") }}
          </span>
          <span class="smaller softer">{{
            $t("authors_expandable.comma_separated")
          }}</span>
        </div>
      </template>
    </InputText>
  </div>
</template>

<script>
import { InputText } from "shared/components/base";

export default {
  name: "AuthorsExpandable",
  components: {
    InputText,
  },
  props: {
    authors: {
      type: Array,
      default: () => [],
    },
    contentStyle: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
    },
  },
  emits: ["update:authors", "search"],
  computed: {
    handleAuthors: {
      get() {
        return this.authors[0];
      },
      set(val) {
        this.$emit("update:authors", val?.length ? [val] : []);
        this.$emit("search");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: $white;
}
</style>
