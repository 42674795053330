<template>
  <div class="no-shrink">
    <div class="row">
      <div
        v-for="(column, index) in platformsColumns"
        :key="index"
        :class="{
          [`col-${12 / columns}`]: !inline,
          'full-width': inline,
        }"
      >
        <Checkbox
          v-for="(medium, key) in column"
          :key="key"
          v-model="mediaInput[medium.medium]"
          :class="`color-${medium.field}`"
          :label="medium.label"
          new-design
          prevent-context-default
          @update:context-click="bulkUpdateMedia(medium.medium)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import bulkSelection from "shared/helpers/bulkSelection";
import { getSources } from "shared/helpers/media";

export default {
  name: "MediaSelector",
  props: {
    stream: {
      type: Object,
      required: true,
    },
    media: {
      type: Array,
      default: () => [],
    },
    refreshMedia: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
    contentStyle: {
      type: String,
      default: "row",
    },
    columns: {
      type: [Number, String],
      default: 2,
      validator(value) {
        return 12 % Number(value) === 0;
      },
    },
    inline: {
      type: Boolean,
    },
  },
  emits: ["update:media", "search", "update:refreshMedia"],
  data() {
    return {
      getSources,
      mediaInput: null,
    };
  },
  computed: {
    enabledMediaTypes() {
      return this.getSources().filter(({ medium }) =>
        this.stream.enabled_media.includes(medium)
      );
    },
    platformsColumns() {
      const columns = this.inline ? 1 : this.columns;

      return this.splitToChunks([...this.enabledMediaTypes], columns);
    },
  },
  watch: {
    mediaInput: {
      deep: true,
      handler() {
        const media = this.mediaInputToParams();

        if (!this.refreshMedia) {
          this.$emit("update:media", media);
          this.$emit("search");
        } else {
          this.$emit("update:refreshMedia", false);
        }
      },
    },
    media: {
      immediate: true,
      handler() {
        if (!this.mediaInput || this.refreshMedia) {
          const sources = this.media.length
            ? this.media
            : this.enabledMediaTypes.map((medium) => medium.medium);

          this.mediaInput = this.mediaToObject(sources);
        }
      },
    },
  },
  methods: {
    mediaToObject(media) {
      return media.reduce((acc, medium) => {
        acc[medium] = true;

        return acc;
      }, {});
    },
    mediaInputToParams() {
      return Object.entries(this.mediaInput)
        .filter((selected) => selected[1])
        .map((entry) => entry[0]);
    },
    splitToChunks(array, chunks) {
      const result = [];

      for (let index = chunks; index > 0; index -= 1) {
        result.push(array.splice(0, Math.ceil(array.length / index)));
      }

      return result;
    },
    bulkUpdateMedia(clickedMedium) {
      const mediaSelections = this.enabledMediaTypes.map((type) => ({
        medium: type.medium,
        selected: this.mediaInput[type.medium] ?? false,
      }));

      const media = bulkSelection(clickedMedium, {
        keySelector: (item) => item.medium,
        valueSelector: (item) => item.selected,
        collection: mediaSelections,
      });

      this.applyMediaUpdates(media);
    },
    applyMediaUpdates(media) {
      this.mediaInput = this.mediaToObject(media);
      this.$emit("update:media", media);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(label.checkbox) {
  height: 30px;
  margin: 0;
}
</style>
