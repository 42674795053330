<template>
  <label
    class="radio"
    :class="radioClasses"
  >
    <input
      :checked="modelValue === value"
      :disabled="disabled"
      :name="name"
      type="radio"
      :value="value"
      @change="onChange"
    />
    <span class="radio-checkmark" />
    <span
      v-if="label"
      class="radio-label"
    >
      {{ label }}
    </span>
    <slot v-else />
  </label>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue";

interface Props {
  value: T | boolean;
  label?: string;
  disabled?: boolean;
  name?: string;
  newDesign?: boolean;
  color?: string;
  alignTop?: boolean;
  dense?: boolean;
}

const modelValue = defineModel<T>({
  default: false as T,
});

const props = withDefaults(defineProps<Props>(), {
  color: "primary",
  name: "",
  label: "",
});

const radioClasses = computed(() => [
  {
    "new-design": props.newDesign,
    "align-top": props.alignTop,
    dense: props.dense,
  },
  `radio--${props.color}`,
]);

function onChange(event: Event) {
  if ((event.target as HTMLInputElement).checked) {
    modelValue.value = props.value as T; // Cast to T to ensure type safety
  }
}
</script>
